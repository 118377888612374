import { API } from "aws-amplify";
import axios from "axios";
import { NOTIFICATION_HISTORY, NOTIFICATION_HISTORY_STATE, SUBMIT_NOTIFICATION_STATE } from "../actions-index.js";
import { handleUnauthenticated } from "../Authentication/util";
import { ACTION_STATE_ERROR, ACTION_STATE_FORBIDDEN, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS } from "../util/constants";
import { buildAction } from "../util/util";

export function submitNotification(file, notification) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(SUBMIT_NOTIFICATION_STATE, ACTION_STATE_LOADING));
            const fileName = file.name.split(".")[0];
            const uri = encodeURI(`/notification/file/${fileName}/url`);
            const { data: signedUrl } = await API.get(process.env.REACT_APP_NOTIFICATION_API_NAME, uri);
            await axios.put(signedUrl, file, { headers: { "Content-Type": file.type } });
            await API.post(process.env.REACT_APP_NOTIFICATION_API_NAME, "/notification", { body: notification });
            dispatch(buildAction(SUBMIT_NOTIFICATION_STATE, ACTION_STATE_SUCCESS));
        } catch(error) {
            console.error("Submit Notification Error: ", error);
            handleUnauthenticated(error, () => {
                const { message = "" } = error;
                if(message.includes("403")) { // Forbidden - a notification is already processing
                    dispatch(buildAction(SUBMIT_NOTIFICATION_STATE, ACTION_STATE_FORBIDDEN));
                } else {
                    dispatch(buildAction(SUBMIT_NOTIFICATION_STATE, ACTION_STATE_ERROR));
                }
            });
        }
    };
}

export function getNotificationHistory() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(NOTIFICATION_HISTORY_STATE, ACTION_STATE_LOADING));
            const { data: history } = await API.get(process.env.REACT_APP_NOTIFICATION_API_NAME, "/notification/executions");
            dispatch(buildAction(NOTIFICATION_HISTORY, history));
            dispatch(buildAction(NOTIFICATION_HISTORY_STATE, ACTION_STATE_SUCCESS));
        } catch(error) {
            console.error("Get Notification History Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(NOTIFICATION_HISTORY_STATE, ACTION_STATE_ERROR));
            });
        }
    };
}