import { NOTIFICATION_HISTORY, NOTIFICATION_HISTORY_STATE, SUBMIT_NOTIFICATION_STATE } from "../actions-index";

export function submitNotificationState(state = "", action = {}) {
    return (action.type === SUBMIT_NOTIFICATION_STATE) ? action.payload : state;
}

export function notificationHistory(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case NOTIFICATION_HISTORY:
            return { ...state, data: payload };
        case NOTIFICATION_HISTORY_STATE:
            return { ...state, state: payload };
        default:
            return state;
    }
}