import React, { useContext, useEffect, useState } from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { AppContext } from ".";
import SignIn from "../../Authentication/components/SignIn";
import SignOut from "../../Authentication/components/SignOut";
import Review from "../../Review/containers";
import ReviewContentItem from "../../Review/containers/ContentItem";
import GetProviderContent from "../../Provider/containers/GetContent";
import Content from "../../Content/containers";
import ViewContentItem from "../../Content/containers/ContentItem";
import Organize from "../../Organize/containers";
import CreateChannel from "../../Organize/containers/CreateChannel";
import ChannelSection from "../../Organize/containers/ChannelSection";
import AddChannelSectionContent from "../../Organize/containers/ChannelSection/AddContent";
import Notification from "../../Notification/containers";
import NotificationHistory from "../../Notification/containers/History";
import Announcements from "../../Announcements/containers/Announcements";
import BulkAnnouncements from "../../Announcements/containers/BulkAnnouncements";
import BulkAnnouncement from "../../Announcements/containers/BulkAnnouncement";
import GroupAnnouncement from "../../Announcements/containers/GroupAnnouncement";
import { APPLICATION_TITLE } from "../../util/constants";
import { isEmpty } from "lodash";

function Routes() {
    const { user } = useContext(AppContext);
    const { isAdmin, isApprover, canManageAnnouncements, canManageCompositeChannels, canSendNotification } = user;
    
    const [pageTitle, setPageTitle] = useState(APPLICATION_TITLE);
    useEffect(() => {
        document.title = pageTitle ? `${pageTitle} | ${APPLICATION_TITLE}` : APPLICATION_TITLE;
    }, [pageTitle]);
    
    const renderPage = (pageTitle, component) => {
        setPageTitle(pageTitle);
        return component;
    };
    
    const renderIndexPage = () => {
        return isEmpty(user.state) ? <div data-testid="index" /> : 
            ((isAdmin === true || isApprover) === true ? <Redirect to="/review" /> : <Redirect to="/content" />);
    };

    const PageNotFound = () => {
        return (
            <div data-testid="page-not-found">
                <h1>Page Not Found</h1>
                Return to the <Link to="/">Home</Link> page.
            </div>
        );
    };
    
    return (
        <Switch>
            <Route exact path="/" render={() => renderIndexPage()} />
            <Route exact path="/signin" render={() => renderPage(null, <SignIn />)} />
            <Route exact path="/signout" render={() => renderPage(null, <SignOut />)} />
            {(isAdmin === true || isApprover === true) && [
                <Route key="Review"exact path="/review" render={() => renderPage("Review", <Review />)} />,
                <Route key="Review Content Item" exact path="/review/content/:itemId" render={() => renderPage("Content Item | Review", <ReviewContentItem />)} />,
                <Route key="Get Provider Content" exact path="/provider/get-content" render={() => renderPage("Get Provider Content", <GetProviderContent />)} />,
            ]}
            <Route exact path="/content" render={() => renderPage("Content", <Content />)} />
            <Route exact path="/content/:itemId" render={() => renderPage("Content Item | Content", <ViewContentItem />)} />
            <Route exact path="/organize" render={() => renderPage("Organize", <Organize />)} />
            {(isAdmin || canManageCompositeChannels) &&
                <Route exact path="/organize/channel/create" render={() => renderPage("Create Channel | Organize", <CreateChannel />)} />
            }
            <Route exact path="/organize/channel/:channel/section" render={() => renderPage("Create Section | Organize", <ChannelSection />)} />
            <Route exact path="/organize/channel/:channel/section/:sectionId" render={() => renderPage("Edit and Organize Section | Organize", <ChannelSection />)} />
            <Route exact path="/organize/channel/:channel/section/:sectionId/add-content" render={() => renderPage("Add Content | Edit and Organize Section | Organize", <AddChannelSectionContent />)} />
            {canSendNotification && [
                <Route key="Notification" exact path="/notification" render={() => renderPage("Notification", <Notification />)} />,
                <Route key="Notification History" exact path="/notification/history" render={() => renderPage("Notification History", <NotificationHistory />)} />
            ]}
            {canManageAnnouncements && [
                <Route key="Announcements" exact path="/announcements" render={() => renderPage("Announcements", <Announcements />)} />,
                <Route key="New Announcement" exact path="/announcement/create/new" render={() => renderPage("New Announcement", <GroupAnnouncement />)} />,
                <Route key="Update Announcement" exact path="/announcement/update/:group/:id" render={() => renderPage("Update Announcement", <GroupAnnouncement />)} />,
                <Route key="Bulk Announcements" exact path="/announcements/bulk" render={() => renderPage("Bulk Announcements", <BulkAnnouncements />)} />,
                <Route key="Bulk Announcement" exact path="/announcement/create/bulk" render={() => renderPage("Bulk Announcement", <BulkAnnouncement />)} />
            ]}
            <Route render={() => renderPage("Page Not Found", <PageNotFound />)} />
        </Switch>
    );
}

Routes.defaultProps = {
    pathname: "/"
}

Routes.propTypes = {
    pathname: PropTypes.string.isRequired
}

export default Routes;