import React, { useContext, useEffect, useState } from "react";
import Link from "@material-ui/core/Link";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Hidden, IconButton, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { compact } from "lodash";
import useStyles from "../styles/theme";
import { AppContext } from "../../App/components";

export default function NavLinks() {
    const { user } = useContext(AppContext);
    const { isAdmin, isApprover, canManageAnnouncements, canSendNotification } = user;
    const history = useHistory();
    const [links, setLinks] = useState([]);

    const [navMenuEl, setNavMenuEl] = useState(null);
    const onNavMenuClick = event => {
        setNavMenuEl(event.currentTarget);
    };
    const onNavMenuClose = () => {
        setNavMenuEl(null);
    };
    const onNavMenuItemClick = url => {
        history.push(url);
        onNavMenuClose();
    };

    useEffect(() => {
        setLinks(compact([
            ((isAdmin || isApprover) ? { name: "Review", url: "/review" } : null),
            { name: "Organize", url: "/organize" },
            { name: "Content", url: "/content" },
            (canSendNotification ? { name: "Notification", url: "/notification" } : null),
            (canManageAnnouncements ? { name: "Announcements", url: "/announcements" } : null)
        ]));
    }, [isAdmin, isApprover, canManageAnnouncements, canSendNotification])

    return [
        <Hidden smDown key="nav-menu-smDown">
            {links.map((link, index) => { 
                return (<NavLink key={index} link={link} />);
            })}
        </Hidden>,
        <Hidden mdUp key="nav-menu-mdUp">
            <IconButton id="nav-menu-button" aria-label="Navigation Menu" aria-controls="nav-menu" aria-haspopup="true" onClick={onNavMenuClick}>
                <MenuIcon />
            </IconButton>
            <Menu id="nav-menu" anchorEl={navMenuEl} keepMounted open={Boolean(navMenuEl)} onClose={onNavMenuClose}>
                {links.map((link, index) => {
                    return (
                        <MenuItem key={index} onClick={() => onNavMenuItemClick(link.url)}>
                            <NavLink link={link} />
                        </MenuItem>
                    );
                })}
            </Menu>
        </Hidden>
    ];
}

function NavLink(props) {
    const classes = useStyles();
    const link = props.link;
    return (
        <Link component={RouterLink} to={link.url} key={link.name} aria-label={`Navigate to ${link.name}`} 
            variant="subtitle1" className={classes.links}>
            {link.name}
        </Link>
    );
}