import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Mui from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import * as MuiIcons from "@material-ui/icons";
import { find, isEmpty } from "lodash";
import useStyles from "../styles/Announcements";
import { transformDateStringToTime } from "../transform";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, ANNOUNCEMENT_AFFILIATION, SORT_DIRECTION } from "../../util/constants";
import { getSortComparator, sort } from "../../util/util";

export default function Announcements(props) {
    const { announcements, announcementsState, getAnnouncements, resetAnnouncements, searchAnnouncements } = props;
    const classes = useStyles();
    const history = useHistory();
    const retryButton = useRef(null);
    const [isFindButtonDisabled, setFindButtonDisabled] = useState(true);
    const [dismissed, setDismissed] = useState(false);
    const [expired, setExpired] = useState(false);
    const [query, setQuery] = useState("");
    const [isSearching, setIsSearching] = useState(false);

    const isLoading = (announcementsState === ACTION_STATE_LOADING);

    if(announcementsState === "") getAnnouncements(expired);

    // when announcements are loading, disable the find button
    // when query changes, set the find button disabled state
    // when getting announcements fails, focus on the retry button
    useEffect(() => {
        if(announcementsState === ACTION_STATE_LOADING) {
            setFindButtonDisabled(true);
        } else {
            const disabled = !(query.length > 0);
            if(isFindButtonDisabled !== disabled) setFindButtonDisabled(disabled);
        }
        if(announcementsState === ACTION_STATE_ERROR && retryButton.current) retryButton.current.focus();
    }, [announcementsState, isFindButtonDisabled, query]);

    // on unmount, reset announcements state
    useEffect(() => {
        return () => {
            resetAnnouncements();
        }
    }, [resetAnnouncements]);

    const onFindButtonClick = () => {
        setIsSearching(true);
        searchAnnouncements(query, dismissed);
    };

    return (
        <div>
            <h1 id="heading" className="mt-3">Announcements</h1>
            <Mui.Button variant="contained" color="primary" aria-label="Create New Announcement" onClick={() => history.push("/announcement/create/new")} className="mr-1 mt-1">Create New</Mui.Button>
            <Mui.Button variant="contained" color="primary" aria-label="Create Bulk Announcement" onClick={() => history.push("/announcement/create/bulk")} className="mr-1 mt-1">Create Bulk</Mui.Button>
            <Mui.Button variant="contained" color="primary" aria-label="View Bulk Announcements" onClick={() => history.push("/announcements/bulk")} className="mr-1 mt-1">View Bulk</Mui.Button>
            <Mui.Box className={classes.findSection}>
                <Mui.TextField variant="outlined" label="Find Announcements by EMPLID or Name.#" value={query} className="mr-2"
                    onChange={(e) => setQuery(e.target.value)} onKeyPress={(e) => { if(e.key === "Enter") onFindButtonClick(); }}
                    inputProps={{ id: "findAnnouncementsTextField" }}
                    InputProps={{ 
                        classes: { root: classes.findInput },
                        endAdornment: (
                            <Mui.InputAdornment position="end">
                                {query !== "" &&
                                    <Mui.IconButton size="small" aria-label="Clear" onClick={() => {
                                        setIsSearching(false);
                                        setQuery("");
                                        getAnnouncements(expired);
                                    }}>
                                        <MuiIcons.Clear fontSize="small" />
                                    </Mui.IconButton>
                                }
                            </Mui.InputAdornment>
                        )
                    }} />
                <Mui.Box alignSelf="center">
                    <Mui.Button variant="contained" color="primary" aria-label="Find Announcements" disabled={isFindButtonDisabled} className={classes.findButton} onClick={onFindButtonClick}>Find</Mui.Button>
                </Mui.Box>
            </Mui.Box>
            {!isLoading &&
                (
                    isSearching ?
                    (<Mui.FormControlLabel label="Include dismissed announcements" checked={dismissed === true}
                        control={<Mui.Checkbox name="dismissed" inputProps={{ id: "dismissed" }} onChange={(e) => {
                                    setIsSearching(true);
                                    setDismissed(e.target.checked);
                                    searchAnnouncements(query, e.target.checked);
                                }
                            } />
                        }
                     />) :
                    (<Mui.FormControlLabel label="Include expired announcements" checked={expired === true}
                        control={<Mui.Checkbox name="expired" inputProps={{ id: "expired" }} onChange={(e) => {
                                    setIsSearching(false);
                                    setExpired(e.target.checked);
                                    getAnnouncements(e.target.checked);
                                }
                            } />
                        }
                     />)
                )
            }
            
            {isLoading &&
                <Mui.Box display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center" marginTop="2rem">
                    <Mui.CircularProgress size="20px" aria-describedby="announcementsLoading" className="mb-2 mr-1" />
                    <Mui.Typography id="announcementsLoading" variant="body1" aria-live="assertive">Loading announcements...</Mui.Typography>
                </Mui.Box>
            }
            {announcementsState === ACTION_STATE_ERROR && 
                <Mui.Box display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center" color="error" marginTop="2rem">
                    <Mui.Typography id="announcementsError" variant="body1" aria-live="assertive" color="error" className="mr-1">Failed to load announcements</Mui.Typography>
                    <Mui.IconButton ref={retryButton} size="small" aria-label="Retry finding announcements" className="mb-2"
                        onClick={() => (isSearching ? searchAnnouncements(query, dismissed) : getAnnouncements(expired))}>
                        <MuiIcons.Replay fontSize="small" color="error" />
                    </Mui.IconButton>
                </Mui.Box>
            }
            {announcementsState === ACTION_STATE_SUCCESS &&
                <AnnouncementsTable data={announcements} showDismissed={(isSearching && dismissed)} />
            }
        </div>
    );
}

function AnnouncementsTable(props) {
    const { data = [], showDismissed } = props;
    const classes = useStyles();
    const [expandedRows, setExpandedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState("priority");
    const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ASCENDING);

    const audienceId = "audience";
    const disabledId = "disabled";
    const expandCollapseId = "expandCollapse";
    const headers = [
        { id: audienceId, label: "Audience", width: "20%", align: "left", sortable: true },
        { id: "title", label: "Title", width: "38%", align: "left", sortable: true },
        { id: "startDate", label: "Start Date", width: "15%", align: "left", sortable: true, sortTransformer: transformDateStringToTime },
        { id: "endDate", label: "End Date", width: "15%", align: "left", sortable: true, sortTransformer: transformDateStringToTime },
        { id: "priority", label: "Priority", width: "5%", align: "left", sortable: true },
        { id: disabledId, label: "Disabled", width: "2%", align: "left", sortable: false },
        { id: expandCollapseId, label: "", width: "5%", align: "left", sortable: false }
    ];
    const colSpan = (headers.length);
    const sortByOptions = headers.filter(header => (header.sortable === true)).map(header => ({ label: header.label, value: header.id }));
    const sortTransformer = (find(headers, ["id", sortBy])?.sortTransformer ?? null);
    const announcements = sort(data, getSortComparator(sortBy, sortDirection, sortTransformer));
    const rows = (rowsPerPage > 0 ? announcements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : announcements);

    const onSort = (columnId) => {
        const isSortedAscending = (sortBy === columnId && sortDirection === SORT_DIRECTION.ASCENDING);
        setSortDirection(isSortedAscending ? SORT_DIRECTION.DESCENDING : SORT_DIRECTION.ASCENDING);
        setSortBy(columnId);
    };
    const setRowExpanded = (rowIndex, isExpanded) => {
        const expandedRowsIndex = expandedRows.indexOf(rowIndex);
        if(isExpanded === true) {
            if(expandedRowsIndex === -1) setExpandedRows([...expandedRows, rowIndex]);
        } else {
            if(expandedRowsIndex !== -1) {
                const updatedExpandedRows = [...expandedRows];
                updatedExpandedRows.splice(expandedRowsIndex, 1);
                setExpandedRows(updatedExpandedRows);
            }
        }
    };

    return (
        <div>
            <Mui.Hidden mdUp>
                <Mui.Box>
                    <Mui.Grid container spacing={2}>
                        <Mui.Grid item lg={6}>
                            <Mui.FormControl variant="outlined">
                                <Mui.InputLabel htmlFor="announcementsTableSortBySelect">Sort By</Mui.InputLabel>
                                <Mui.Select native inputProps={{ id: "announcementsTableSortBySelect", className: classes.sortBySelectInput }}
                                    value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                                    {sortByOptions.map((sortByOption) => (<option key={sortByOption.value} value={sortByOption.value}>{sortByOption.label}</option>))}
                                </Mui.Select>
                            </Mui.FormControl>
                        </Mui.Grid>
                        <Mui.Grid item lg={6}>
                            <Mui.FormControl>
                                <Mui.FormLabel id="announcementsTableSortDirectionLabel" classes={{ root: classes.sortDirectionRadioGroupLabel }}>
                                    <Mui.Typography component="span" variant="subtitle1">Sort Direction</Mui.Typography>
                                </Mui.FormLabel>
                                <Mui.RadioGroup id="announcementsTableSortDirectionRadioGroup" row aria-labelledby="announcementsTableSortDirectionLabel" name="announcementsTableSortDirection" value={sortDirection}
                                    classes={{ root: classes.sortDirectionRadioGroup }} onChange={e => { setSortDirection(e.target.value); }}>
                                    <Mui.FormControlLabel label="Ascending" value={SORT_DIRECTION.ASCENDING} control={<Mui.Radio classes={{ root: classes.sortDirectionRadio }} />} />
                                    <Mui.FormControlLabel label="Descending" value={SORT_DIRECTION.DESCENDING} control={<Mui.Radio classes={{ root: classes.sortDirectionRadio }} />} />
                                </Mui.RadioGroup>
                            </Mui.FormControl>
                        </Mui.Grid>
                    </Mui.Grid>
                </Mui.Box>
            </Mui.Hidden>
            <Mui.Table id="announcementsTable" aria-label="Announcements Table" className={classes.announcementsTable}>
                <caption className="sr-only">Announcements Table</caption>
                <Mui.TableHead>
                    <Mui.TableRow>
                        {headers.map((header) => (
                            <Mui.TableCell key={header.id} style={{ width: header.width}} align={header.align}
                                sortDirection={header.sortable === true ? (sortBy === header.id ? sortDirection : false) : null}>
                                {header.sortable === true ?
                                    (<Mui.TableSortLabel active={sortBy === header.id} onClick={() => onSort(header.id)}
                                        direction={sortBy === header.id ? sortDirection : SORT_DIRECTION.ASCENDING}>
                                        {header.label}
                                        {sortBy === header.id ? 
                                            (<span className="sr-only">
                                                {sortDirection === SORT_DIRECTION.DESCENDING ? "(Sorted Descending)" : "(Sorted Ascending)"}
                                            </span>) : null
                                        }
                                    </Mui.TableSortLabel>) : 
                                    <Fragment>{header.label}</Fragment>
                                }
                            </Mui.TableCell>
                        ))}
                    </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                    {rows.length === 0 &&
                        <Mui.TableRow id="noAnnouncementsRow">
                            <Mui.TableCell colSpan={colSpan} className="noDataMessage">
                                <Mui.Typography component="span" variant="subtitle2" aria-live="assertive">No Announcements</Mui.Typography>
                            </Mui.TableCell>
                        </Mui.TableRow>
                    }
                    {rows.map((row, rowIndex) => {
                        const expandedRowId = `expandedRow${rowIndex}`;
                        const isExpanded = expandedRows.includes(rowIndex);
                        return (
                            <Fragment key={rowIndex}>
                                <Mui.TableRow aria-controls={expandedRowId} aria-expanded={isExpanded} onClick={() => setRowExpanded(rowIndex, !isExpanded)}>
                                    {headers.map((header) => {
                                        if(header.id === audienceId && row.isGroup === true) {
                                            return (
                                                <Mui.TableCell key={header.id} data-header={header.label} align={headers.align}>
                                                    <Link aria-label="Update Announcement" to={`/announcement/update/${row.group}/${row.id}`}>{row[header.id]}</Link>
                                                </Mui.TableCell>
                                            );
                                        } else if(header.id === disabledId) {
                                            const disabled = (row[header.id] === true);
                                            const ariaLabel = booleanToString(disabled);
                                            const Element = (disabled ? 
                                                <MuiIcons.Block aria-hidden="false" aria-label={ariaLabel} role="img" style={{ color: red[500] }} /> : 
                                                <span className="sr-only" aria-label={ariaLabel} />
                                            );
                                            return (<Mui.TableCell key={header.id} data-header={header.label} align={headers.align}>{Element}</Mui.TableCell>);
                                        } else if(header.id === expandCollapseId) {
                                            const ariaLabel = `${(isExpanded ? "Collapse" : "Expand")} Row`;
                                            return (
                                                <Mui.TableCell key={header.id} data-header={header.label} align={headers.align} className="expandCollapse">
                                                    <Mui.IconButton aria-controls={expandedRowId} aria-expanded={isExpanded} aria-label={ariaLabel} title={ariaLabel}
                                                        size="small" onClick={() => setRowExpanded(rowIndex, !isExpanded)}>
                                                        {isExpanded ? <MuiIcons.ExpandMore /> : <MuiIcons.ExpandLess />}
                                                    </Mui.IconButton>
                                                </Mui.TableCell>
                                            );
                                        } else {
                                            return (<Mui.TableCell key={header.id} data-header={header.label} align={headers.align}>{row[header.id]}</Mui.TableCell>);
                                        }
                                    })}
                                </Mui.TableRow>
                                {isExpanded &&
                                    <Mui.TableRow id={expandedRowId} className="expandedRow">
                                        <Mui.TableCell colSpan={colSpan}>
                                            <AnnouncementContent data={row} showDismissed={showDismissed} />
                                        </Mui.TableCell>
                                    </Mui.TableRow>
                                }
                            </Fragment>
                        );
                    })}
                </Mui.TableBody>
                <Mui.TableFooter>
                    <Mui.TableRow>
                        <Mui.TablePagination
                            rowsPerPageOptions={[10, 20, 30, { label: "All", value: announcements.length }]}
                            colSpan={colSpan}
                            count={announcements.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{ inputProps: { "aria-label": "Rows per page" }, native: true}}
                            onPageChange={(e, page) => setPage(page)}
                            onRowsPerPageChange={(e) => {
                                setRowsPerPage(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                            classes={{ toolbar: classes.pagination }}
                        />
                    </Mui.TableRow>
                </Mui.TableFooter>
            </Mui.Table>
        </div>
    );
}

function AnnouncementContent(props) {
    const classes = useStyles();
    const { data, showDismissed } = props;
    const isGroup = (data.isGroup === true);

    const formatArrayForDisplay = (arrayString) => {
        const array = arrayString.join(", ");
        return (array.length > 0 ? array : "All");
    };

    if(isGroup) {
        return (
            <Mui.Grid container spacing={1} className="expandedContent">
                <Mui.Grid item xs={12}>
                    <Mui.Typography component="span" variant="subtitle2">Description: </Mui.Typography>
                    <Mui.Typography component="span" variant="body2">{data.message}</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item sm={4}>
                    <Mui.Grid item>
                        <Mui.Typography component="span" variant="subtitle2">Created By: </Mui.Typography>
                        <Mui.Typography component="span" variant="body2">{data.createdBy}</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item>
                        <Mui.Typography component="span" variant="subtitle2">Created Date: </Mui.Typography>
                        <Mui.Typography component="span" variant="body2">{data.createdDate}</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item>
                        <Mui.Typography component="span" variant="subtitle2">Updated By: </Mui.Typography>
                        <Mui.Typography component="span" variant="body2">{data.updatedBy}</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item>
                        <Mui.Typography component="span" variant="subtitle2">Updated Date: </Mui.Typography>
                        <Mui.Typography component="span" variant="body2">{data.updatedDate}</Mui.Typography>
                    </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid item sm={(data.authenticated === true ? 4 : 8)}>
                    {(data.application && data.application.length > 0) &&
                        <Mui.Grid item>
                            <Mui.Typography component="span" variant="subtitle2">Application: </Mui.Typography>
                            <Mui.Typography component="span" variant="body2">{formatArrayForDisplay(data.application)}</Mui.Typography>
                        </Mui.Grid>
                    }
                    <Mui.Grid item>
                        <Mui.Typography component="span" variant="subtitle2">Disabled: </Mui.Typography>
                        <Mui.Typography component="span" variant="body2">{booleanToString(data.disabled)}</Mui.Typography>
                    </Mui.Grid>
                    {showDismissed &&
                        <Mui.Grid item>
                            <Mui.Typography component="span" variant="subtitle2">Dismissed: </Mui.Typography>
                            <Mui.Typography component="span" variant="body2">{booleanToString(data.dismissed)}</Mui.Typography>
                        </Mui.Grid>
                    }
                    <Mui.Grid item>
                        <Mui.Typography component="span" variant="subtitle2">Screen: </Mui.Typography>
                        <Mui.Typography component="span" variant="body2">{data.screen}</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item>
                        <Mui.Typography component="span" variant="subtitle2">URL: </Mui.Typography>
                        <Mui.Typography component="span" variant="body2">{data.url}</Mui.Typography>
                    </Mui.Grid>
                </Mui.Grid>
                {data.authenticated === true &&
                    <Mui.Grid item sm={4}>
                        {data.affiliation.includes(ANNOUNCEMENT_AFFILIATION.FACULTY_AND_STAFF) &&
                            <Mui.Grid item>
                                <Mui.Typography component="span" variant="subtitle2">Faculty/Staff College: </Mui.Typography>
                                <Mui.Typography component="span" variant="body2">{formatArrayForDisplay(data.college)}</Mui.Typography>
                            </Mui.Grid>
                        }
                        {data.affiliation.includes(ANNOUNCEMENT_AFFILIATION.STUDENTS) &&
                            <Fragment>
                                <Mui.Grid item>
                                    <Mui.Typography component="span" variant="subtitle2">Student Campus: </Mui.Typography>
                                    <Mui.Typography component="span" variant="body2">{formatArrayForDisplay(data.campus)}</Mui.Typography>
                                </Mui.Grid>
                                <Mui.Grid item>
                                    <Mui.Typography component="span" variant="subtitle2">Student Academic Career: </Mui.Typography>
                                    <Mui.Typography component="span" variant="body2">{formatArrayForDisplay(data.academicCareer)}</Mui.Typography>
                                </Mui.Grid>
                                <Mui.Grid item>
                                    <Mui.Typography component="span" variant="subtitle2">Student Academic Program: </Mui.Typography>
                                    <Mui.Typography component="span" variant="body2">{formatArrayForDisplay(data.academicProgram)}</Mui.Typography>
                                </Mui.Grid>
                            </Fragment>
                        }
                    </Mui.Grid>
                }
            </Mui.Grid>
        );
    } else {
        return (
            <Mui.Grid container spacing={1} className="expandedContent">
                <Mui.Grid item sm={4}>
                    <Mui.Grid item>
                        <Mui.Typography component="span" variant="subtitle2">Created Date: </Mui.Typography>
                        <Mui.Typography component="span" variant="body2">{data.createdDate}</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item>
                        <Mui.Typography component="span" variant="subtitle2">Updated Date: </Mui.Typography>
                        <Mui.Typography component="span" variant="body2">{data.updatedDate}</Mui.Typography>
                    </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid item sm={8}>
                    {showDismissed &&
                        <Mui.Grid item>
                            <Mui.Typography component="span" variant="subtitle2">Dismissed: </Mui.Typography>
                            <Mui.Typography component="span" variant="body2">{booleanToString(data.dismissed)}</Mui.Typography>
                        </Mui.Grid>
                    }
                    {!isEmpty(data.screen) &&
                        <Mui.Grid item>
                            <Mui.Typography component="span" variant="subtitle2">Screen: </Mui.Typography>
                            <Mui.Typography component="span" variant="body2">{data.screen}</Mui.Typography>
                        </Mui.Grid>
                    }
                    <Mui.Grid item>
                        <Mui.Typography component="span" variant="subtitle2">URL: </Mui.Typography>
                        <Mui.Typography component="span" variant="body2" className={classes.url}>{data.url}</Mui.Typography>
                    </Mui.Grid>
                </Mui.Grid>
            </Mui.Grid>
        );
    }
}

const booleanToString = (bool) => ((bool === true) ? "Yes" : "No");