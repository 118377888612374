import React, { useEffect, useRef, useState } from "react";
import { Hint } from "react-autocomplete-hint";
import { Link } from "react-router-dom";
import { Alert, FormFeedback, FormText, Label, Input, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Body1, Body2, Caption, OSUButton, OSULoading, Subtitle2 } from "osu-react-components";
import { find, findIndex, isEmpty, keys, map } from "lodash";
import { ACTION_STATE_LOADING, ACTION_STATE_ERROR, ACTION_STATE_FORBIDDEN, ACTION_STATE_SUCCESS,
  APPLICATION_PACKAGES, NOTIFICATION_MESSAGE_TITLE_MAX_LENGTH, NOTIFICATION_MESSAGE_BODY_MAX_LENGTH,
  SCREEN_IDENTIFIER, SCREEN_OPTIONS } from "../../util/constants";
import { getFileRowCount } from "../../util/util";

const APPLICATIONS = map(keys(APPLICATION_PACKAGES), key => {
  return { label: APPLICATION_PACKAGES[key], value: key };
});
const FILE_EXTENSION = ".csv";
const FILE_NAME_REGEX = new RegExp(`^[-_a-zA-Z0-9]+${FILE_EXTENSION}`);
const MESSAGE_FIELD_MAX_LENGTH = "150"; // default max length for message fields without a specific max length
const MESSAGE_INPUT_STYLE = { maxWidth: "60rem" };

export default function Notification(props) {
  const { resetSubmitNotificationState, submitNotification, submitNotificationState } = props;
  const alertsEl = useRef(null);

  const [file, setFile] = useState(null);
  const [fileUserCount, setFileUserCount] = useState(null);
  const [isFileInvalid, setIsFileInvalid] = useState(false);
  const [appPackages, setAppPackages] = useState([]);
  const [messageTitle, setMessageTitle] = useState(null);
  const [messageBody, setMessageBody] = useState(null);
  //const [messageKey, setMessageKey] = useState(null);
  const [messageScreen, setMessageScreen] = useState(null);
  const [messageScreenFormat, setMessageScreenFormat] = useState(null);
  const [messageOnScreenText, setMessageOnScreenText] = useState(null);
  //const [messageSound, setMessageSound] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const trimValueToMaxLength = (value, maxLength) => {
    let trimmedValue = value;
    if(value && maxLength && value.length > maxLength) {
      trimmedValue = value.substring(0, maxLength);
    }
    return trimmedValue;
  }

  const resetFile = () => {
    document.getElementById("file").value = null; // need to manually clear the input
    setFile(null);
    setFileUserCount(null);
    setIsFileInvalid(false);
  };

  const onFileChange = (e) => {
    const input = e.target;
    const file = (input.files.length > 0 ? input.files[0] : null);
    if(file) {
      const isFileInvalid = !FILE_NAME_REGEX.test(file.name);
      setIsFileInvalid(isFileInvalid);
      if(!isFileInvalid) {
        setFile(file);
        try {
          getFileRowCount(file, setFileUserCount, true);
        } catch(error) { // ignore file read failures
          console.error("Failed to read users from file", error);
          setFileUserCount(null);
        }
      } else {
        setFile(null);
        setFileUserCount(null);
      }
    } else {
      resetFile();
    }
  };

  const onApplicationChange = (appPackage, checked) => {
    const index = appPackages.indexOf(appPackage);
    if(checked === true) {
      if(index === -1) setAppPackages([...appPackages, appPackage]);
    } else {
      if(index !== -1) {
        const updatedAppPackages = [...appPackages];
        updatedAppPackages.splice(index, 1);
        setAppPackages(updatedAppPackages);
      }
    }
  };

  const isSubmitButtonEnabled = () => {
    return (file !== null && appPackages.length > 0 && !isEmpty(messageBody)/*  && !isEmpty(messageKey) */ && !isEmpty(messageScreen));
  };

  const onSubmitConfirmation = () => {
    setIsConfirmationModalOpen(false);
    const notification = {
      appPackages,
      fileKey: file.name,
      message: {
        body: messageBody,
        //key: messageKey,
        onScreenText: messageOnScreenText,
        screen: messageScreen,
        //sound: messageSound,
        title: messageTitle
      }
    };
    // add (legacy) identifier to the notification message for older devices - pull from message screen
    const screenTokens = messageScreen.split(":");
    const screenFormat = find(SCREEN_OPTIONS, option => (option.label.includes(screenTokens[0])));
    if(screenFormat && screenFormat.format && screenFormat.format.includes(SCREEN_IDENTIFIER)) {
      const screenFormatTokens = screenFormat.format.split(":");
      const identifierIndex = findIndex(screenFormatTokens, token => (token.includes(SCREEN_IDENTIFIER))); // grabs first instance
      if(typeof screenTokens[identifierIndex] !== "undefined") { // validate that message screen has the identifier
        const lastIdentifierIndex = (((screenTokens.length - 1) > identifierIndex) ? (screenTokens.length - 1): identifierIndex); // grab the index of the last identifier (there can be multiple)
        const identifier = screenTokens[lastIdentifierIndex];
        if(!isEmpty(identifier)) notification.message.identifier = identifier;
      }
    }
    submitNotification(file, notification);
  };

  // when the submit notification state changes, set focus on alerts
  // when the state is success, reset fields
  useEffect(() => {
    if([ACTION_STATE_ERROR, ACTION_STATE_FORBIDDEN, ACTION_STATE_SUCCESS].includes(submitNotificationState)) {
      alertsEl.current.focus();
      if(submitNotificationState === ACTION_STATE_SUCCESS) {
        resetFile();
        setAppPackages([]);
        setMessageTitle(null);
        setMessageBody(null);
        //setMessageKey(null);
        setMessageScreen(null);
        setMessageOnScreenText(null);
        //setMessageSound(null);
      }
    }
  }, [submitNotificationState]);

  // when leaving the page, reset the submit notification state
  useEffect(() => {
    return () => {
      resetSubmitNotificationState();
    };
  }, [resetSubmitNotificationState]);

  return (
    <div>
      {submitNotificationState === ACTION_STATE_LOADING &&
        <OSULoading dataTestId="submittingNotification" text="Submitting Notification..." />
      }
      <div data-testid="content" className={submitNotificationState === ACTION_STATE_LOADING ? "d-none" : ""}>
        <div data-testid="alerts" ref={alertsEl} tabIndex="-1">
          <Alert data-testid="submitNotificationFailureAlert" color="danger" isOpen={submitNotificationState === ACTION_STATE_ERROR} toggle={resetSubmitNotificationState}>
            <Subtitle2>Submit Notification Failure:</Subtitle2>
            <p className="mb-0">
              The notification failed to submit due to an error. Please retry to see if that resolves the issue.
            </p>
          </Alert>
          <Alert data-testid="submitNotificationForbiddenAlert" color="danger" isOpen={submitNotificationState === ACTION_STATE_FORBIDDEN} toggle={resetSubmitNotificationState}>
            <Subtitle2>Submit Notification Failure:</Subtitle2>
            <p className="mb-0">
              The notification failed to submit because another notification is currently being processed.
              Visit the <Link to="/notification/history">Notification History</Link> page to view the status of notifications.
            </p>
          </Alert>
          <Alert data-testid="submitNotificationSuccessAlert" color="success" isOpen={submitNotificationState === ACTION_STATE_SUCCESS} toggle={resetSubmitNotificationState}>
            <Subtitle2>Submit Notification Success:</Subtitle2>
            <p className="mb-0">
              The notification was successfully submitted.
              Visit the <Link to="/notification/history">Notification History</Link> page to view the status of the notification.
            </p>
          </Alert>
        </div>

        <Link to="/notification/history" data-testid="historyLink">View History</Link>

        <h1 data-testid="heading" className="mt-3">Notification</h1>

        <Label for="file" className="mb-0 required">
          Users File
        </Label>
        <FormText id="fileDescription" color="muted" className="mt-0 mb-1">
          A comma-delimited file (.csv) that includes a header and the emplids (without quotes) of the users to notify.
        </FormText>
        <Input type="file" accept={FILE_EXTENSION} id="file" name="file" aria-describedby="fileDescription fileFeedback fileUserCount" aria-required={true}
          aria-invalid={isFileInvalid} invalid={isFileInvalid} onChange={onFileChange} className="d-inline-block" />
        <FormFeedback id="fileFeedback">The file name can include only alphanumeric characters, spaces, dashes, and underscores.</FormFeedback>
        {fileUserCount && <Caption id="fileUserCount" className="mt-1">File contains {fileUserCount} user(s).</Caption>}

        <fieldset className="mt-3">
          <legend className="mb-0 required" style={{ fontSize: "1rem" }} aria-describedby="applicationsDescription applicationsRequired">
            Application(s)
          </legend>
          <FormText id="applicationsDescription" color="muted" className="mt-0 mb-1">
            The application(s) on the users' devices that will receive the notification.
          </FormText>
          <span id="applicationsRequired" className="sr-only">At least one selection is required.</span>
          <div className="ml-4">
            {APPLICATIONS.map((application, index) => {
              const key = `application${index}`;
              return(
                <Label key={key} for={key} className="d-block">
                  <Input id={key} name={key} type="checkbox" value={application.value} checked={appPackages.includes(application.value)} 
                    onChange={(e) => onApplicationChange(application.value, e.target.checked)} />
                  {application.label}
                </Label>
              );
            })}
          </div>
        </fieldset>

        <fieldset className="mt-3">
          <legend className="mb-0" style={{ fontSize: "1rem" }}>
            Message
          </legend>
          <div className="ml-2">
            <Label for="messageTitle" className="mb-0 mt-1">
              Title
            </Label>
            <Input type="text" id="messageTitle" name="messageTitle" value={(messageTitle || "")} style={MESSAGE_INPUT_STYLE} 
              maxLength={NOTIFICATION_MESSAGE_TITLE_MAX_LENGTH}
              onChange={(e) => setMessageTitle(trimValueToMaxLength(e.target.value, NOTIFICATION_MESSAGE_TITLE_MAX_LENGTH))} />

            <Label for="messageBody" className="mb-0 mt-1 required">
              Body
            </Label>
            <Input type="text" id="messageBody" name="messageBody" value={(messageBody || "")} style={MESSAGE_INPUT_STYLE} 
              maxLength={NOTIFICATION_MESSAGE_BODY_MAX_LENGTH} aria-required={true}
              onChange={(e) => setMessageBody(trimValueToMaxLength(e.target.value, NOTIFICATION_MESSAGE_BODY_MAX_LENGTH))} />

            {/* ***** Deprecated *****
            <Label for="messageKey" className="mb-0 mt-1 required">
              Key
            </Label>
            <Input type="text" id="messageKey" name="messageKey" value={(messageKey || "")} style={MESSAGE_INPUT_STYLE}
              maxLength={MESSAGE_FIELD_MAX_LENGTH}
              onChange={(e) => setMessageKey(trimValueToMaxLength(e.target.value, MESSAGE_FIELD_MAX_LENGTH))} aria-required={true} /> */}

            <Label for="messageScreen" className="mb-0 mt-1 required">
              Screen
            </Label>
            <FormText id="messageScreenHelp" className="mt-0">Use the right arrow key to fill autocomplete suggestions.</FormText>
            <Hint options={SCREEN_OPTIONS} onFill={screenOption => { // screen provided by hint
              setMessageScreen(screenOption.label);
              setMessageScreenFormat((screenOption.format || null));
            }}>
              <input type="text" id="messageScreen" name="messageScreen" value={(messageScreen || "")} style={MESSAGE_INPUT_STYLE} aria-required={true}
                maxLength={MESSAGE_FIELD_MAX_LENGTH} className="form-control" autoComplete="off" aria-describedby="messageScreenHelp messageScreenFormat"
                onChange={(e) => { // screen provided through manual entry
                  const screenLabel = trimValueToMaxLength(e.target.value, MESSAGE_FIELD_MAX_LENGTH);
                  const screenOption = find(SCREEN_OPTIONS, [ "label", screenLabel ]);
                  const screenFormat = ((screenOption && screenOption.format) ? screenOption.format : null);
                  setMessageScreen(screenLabel);
                  setMessageScreenFormat(screenFormat);
                }} />
            </Hint>
            {messageScreenFormat && 
              <FormText id="messageScreenFormat" style={{ fontSize: "100%" }}>
                Format: <span dangerouslySetInnerHTML={{ __html: messageScreenFormat }} />
              </FormText>
            }

            <Label for="messageOnScreenText" className="mb-0 mt-1">
              On Screen Text
            </Label>
            <Input type="text" id="messageOnScreenText" name="messageOnScreenText" value={(messageOnScreenText || "")} style={MESSAGE_INPUT_STYLE}
              maxLength={MESSAGE_FIELD_MAX_LENGTH}
              onChange={(e) => setMessageOnScreenText(trimValueToMaxLength(e.target.value, MESSAGE_FIELD_MAX_LENGTH))} />

            {/* <Label for="messageSound" className="mb-0 mt-1">
              Sound
            </Label>
            <Input type="text" id="messageSound" name="messageSound" value={(messageSound || "")} style={MESSAGE_INPUT_STYLE}
              maxLength={MESSAGE_FIELD_MAX_LENGTH}
              onChange={(e) => setMessageSound(trimValueToMaxLength(e.target.value, MESSAGE_FIELD_MAX_LENGTH))} /> */}
          </div>
        </fieldset>

        <OSUButton ariaLabel="Submit Notification" color="blue" className="mt-3 mb-1" disabled={!isSubmitButtonEnabled()} 
          onClick={() => { if(isSubmitButtonEnabled()) setIsConfirmationModalOpen(true); }}>
          Submit
        </OSUButton>
      </div>
      <Modal data-testid="confirmationModal" isOpen={isConfirmationModalOpen}>
          <ModalHeader>Submit Notification Confirmation</ModalHeader>
          <ModalBody>
              <Body1 className="overflow-wrap-break-word pb-2">Please confirm that you want to submit the following notification{fileUserCount ? ` to ${fileUserCount} user(s)` : ""}.</Body1>
              <Body2 className="overflow-wrap-break-word pb-1">Users File: {file?.name}</Body2>
              <Body2 className="overflow-wrap-break-word pb-1">Application(s): {appPackages.map((appPackage) => (APPLICATION_PACKAGES[appPackage])).join(", ")}</Body2>
              <Caption color="gray" className="pb-1">Message</Caption>
              <div className="pl-1">
                <Body2 className="overflow-wrap-break-word pb-1">Title: {messageTitle}</Body2>
                <Body2 className="overflow-wrap-break-word pb-1">Body: {messageBody}</Body2>
                {/* <Body2 className="overflow-wrap-break-word pb-1">Key: {messageKey}</Body2> */}
                <Body2 className="overflow-wrap-break-word pb-1">Screen: {messageScreen}</Body2>
                <Body2 className="overflow-wrap-break-word pb-1">On Screen Text: {messageOnScreenText}</Body2>
                {/* <Body2 className="overflow-wrap-break-word pb-1">Sound: {messageSound}</Body2> */}
              </div>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <OSUButton ariaLabel="Cancel Notification Submit" color="gray" onClick={() => setIsConfirmationModalOpen(false)}>
              Cancel
            </OSUButton>
            <OSUButton ariaLabel="Confirm Notification Submit" color="blue" onClick={onSubmitConfirmation}>
              Confirm
            </OSUButton>
          </ModalFooter>
      </Modal>
    </div>
  );
}