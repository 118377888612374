import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import GetProviderContent from "../components/GetContent";
import { getProviderContent, GET_PROVIDER_CONTENT_STATE, getProviders } from "../../actions-index";
import { buildAction } from "../../util/util";

const mapStateToProps = (state) => {
    const { data: providers = {}, state: providersState = "" } = state.providers
    return {
        getProviderContentState: state.getProviderContentState,
        providers,
        providersState
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProviderContent: (provider) => dispatch((getProviderContent(provider))),
        getProviders: () => dispatch(getProviders()),
        resetGetProviderContentState: () => dispatch(buildAction(GET_PROVIDER_CONTENT_STATE, ""))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GetProviderContent));