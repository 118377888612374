import React from "react";
import PropTypes from "prop-types";
import { find, forEach, isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { Media } from "reactstrap";
import "../styles/articleLink.css";

function ArticleLink(props) {
    const { component, ...otherProps } = props;
    const { additions = [], components = [] } = component;
    const link = find(additions, ["type", "link"]);
    const articleTitle = find(components, ["role", "article_title"]);
    const articleThumbnail = find(components, ["role", "article_thumbnail"]);
    const url = (link ? link.URL : null);
    const title = (articleTitle ? articleTitle.text : null);
    const thumbnailURL = (articleThumbnail ? articleThumbnail.URL : null);
    const renderThumbnail = (thumbnailURL, title) => {
        return (thumbnailURL ? (<Media object src={thumbnailURL} alt={title ? title : null} className="thumbnailImage" />) : null);
    };
    const renderLink = (url, title) => {
        if(!isEmpty(url)) {
            const queryStringParams = ((url) => {
                var params = {};
                if(url.includes("?")) {
                    const paramsString = url.split("?")[1];
                    const paramStrings = paramsString.split("&");
                    forEach(paramStrings, paramString => {
                        const paramStringTokens = paramString.split("=");
                        params[paramStringTokens[0]] = paramStringTokens[1];
                    });
        
                }
                return params;
            })(url);
            const identifier = (queryStringParams.identifier || null);
            if(!isEmpty(identifier)) {
                return (
                    <span>
                        <Link to={`/content/${identifier}`} target="_blank" rel="noopener noreferrer">
                            {(!isEmpty(title) ? title : url)}
                        </Link>
                    </span>
                );
            } else {
                return (
                    <span>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            {(!isEmpty(title) ? title : url)}
                        </a>
                    </span>
                );
            }
        } else if (!isEmpty(title)) {
            return (<span>{title}</span>);
        } else {
            return null;
        }
    };
    return (
        <div {...otherProps}>
            {renderThumbnail(thumbnailURL, title)}
            {renderLink(url, title)}
        </div>
    );
}

ArticleLink.defaultProps = {
    component: {}
}

ArticleLink.propTypes = {
    component: PropTypes.object
}

export default ArticleLink;