import { API } from "aws-amplify";
import {
    APPROVED_CONTENT, APPROVED_CONTENT_FILTER, APPROVED_CONTENT_STATE,
    CONTENT_ITEM, CONTENT_ITEM_STATE,
    UPDATE_CONTENT_ITEM_STATUS, UPDATE_CONTENT_ITEM_STATUS_STATE
} from "../actions-index";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS } from "../util/constants";
import { buildAction } from "../util/util";
import { handleUnauthenticated } from "../Authentication/util";
import { isEmpty } from "lodash";

export function getApprovedContent() {
    return dispatch => {
        dispatch(buildAction(APPROVED_CONTENT_STATE, ACTION_STATE_LOADING));
        API.get(process.env.REACT_APP_API_NAME, "/content/approved")
        .then(response => {
            const approvedContent = (response.data && response.data.content) ? response.data.content : [];
            dispatch(buildAction(APPROVED_CONTENT, approvedContent));
            dispatch(buildAction(APPROVED_CONTENT_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Get Approved Content Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(APPROVED_CONTENT_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function filterApprovedContent(field, value) {
    return dispatch => dispatch(buildAction(APPROVED_CONTENT_FILTER, { field, value }));
}

export function getContentItem(itemId) {
    return dispatch => {
        dispatch(buildAction(CONTENT_ITEM_STATE, ACTION_STATE_LOADING));
        
        const errorHandler = error => {
            console.error("Get Content Item Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(CONTENT_ITEM_STATE, ACTION_STATE_ERROR));
            });
        };
        
        if(isEmpty(itemId)) {
            errorHandler("Request is missing item Id");
        } else {
            API.get(process.env.REACT_APP_API_NAME, `/content/article/${itemId}?main=y`)
            .then(response => {
                let contentItem = (response.data) ? response.data : null;
                if(contentItem) {
                    dispatch(buildAction(CONTENT_ITEM, contentItem));
                    dispatch(buildAction(CONTENT_ITEM_STATE, ACTION_STATE_SUCCESS));
                } else {
                    throw new Error("Response is missing data");
                }
            })
            .catch(errorHandler);
        }
    };
}

export function updateContentItemStatus(item, status) {
    return dispatch => {
        const itemId = item.main.identifier;
        dispatch(buildAction(UPDATE_CONTENT_ITEM_STATUS_STATE, ACTION_STATE_LOADING));
        API.post(process.env.REACT_APP_API_NAME, `/content/article/${itemId}/update-status`, { body: { status } })
        .then(() => {
            dispatch(buildAction(UPDATE_CONTENT_ITEM_STATUS_STATE, ACTION_STATE_SUCCESS));
            dispatch(buildAction(UPDATE_CONTENT_ITEM_STATUS, { item, status }));
        })
        .catch(error => {
            console.error("Update Content Item Status Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(UPDATE_CONTENT_ITEM_STATUS_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function resetUpdateContentItemStatusState() {
    return dispatch => {
        dispatch(buildAction(UPDATE_CONTENT_ITEM_STATUS_STATE, ""));
    };
}