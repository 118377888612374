import Moment from "moment";
import { DATE_FORMAT } from "./constants";

export function formatDateString(dateString) {
    const date = Moment(dateString, DATE_FORMAT);
    return date.isValid() ? date.valueOf() : "";
}

export function formatText(text) {
    return text.toLowerCase(); // fixes a sort issue with text that starts with a lowercase letter
}