import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AddChannelSectionContent from "../../components/ChannelSection/AddContent";
import { addChannelSectionArticle, filterAvailableContent, getAvailableContent,
    getChannelSection, resetAddChannelSectionArticleState, resetGetAvailableContentState } from "../../../actions-index";
import { filter, isEmpty } from "lodash";

const applyFilter = (content, contentFilter) => {
    if(content.length > 0 && !isEmpty(contentFilter)) {
        return filter(content, item => {
            let matches = true;
            for(const key in contentFilter) {
                const value = contentFilter[key];
                let field = item[key];
                if(React.isValidElement(field)) { // Mobile Apps article
                    field = field.props.children[0].props.children; // use first child, which is the title
                }
                if((key === "title" && field.toLowerCase().includes(value.toLowerCase())) || // search query (partial text search)
                    (typeof field === "string" && field === value)) {
                    continue;
                } else {
                    matches = false;
                }
            }
            return matches;
        });
    } else {
        return content;
    }
}

const mapStateToProps = (state) => {
    const { data: availableContent = [], filter: availableContentFilter = {}, state: availableContentState = "" } = state.availableContent;
    const { data: channelSection = {}, state: channelSectionState = "" } = state.channelSection;
    const filteredContent = applyFilter(availableContent, availableContentFilter);
    return {
        addChannelSectionArticleId: state.addChannelSectionArticleId,
        addChannelSectionArticleState: state.addChannelSectionArticleState,
        availableContent: filteredContent,
        availableContentFilter,
        availableContentState,
        channelSection,
        channelSectionState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addChannelSectionArticle: (channel, sectionId, articleId) => dispatch(addChannelSectionArticle(channel, sectionId, articleId)),
        filterAvailableContent: (field, value) => dispatch(filterAvailableContent(field, value)),
        getAvailableContent: (channel) => dispatch(getAvailableContent(channel)),
        getChannelSection: (channel, sectionId) => dispatch(getChannelSection(channel, sectionId)),
        resetAddChannelSectionArticleState: () => dispatch(resetAddChannelSectionArticleState()),
        resetGetAvailableContentState: () => dispatch(resetGetAvailableContentState())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddChannelSectionContent));