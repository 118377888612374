import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ChannelSection from "../../components/ChannelSection";
import { createChannelSection, deleteChannelSection, getChannelSection, removeChannelSectionArticle,
    resetChannelSectionState, resetDeleteChannelSectionState, resetRemoveChannelSectionArticleState,
    resetUpdateArticlesPriorityState, resetUpdateChannelSectionState, updateArticlesPriority,
    updateChannelSection } from "../../../actions-index";

const mapStateToProps = (state) => {
    const { data: channelSection = {}, state: channelSectionState = "" } = state.channelSection;
    return {
        channelSection,
        channelSectionState,
        channelNotFound: state.channelNotFound,
        deleteChannelSectionState: state.deleteChannelSectionState,
        removeChannelSectionArticleState: state.removeChannelSectionArticleState,
        updateArticlesPriorityState: state.updateArticlesPriorityState,
        updateChannelSectionState: state.updateChannelSectionState
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createChannelSection: (channel, title) => dispatch(createChannelSection(channel, title)),
        deleteChannelSection: (channel, sectionId, title) => dispatch(deleteChannelSection(channel, sectionId, title)),
        getChannelSection: (channel, sectionId) => dispatch(getChannelSection(channel, sectionId)),
        removeChannelSectionArticle: (channel, sectionId, articleId) => dispatch(removeChannelSectionArticle(channel, sectionId, articleId)),
        resetChannelSectionState: () => dispatch(resetChannelSectionState()),
        resetDeleteChannelSectionState: () => dispatch(resetDeleteChannelSectionState()),
        resetRemoveChannelSectionArticleState: () => dispatch(resetRemoveChannelSectionArticleState()),
        resetUpdateArticlesPriorityState: () => dispatch(resetUpdateArticlesPriorityState()),
        resetUpdateChannelSectionState: () => dispatch(resetUpdateChannelSectionState()),
        updateArticlesPriority: (channel, sectionId, articles) => dispatch(updateArticlesPriority(channel, sectionId, articles)),
        updateChannelSection: (channel, sectionId, currentTitle, newTitle) => dispatch(updateChannelSection(channel, sectionId, currentTitle, newTitle))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChannelSection));