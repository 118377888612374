import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Content from "../components";
import { filterApprovedContent, getApprovedContent } from "../../actions-index";
import _ from "lodash";

const applyFilter = (data, filter) => {
    if(data.length > 0 && !_.isEmpty(filter)) {
        return _.filter(data, item => {
            let matches = true;
            for(const key in filter) {
                const value = filter[key];
                let field = item[key];
                if(React.isValidElement(field)) { // Mobile Apps article
                    field = field.props.children[0].props.children; // use first child, which is the title
                }
                if((Array.isArray(field) && field.includes(value)) || 
                    (key === "title" && field.toLowerCase().includes(value.toLowerCase())) || // search query (partial text search)
                    (typeof field === "string" && field === value)) {
                    continue;
                } else {
                    matches = false;
                }
            }
            return matches;
        });
    } else {
        return data;
    }
};

const buildFilterOptions = (data) => {
    const filterOptions = { channels: [], providers: [], loginStates: [] };
    if(data.length > 0) {
        const channels = new Set(), providers = new Set(), loginStates = new Set();
        _.forEach(data, item => {
            providers.add(item.provider);
            _.forEach(item.channel, channel => {
                channels.add(channel);
            });
            loginStates.add(item.loginState);
        });
        function buildOptions(values) {
            if(values.length > 0) {
                values.sort((a, b) => {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                });
                return values.map(value => {
                    return { label: value, value };
                });
            } else {
                return [];
            }
        }
        filterOptions.channels = buildOptions(_.compact(Array.from(channels)));
        filterOptions.providers = buildOptions(_.compact(Array.from(providers)));
        filterOptions.loginStates = buildOptions(_.compact(Array.from(loginStates)));
    }
    return filterOptions
};

const mapStateToProps = (state) => {
    const { data = [], filter = {}, state: contentState = "" } = state.approvedContent;
    let filteredData = applyFilter(data, filter);
    const filterOptions = buildFilterOptions(filteredData);
    return {
        data: filteredData,
        filter,
        filterOptions,
        state: contentState
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        filterApprovedContent: (field, value) => dispatch(filterApprovedContent(field, value)),
        getApprovedContent: () => dispatch(getApprovedContent())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));