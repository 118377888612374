import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../App/components";
import AppBar from "./AppBar";
import NavLinks from "./NavLinks";
import Account from "./Account";
import "../styles/index.css";

function Navbar(props) {
  const { elevation, setNavHeight } = useContext(AppContext);
  return (
    <AppBar determineHeight={setNavHeight} elevation={elevation.navigation || 0} className={props.className}>
      <NavLinks />
      <Account className="ml-1" />
    </AppBar>
  ); 
}

Navbar.defaultProps = {
  className: ''
};

Navbar.propTypes = {
  className: PropTypes.string
};

export default Navbar;