import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

function Photo(props) {
    const { alt, caption, src } = props;
    const Image = (<img data-testid="photo-img" alt={alt} src={src} className="w-100" />);
    let Photo = Image;
    if(!isEmpty(caption)) {
        Photo = (
            <figure data-testid="photo-fig">
                {Image}
                <figcaption data-testid="photo-figcaption">{caption}</figcaption>
            </figure>
        );
    }
    return Photo;
}

Photo.propTypes = {
    alt: PropTypes.string.isRequired,
    caption: PropTypes.string,
    src: PropTypes.string.isRequired
}

export default Photo;