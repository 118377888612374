import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NotificationHistory from "../components/History";
import { getNotificationHistory } from "../../actions-index";

const mapStateToProps = (state) => {
    const { data: notificationHistory = [], state: notificationHistoryState = "" } = state.notificationHistory;
    return {
        notificationHistory,
        notificationHistoryState
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNotificationHistory: () => dispatch(getNotificationHistory())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationHistory));