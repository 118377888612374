import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PropTypes from "prop-types";
import ArticleSection from "./ArticleSection";

function ArticleSections(props) {
    const { articles, onDrag, onMoveToTop, onRemove } = props;
    const hasArticles = articles.length > 0;
    return (
        <div data-testid="article-sections">
            {!hasArticles &&
                <div data-testid="no-article-sections" className="font-weight-bold my-2 text-center">There are currently no content items for this section.</div>
            }
            {hasArticles &&
                <DragDropContext onDragEnd={onDrag}>
                <Droppable droppableId="articleSections">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {articles.map((article, index) => {
                                return (
                                    <Draggable key={article.identifier} draggableId={article.identifier} index={index}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="mb-2">
                                                <ArticleSection key={article.identifier} article={article} draggable={true}
                                                    onMoveToTop={index === 0 ? null : onMoveToTop} onRemove={onRemove} />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            }
        </div>
    );
}

ArticleSections.propTypes = {
    articles: PropTypes.arrayOf(PropTypes.shape({
        affiliation: PropTypes.arrayOf(PropTypes.string).isRequired,
        campus: PropTypes.arrayOf(PropTypes.string).isRequired,
        identifier: PropTypes.string.isRequired,
        loginState: PropTypes.string.isRequired,
        publishEndDate: PropTypes.string,
        publishStartDate: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    })),
    onDrag: PropTypes.func.isRequired,
    onRemove: PropTypes.func
}

export default ArticleSections;