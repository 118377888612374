import { ANNOUNCEMENT_AUDIENCE, ANNOUNCEMENT_AFFILIATION } from "../util/constants";

export function buildCollegeLabel(college) {
    return college.replace(/_/g, " ");
}

export function getAudience(authenticated, affiliation) {
    let audience;
    if(authenticated === true) {
        audience = ANNOUNCEMENT_AUDIENCE.AUTHENTICATED;
        const hasFacultyAndStaffAffiliation = affiliation.includes(ANNOUNCEMENT_AFFILIATION.FACULTY_AND_STAFF);
        const hasStudentsAffiliation = affiliation.includes(ANNOUNCEMENT_AFFILIATION.STUDENTS);
        if(hasFacultyAndStaffAffiliation && !hasStudentsAffiliation) audience = ANNOUNCEMENT_AUDIENCE.FACULTY_STAFF;
        if(!hasFacultyAndStaffAffiliation && hasStudentsAffiliation) audience = ANNOUNCEMENT_AUDIENCE.STUDENT;
    } else {
        audience = ANNOUNCEMENT_AUDIENCE.ALL;
    }
    return audience;
}