import React, { Fragment, /* useEffect,  */useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import { find } from "lodash";
import useStyles from "../styles/BulkAnnouncements";
import { transformDateStringToTime } from "../transform";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, SORT_DIRECTION } from "../../util/constants";
import { getSortComparator, sort } from "../../util/util";

export default function BulkAnnouncements(props) {
    const { bulkAnnouncements, bulkAnnouncementsState, getBulkAnnouncements/* , resetBulkAnnouncements */ } = props;
    const retryButton = useRef(null);

    const isLoading = (bulkAnnouncementsState === ACTION_STATE_LOADING);

    if(bulkAnnouncementsState === "") getBulkAnnouncements();

    // on unmount, reset bulk announcements state
    /* useEffect(() => {
        return () => {
            resetBulkAnnouncements();
        }
    }, [resetBulkAnnouncements]); */

    return (
        <div>
            <div className="d-flex mb-3">
                <Link id="announcementsLink" aria-label="Navigate to Announcements" to="/announcements" className="d-flex p-0">
                    <Mui.Typography variant="caption" color="primary">Announcements</Mui.Typography>
                </Link>
                <Mui.Typography variant="caption" className="px-1">/</Mui.Typography>
                <Mui.Typography variant="caption" color="secondary">Bulk Announcements</Mui.Typography>
            </div>
            <Mui.Box display="flex" justifyContent="space-between">
                <h1 id="heading">Bulk Announcements</h1>
                {bulkAnnouncementsState === ACTION_STATE_SUCCESS &&
                    <Mui.IconButton aria-label="Refresh Bulk Announcements" color="primary" onClick={() => getBulkAnnouncements()}>
                        <MuiIcons.Refresh fontSize="large" />
                    </Mui.IconButton>
                }
            </Mui.Box>
            {isLoading &&
                <Mui.Box display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center" marginTop="2rem">
                    <Mui.CircularProgress size="20px" aria-describedby="bulkAnnouncementsLoading" className="mb-2 mr-1" />
                    <Mui.Typography id="bulkAnnouncementsLoading" variant="body1" aria-live="assertive">Loading bulk announcements...</Mui.Typography>
                </Mui.Box>
            }
            {bulkAnnouncementsState === ACTION_STATE_ERROR && 
                <Mui.Box display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center" color="error" marginTop="2rem">
                    <Mui.Typography id="bulkAnnouncementsError" variant="body1" aria-live="assertive" color="error" className="mr-1">Failed to load bulk announcements</Mui.Typography>
                    <Mui.IconButton ref={retryButton} size="small" aria-label="Retry loading bulk announcements" className="mb-2" onClick={() => getBulkAnnouncements()}>
                        <MuiIcons.Replay fontSize="small" color="error" />
                    </Mui.IconButton>
                </Mui.Box>
            }
            {bulkAnnouncementsState === ACTION_STATE_SUCCESS &&
                <BulkAnnouncementsTable data={bulkAnnouncements} />
            }
        </div>
    );
}

function BulkAnnouncementsTable(props) {
    const { data = [] } = props;
    const [expandedRows, setExpandedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState("createdDate");
    const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.DESCENDING);
    const classes = useStyles();

    const expandCollapseId = "expandCollapse";
    const headers = [
        { id: "title", label: "Title", width: "22%", align: "left", sortable: true },
        { id: "createdBy", label: "Created By", width: "12%", align: "left", sortable: true },
        { id: "createdDate", label: "Created Date", width: "15%", align: "left", sortable: true, sortTransformer: transformDateStringToTime },
        { id: "startDate", label: "Start Date", width: "15%", align: "left", sortable: true, sortTransformer: transformDateStringToTime },
        { id: "endDate", label: "End Date", width: "15%", align: "left", sortable: true, sortTransformer: transformDateStringToTime },
        { id: "users", label: "Users", width: "8%", align: "left", sortable: true },
        { id: "status", label: "Status", width: "8%", align: "left", sortable: true },
        { id: expandCollapseId, label: "", width: "5%", align: "left", sortable: false }
    ];
    const colSpan = (headers.length);
    const sortByOptions = headers.filter(header => (header.sortable === true)).map(header => ({ label: header.label, value: header.id }));
    const sortTransformer = (find(headers, ["id", sortBy])?.sortTransformer ?? null);
    const bulkAnnouncements = sort(data, getSortComparator(sortBy, sortDirection, sortTransformer));
    const rows = (rowsPerPage > 0 ? bulkAnnouncements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : bulkAnnouncements);

    const onSort = (columnId) => {
        const isSortedAscending = (sortBy === columnId && sortDirection === SORT_DIRECTION.ASCENDING);
        setSortDirection(isSortedAscending ? SORT_DIRECTION.DESCENDING : SORT_DIRECTION.ASCENDING);
        setSortBy(columnId);
    };
    const setRowExpanded = (rowIndex, isExpanded) => {
        const expandedRowsIndex = expandedRows.indexOf(rowIndex);
        if(isExpanded === true) {
            if(expandedRowsIndex === -1) setExpandedRows([...expandedRows, rowIndex]);
        } else {
            if(expandedRowsIndex !== -1) {
                const updatedExpandedRows = [...expandedRows];
                updatedExpandedRows.splice(expandedRowsIndex, 1);
                setExpandedRows(updatedExpandedRows);
            }
        }
    };

    return (
        <div>
            <Mui.Hidden mdUp>
                <Mui.Box>
                    <Mui.Grid container spacing={2}>
                        <Mui.Grid item lg={6}>
                            <Mui.FormControl variant="outlined">
                                <Mui.InputLabel htmlFor="bulkAnnouncementsTableSortBySelect">Sort By</Mui.InputLabel>
                                <Mui.Select native inputProps={{ id: "bulkAnnouncementsTableSortBySelect", className: classes.sortBySelectInput }}
                                    value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                                    {sortByOptions.map((sortByOption) => (<option key={sortByOption.value} value={sortByOption.value}>{sortByOption.label}</option>))}
                                </Mui.Select>
                            </Mui.FormControl>
                        </Mui.Grid>
                        <Mui.Grid item lg={6}>
                            <Mui.FormControl>
                                <Mui.FormLabel id="bulkAnnouncementsTableSortDirectionLabel" classes={{ root: classes.sortDirectionRadioGroupLabel }}>
                                    <Mui.Typography component="span" variant="subtitle1">Sort Direction</Mui.Typography>
                                </Mui.FormLabel>
                                <Mui.RadioGroup id="bulkAnnouncementsTableSortDirectionRadioGroup" row aria-labelledby="bulkAnnouncementsTableSortDirectionLabel" name="bulkAnnouncementsTableSortDirection" value={sortDirection}
                                    classes={{ root: classes.sortDirectionRadioGroup }} onChange={e => { setSortDirection(e.target.value); }}>
                                    <Mui.FormControlLabel label="Ascending" value={SORT_DIRECTION.ASCENDING} control={<Mui.Radio classes={{ root: classes.sortDirectionRadio }} />} />
                                    <Mui.FormControlLabel label="Descending" value={SORT_DIRECTION.DESCENDING} control={<Mui.Radio classes={{ root: classes.sortDirectionRadio }} />} />
                                </Mui.RadioGroup>
                            </Mui.FormControl>
                        </Mui.Grid>
                    </Mui.Grid>
                </Mui.Box>
            </Mui.Hidden>
            <Mui.Table id="bulkAnnouncementsTable" aria-label="Bulk Announcements Table" className={classes.bulkAnnouncementsTable}>
                <caption className="sr-only">Bulk Announcements Table</caption>
                <Mui.TableHead>
                    <Mui.TableRow>
                        {headers.map((header) => (
                            <Mui.TableCell key={header.id} style={{ width: header.width}} align={header.align}
                                sortDirection={header.sortable === true ? (sortBy === header.id ? sortDirection : false) : null}>
                                {header.sortable === true ?
                                    (<Mui.TableSortLabel active={sortBy === header.id} onClick={() => onSort(header.id)}
                                        direction={sortBy === header.id ? sortDirection : SORT_DIRECTION.ASCENDING}>
                                        {header.label}
                                        {sortBy === header.id ? 
                                            (<span className="sr-only">
                                                {sortDirection === SORT_DIRECTION.DESCENDING ? "(Sorted Descending)" : "(Sorted Ascending)"}
                                            </span>) : null
                                        }
                                    </Mui.TableSortLabel>) : 
                                    <Fragment>{header.label}</Fragment>
                                }
                            </Mui.TableCell>
                        ))}
                    </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                    {rows.length === 0 &&
                        <Mui.TableRow id="noBulkAnnouncementsRow">
                            <Mui.TableCell colSpan={colSpan} className="noDataMessage">
                                <Mui.Typography component="span" variant="subtitle2" aria-live="assertive">No Bulk Announcements</Mui.Typography>
                            </Mui.TableCell>
                        </Mui.TableRow>
                    }
                    {rows.map((row, rowIndex) => {
                        const expandedRowId = `expandedRow${rowIndex}`;
                        const isExpanded = expandedRows.includes(rowIndex);
                        return (
                            <Fragment key={rowIndex}>
                                <Mui.TableRow aria-controls={expandedRowId} aria-expanded={isExpanded} onClick={() => setRowExpanded(rowIndex, !isExpanded)}>
                                    {headers.map((header) => {
                                        if(header.id === expandCollapseId) {
                                            const ariaLabel = `${(isExpanded ? "Collapse" : "Expand")} Row`;
                                            return (
                                                <Mui.TableCell key={header.id} data-header={header.label} align={headers.align} className="expandCollapse">
                                                    <Mui.IconButton aria-controls={expandedRowId} aria-expanded={isExpanded} aria-label={ariaLabel} title={ariaLabel}
                                                        size="small" onClick={() => setRowExpanded(rowIndex, !isExpanded)}>
                                                        {isExpanded ? <MuiIcons.ExpandMore /> : <MuiIcons.ExpandLess />}
                                                    </Mui.IconButton>
                                                </Mui.TableCell>
                                            );
                                        } else {
                                            return (<Mui.TableCell key={header.id} data-header={header.label} align={headers.align}>{row[header.id]}</Mui.TableCell>);
                                        }
                                    })}
                                </Mui.TableRow>
                                {isExpanded &&
                                    <Mui.TableRow id={expandedRowId} className="expandedRow">
                                        <Mui.TableCell colSpan={colSpan}>
                                            <BulkAnnouncementContent data={row} />
                                        </Mui.TableCell>
                                    </Mui.TableRow>
                                }
                            </Fragment>
                        );
                    })}
                </Mui.TableBody>
                <Mui.TableFooter>
                    <Mui.TableRow>
                        <Mui.TablePagination
                            rowsPerPageOptions={[10, 20, 30, { label: "All", value: bulkAnnouncements.length }]}
                            colSpan={colSpan}
                            count={bulkAnnouncements.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{ inputProps: { "aria-label": "Rows per page" }, native: true}}
                            onPageChange={(e, page) => setPage(page)}
                            onRowsPerPageChange={(e) => {
                                setRowsPerPage(parseInt(e.target.value, 10));
                                setPage(0);
                            }}
                            classes={{ toolbar: classes.pagination }}
                        />
                    </Mui.TableRow>
                </Mui.TableFooter>
            </Mui.Table>
        </div>
    );
}

function BulkAnnouncementContent(props) {
    const { data } = props;
    return (
        <Mui.Grid container spacing={1} className="expandedContent">
            <Mui.Grid item xs={12}>
                <Mui.Typography component="span" variant="subtitle2">Description: </Mui.Typography>
                <Mui.Typography component="span" variant="body2">{data.message}</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={6}>
                <Mui.Grid item>
                    <Mui.Typography component="span" variant="subtitle2">Updated By: </Mui.Typography>
                    <Mui.Typography component="span" variant="body2">{data.updatedBy}</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item>
                    <Mui.Typography component="span" variant="subtitle2">Updated Date: </Mui.Typography>
                    <Mui.Typography component="span" variant="body2">{data.updatedDate}</Mui.Typography>
                </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={6}>
                <Mui.Grid item>
                    <Mui.Typography component="span" variant="subtitle2">Priority: </Mui.Typography>
                    <Mui.Typography component="span" variant="body2">{data.priority}</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item>
                    <Mui.Typography component="span" variant="subtitle2">Screen: </Mui.Typography>
                    <Mui.Typography component="span" variant="body2">{data.screen}</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item>
                    <Mui.Typography component="span" variant="subtitle2">URL: </Mui.Typography>
                    <Mui.Typography component="span" variant="body2">{data.url}</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item>
                    <Mui.Typography component="span" variant="subtitle2">Users File: </Mui.Typography>
                    <Mui.Typography component="span" variant="body2">{data.usersFile}</Mui.Typography>
                </Mui.Grid>
            </Mui.Grid>
        </Mui.Grid>
    );
}