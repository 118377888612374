import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Img = styled.img`
    border-radius: 0.25rem;
    height: 4rem;
`;

const Title = styled.h6`
    font-size: 18px;
    font-weight: 700;
`;

const Caption = styled.p`
    color: #757575;
`;

function App(props) {
    const { caption, iconImgAlt, iconImgSrc, title } = props;
    return (
        <div className="d-flex mt-2">
            <div className="flex-column">
                <Img data-testid="app-icon" src={iconImgSrc} alt={iconImgAlt} />
            </div>
            <div className="flex-column">
                <Title data-testid="app-title">{title}</Title>
                {caption && <Caption data-testid="app-caption">{caption}</Caption>}
            </div>
        </div>
    );
}

App.propTypes = {
    caption: PropTypes.string,
    iconImgAlt: PropTypes.string,
    iconImgSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
}

export default App;
