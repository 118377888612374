export const ACTION_STATE_ALREADY_EXISTS = "already_exists";
export const ACTION_STATE_ERROR = "error";
export const ACTION_STATE_FORBIDDEN = "forbidden";
export const ACTION_STATE_LOADING = "loading";
export const ACTION_STATE_NOT_FOUND = "not_found";
export const ACTION_STATE_SUCCESS = "success";
export const ANNOUNCEMENT_AUDIENCE = {
    ALL: "ALL",
    AUTHENTICATED: "AUTHENTICATED",
    FACULTY_STAFF: "FACULTY - STAFF",
    STUDENT: "STUDENT"
};
export const ANNOUNCEMENT_AFFILIATION = {
    FACULTY_AND_STAFF: "facultyAndStaff",
    STUDENTS: "students"
};
export const ANNOUNCEMENT_DATE_DISPLAY_FORMAT = "L LT";
export const ANNOUNCEMENT_DATE_FIELD_FORMAT = "MM/DD/YYYY h:mm a";
export const ANNOUNCEMENT_FIELD_LABELS = {
    "usersFile": "Users File",
    "title": "Title",
    "message": "Description",
    "url": "Action",
    "startDate": "Start",
    "endDate": "End",
    "screen": "Screen",
    "priority": "Priority"
};
export const ANNOUNCEMENT_GROUP = "CONTENT_HUB";
export const ANNOUNCEMENT_TYPE = {
    BULK: "BULK",
    GROUP: "GROUP"
};
export const APPLICATION_NAME = "Content Hub";
export const APPLICATION_TITLE = "Content Hub | The Ohio State University";
export const APPLICATION_PACKAGES = {
    "edu.osu.Alumni": "Alumni",
    "edu.osu.My-OSU": "Discover",
    "edu.osu.osumobile": "Ohio State",
    "edu.osu.Wellness": "Wellness"
};
export const ARTICLE_CONTENT = "ARTICLE_CONTENT";
export const ARTICLE_MAIN = "ARTICLE_MAIN";
export const CHANNEL_COMPASS = "compass";
export const CHANNELS = {
    "alumni": "Alumni",
    [CHANNEL_COMPASS]: "Compass",
    "ohioState": "Ohio State",
    "wellness": "Wellness"
};
export const CONTENT_ITEM_STATUS_APPROVED = "Approved";
export const CONTENT_ITEM_STATUS_ARCHIVED = "Archived";
export const CONTENT_ITEM_STATUS_PENDING = "Pending";
export const CONTENT_ITEM_STATUS_REJECTED = "Rejected";
export const DATE_FORMAT = "MM/DD/YYYY";
export const EVENT_ANNOUNCEMENT_RESET_STATE = "announcementResetState";
export const NO = "NO";
export const NOTIFICATION_MESSAGE_TITLE_MAX_LENGTH = "65";
export const NOTIFICATION_MESSAGE_BODY_MAX_LENGTH = "160";
export const NOTIFICATION_STATUS_SKIPPED = "Skipped";
export const PROVIDER_MOBILE_APPS = "Mobile Apps";
export const SCREEN_IDENTIFIER = "identifier";
export const SCREEN_OPTIONS = [
    { label: "aboutYou" },
    { label: "aboutYouCustomize" },
    { label: "academicCalendar" },
    { label: "academicForecast" },
    { label: "academicForecastCourseDetail" },
    { label: "advising" },
    { label: "advisingNoteDetail" },
    { label: "alumniAuthenticationGetHelp" },
    { label: "alumniBio" },
    { label: "alumniBioDataTypeSelectionList" },
    { label: "alumniEvents" },
    { label: "alumniGameWatchLocations" },
    { label: "alumniGameWatchLocationDetail", format: "alumniGameWatchLocationDetail:<var>itemHash</var>" }, // (String)
    { label: "alumniGetInvolved" },
    { label: "alumniGifts" },
    { label: "alumniMemberships" },
    { label: "alumniOrganizations" },
    { label: "alumniOrganizationsLanding" },
    { label: "alumniOrganizationDetail", format: "alumniOrganizationDetail:<var>organizationId</var>" }, // (String)
    { label: "alumniResetPassword" },
    { label: "alumniSpirit" },
    { label: "alumniWelcome" },
    { label: "alumniRegisteredEvents" },
    { label: "appIcons" },
    { label: "appStoreReview" },
    { label: "athletics" },
    { label: "athleticsEvents" },
    { label: "athleticsNews" },
    { label: "athleticsSportList" },
    { label: "athleticsSport", format: "athleticsSport:<var>sportCode</var>" }, // (String)
    { label: "athleticsSportSchedule", format: "athleticsSportSchedule:<var>sportCode</var>" }, // (String)
    { label: "biometricAuthenticationSettings" },
    { label: "buckeyeLearn" },
    { label: "buckIdAccounts" },
    { label: "buckIdPhoto" },
    { label: "buckIdMerchants" },
    { label: "buckIdMerchantCategoryList" },
    { label: "buckIdMerchantDetail", format: "buckIdMerchantDetail:<var>merchantId</var>" }, // (String)
    { label: "buildingDetail", format: "buildingDetail:<var>buildingNumber</var>" }, // (String)
    { label: "buildingList" },
    { label: "buildingRoom", format: "buildingRoom:<var>roomType</var>:<var>buildingNumber</var>" }, // (String, String)
    { label: "buildingRoomList", format: "buildingRoomList:<var>roomType</var>" }, // (String)
    { label: "buses" },
    { label: "busHelp" },
    { label: "busesBusPrediction" },
    { label: "busesRoutePrediction" },
    { label: "busesStopPrediction" },
    { label: "calendarSelectionFilter" },
    { label: "campus" },
    { label: "campusAlert" },
    { label: "campusMap" },
    { label: "carmenAssignmentDetail", format: "carmenAssignmentDetail:<var>assignmentId</var>" }, // (Int)
    { label: "carmenAssignments", format: "carmenAssignments:<var>courseId</var>" }, // (String)
    { label: "carmenCalendar", format: "carmenCalendar:<var>courseId</var>" }, // (String)
    { label: "carmenCourses" },
    { label: "carmenCourseDetail", format: "carmenCourseDetail:<var>courseId</var>" }, // (String)
    { label: "carmenFiles", format: "carmenFiles:<var>couseId</var>" }, // (String)
    { label: "carmenGrades" },
    { label: "carmenGradesCourseDetail", format: "carmenGradesCourseDetail:<var>courseId</var>" }, // (String)
    { label: "carmenUsers", format: "carmenUsers:<var>courseId</var>" }, // (String)
    { label: "changePassword" },
    { label: "changePreferredFirstName" },
    { label: "classesCalendar" },
    { label: "classesCurrentTerm" },
    { label: "classesMeetingDetail", format: "classesMeetingDetail:<var>instanceIdUrl</var>" }, // (String)
    { label: "classesAllTerms" },
    { label: "classesWeeklyView" },
    { label: "consoleDebug" },
    { label: "contentPublisherArticle", format: `contentPublisherArticle:<var>articleSource</var>:<var>${SCREEN_IDENTIFIER}</var>` }, // (String, String)
    { label: "contentPublisherGalleryComponentPageView" },
    { label: "courseSearch" },
    { label: "courseSearchFilter" },
    { label: "coursePlanner" },
    { label: "coursePlannerMenu" },
    { label: "coursePlannerBackups" },
    { label: "coursePlannerPlanBackups" },
    { label: "coursePlannerPreview" },
    { label: "coursePlannerSearch" },
    { label: "coursePlannerSemesterFilter" },
    { label: "crisis" },
    { label: "dateTimeSettings" },
    { label: "demoUsers" },
    { label: "demoUsersGraduationPush" },
    { label: "developer" },
    { label: "developerLog" },
    { label: "developerSymbolsList" },
    { label: "dining" },
    { label: "diningIpad" },
    { label: "diningLocation", format: "diningLocation:<var>locationId</var>" }, // (NSNumber)
    { label: "diningMenu" },
    { label: "diningMenuItem" },
    { label: "diningMenuFilter" },
    { label: "discoverGetInvolved" },
    { label: "downloadApps" },
    { label: "downloadAppDetail", format: "downloadAppDetail:<var>itemHash</var>" }, // (String)
    { label: "employeeLeave" },
    { label: "events" },
    { label: "eventsFilter" },
    { label: "eventsDetail" },
    { label: "eventsDetailWebview" },
    { label: "exampleTableView" },
    { label: "exampleGroupedTableView" },
    { label: "feedback" },
    { label: "feedbackReview" },
    { label: "finalGrades" },
    { label: "findPeople" },
    { label: "findPeoplePersonDetail" },
    { label: "forYou" },
    { label: "forYouArticles", format: `forYouArticles:<var>${SCREEN_IDENTIFIER}</var> (join additional identifiers with colons)` }, // (String)
    { label: "genderInclusiveRestrooms" },
    { label: "graduationPushNotification" },
    { label: "infoCells" },
    { label: "lactationRooms" },
    { label: "libraries" },
    { label: "libraryLocation", format: "libraryLocation:<var>locationId</var>" }, //(NSNumber)
    { label: "libraryRoomReservations" },
    { label: "libraryRoomReservationBuildingSelection" },
    { label: "libraryRoomReservationRoomSelection", format: "libraryRoomReservationRoomSelection:<var>locationId</var>" }, // (Int64)
    { label: "libraryRoomReservationSubmit" },
    { label: "libraryRoomReservationTimeSlotSelection", format: "libraryRoomReservationTimeSlotSelection:<var>roomId</var>" }, // (Int64)
    { label: "locationMap" },
    { label: "locationServicesSettings" },
    { label: "misconduct" },
    { label: "more" },
    { label: "paycheck" },
    { label: "notImportantForPushOrViewTrackingAnalytics" },
    { label: "notSet" },
    { label: "orientationSchedule" },
    { label: "osuIdOverrideConfiguration" },
    { label: "osuWireless" },
    { label: "parking" },
    { label: "pleaseLogIn" },
    { label: "pleaseUpdate" },
    { label: "prepareForStore" },
    { label: "pushNotificationSettings" },
    { label: "ringtoneForm" },
    { label: "ringtones" },
    { label: "safety" },
    { label: "safetyAdditionalContacts" },
    { label: "safetyCategory" },
    { label: "safetyCategoryWebView" },
    { label: "safetyProgramsAndServices" },
    { label: "savedDiscoverArticles" },
    { label: "search" },
    { label: "sei" },
    { label: "seiEvaluation" },
    { label: "serviceIndicators" },
    { label: "serviceNow" },
    { label: "siri" },
    { label: "siriShortcuts" },
    { label: "statementOfAccount" },
    { label: "statementOfAccountActivity" },
    { label: "stickers" },
    { label: "studentOrganization", format: "studentOrganization:<var>organizationId</var>" }, // (NSNumber)
    { label: "studentOrganizations" },
    { label: "studentOrganizationsFilter" },
    { label: "symptomTracking" },
    { label: "symptomTrackingForm" },
    { label: "symptomTrackingIntro" },
    { label: "symptomTrackingTestingInfo" },
    { label: "timesheet" },
    { label: "todayAssignmentsExtension" },
    { label: "todayBuckIdExtension" },
    { label: "todayClassesExtension" },
    { label: "todayForYouExtension" },
    { label: "todayLeaveExtension" },
    { label: "todayNextGameDayExtension" },
    { label: "todayParkingExtension" },
    { label: "todayWellnessExtension" },
    { label: "todayWellnessExploreExtension" },
    { label: "todaySymptomTrackingExtension" },
    { label: "transportation" },
    { label: "updateMailingAddress" },
    { label: "updateMailingAddressForm" },
    { label: "wallpapers" },
    { label: "wallpaperDetail" },
    { label: "wellness" },
    { label: "wellnessGoalConfiguration" },
    { label: "wellnessGoals" },
    { label: "wellnessPlan" },
    { label: "wellnessSupport" },
    { label: "wellnessSupportArticle", format: `wellnessSupportArticle:<var>${SCREEN_IDENTIFIER}</var>` }, // (String)
    { label: "wellnessSupportArticles", format: `wellnessSupportArticles:<var>${SCREEN_IDENTIFIER}</var> (join additional identifiers with colons)` }, // (String)
    { label: "wifiLocations" },
    { label: "zoom" }
];
export const SORT_DIRECTION = {
    ASCENDING: "asc",
    DESCENDING: "desc"
};
export const YES = "YES";
export const STYLESHEET_LENGTH = document && document.styleSheets ? document.styleSheets.length : 99