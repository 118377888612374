import React from "react";
import PropTypes from "prop-types";
import ArticleLink from "./ArticleLink";

function Container(props) {
    const { components, displayType, ...otherProps } = props;
    const isCollection = (displayType === "collection");
    let containerContent = null;
    if(components.length > 0) {
        containerContent = components.map(component => {
            const { identifier, role } = component;
            switch(role) {
                case "article_link":
                    return (<ArticleLink key={identifier} data-testid={`articleLink-${identifier}`} component={component} className={`m-1${(isCollection === true ? " flex-column" : "")}`} />);
                default:
                    return null;
            }
        });
    }
    return (
        <div {...otherProps} className={(isCollection === true ? "d-flex flex-wrap" : "d-block")}>
            {containerContent}
        </div>
    );
}

Container.defaultProps = {
    components: [],
    displayType: null
}

Container.propTypes = {
    components: PropTypes.arrayOf(PropTypes.shape({
        components: PropTypes.arrayOf(PropTypes.object),
        identifier: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired
    })),
    displayType: PropTypes.string
}

export default Container;