import { Auth } from "aws-amplify";

export function redirectToSignIn() {
    Auth.federatedSignIn({ customProvider: process.env.REACT_APP_SAML_PROVIDER });
}

export function redirectToSignOut() {
    const { userPoolWebClientId, oauth } = Auth.configure();
    window.location.assign(`https://${oauth.domain}/logout?&client_id=${userPoolWebClientId}&logout_uri=${oauth.redirectSignOut}`);
}

export function isUnauthenticated(error) {
    const status = (error.response && error.response.status) ? error.response.status : null;
    const unauthorizedErrors = ["No current user", "Request failed with status code 401"];
    return (unauthorizedErrors.includes(error) || status === 401) ? true : false;
}

export function handleUnauthenticated(error, callback) {
    if(isUnauthenticated(error)) {
        Auth.signOut();
        redirectToSignOut();
    } else {
        callback();
    }
}