import { GET_PROVIDER_CONTENT_STATE, PROVIDERS, PROVIDERS_STATE } from "../actions-index";

export function providers(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case PROVIDERS:
            return { ...state, data: payload };
        case PROVIDERS_STATE:
            return { ...state, state: payload };
        default:
            return state;
    }
}

export function getProviderContentState(state = "", action = {}) {
    return (action.type === GET_PROVIDER_CONTENT_STATE) ? action.payload : state;
}
