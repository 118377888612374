import { ANNOUNCEMENT, ANNOUNCEMENT_STATE, ANNOUNCEMENTS, ANNOUNCEMENT_OPERATION_STATE, ANNOUNCEMENTS_STATE, BULK_ANNOUNCEMENT_FILE_KEY, BULK_ANNOUNCEMENT_OPERATION_STATE,
    BULK_ANNOUNCEMENTS, BULK_ANNOUNCEMENTS_STATE } from "../actions-index";

export function announcements(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case ANNOUNCEMENTS:
            return { ...state, data: payload };
        case ANNOUNCEMENTS_STATE:
            return { ...state, state: payload };
        default:
            return state;
    }
}

export function announcement(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case ANNOUNCEMENT:
            return { ...state, data: payload };
        case ANNOUNCEMENT_STATE:
            return { ...state, state: payload };
        default:
            return state;
    }
}

export function announcementOperationState(state = "", action = {}) {
    return (action.type === ANNOUNCEMENT_OPERATION_STATE) ? action.payload : state;
}

export function bulkAnnouncementFileKey(state = null, action = {}) {
    return (action.type === BULK_ANNOUNCEMENT_FILE_KEY) ? action.payload : state;
}

export function bulkAnnouncementOperationState(state = "", action = {}) {
    return (action.type === BULK_ANNOUNCEMENT_OPERATION_STATE) ? action.payload : state;
}

export function bulkAnnouncements(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case BULK_ANNOUNCEMENTS:
            return { ...state, data: payload };
        case BULK_ANNOUNCEMENTS_STATE:
            return { ...state, state: payload };
        default:
            return state;
    }
}