import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import App from "../components";
import { getUser, signIn, signOut } from "../../actions-index";

const mapStateToProps = (state) => {
    const { data = {}, state: userState = "" } = state.user;
    return {
        user: {
            ...data,
            state: userState
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: () => dispatch(getUser()),
        signIn: () => dispatch(signIn()),
        signOut: () => dispatch(signOut())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

