import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import Article from "../../Article/components";
import { PROVIDER_MOBILE_APPS } from "../../util/constants";
import { arrayToString, formatDateString } from "../../util/util";

function ContentItem(props) {
    const { content } = props;
    return (
        <article>
            <section>
                <dl>
                    <dt data-testid="titleLabel" className="d-inline">Title: </dt>
                    <dd data-testid="titleValue" className="d-inline">{content.title}</dd>
                </dl>
                <dl>
                    <dt data-testid="subtitleLabel" className="d-inline">Subtitle: </dt>
                    <dd data-testid="subtitleValue" className="d-inline">{content.subtitle}</dd>
                </dl>
                <dl>
                    <dt data-testid="channelsLabel" className="d-inline">Channel(s): </dt>
                    <dd data-testid="channelsValue" className="d-inline">{arrayToString(content.channel)}</dd>
                </dl>
                <dl>
                    <dt data-testid="providerLabel" className="d-inline">Provider: </dt>
                    <dd data-testid="providerValue" className="d-inline">{content.provider}</dd>
                </dl>
                <Row>
                    <Col sm="2">
                        <dl>
                            <dt data-testid="publishStartDateLabel">Publish Start Date</dt>
                            <dd data-testid="publishStartDateValue">{formatDateString(content.publishStartDate)}</dd>
                        </dl>
                    </Col>
                    <Col sm="2">
                        <dl>
                            <dt data-testid="publishEndDateLabel">Publish End Date</dt>
                            <dd data-testid="publishEndDateValue">{formatDateString(content.publishEndDate)}</dd>
                        </dl>
                    </Col>
                </Row>
                {content.provider === PROVIDER_MOBILE_APPS &&
                    <Fragment>
                        <dl>
                            <dt data-testid="platformLabel" className="d-inline">Platform(s): </dt>
                            <dd data-testid="platformValue" className="d-inline">{(content?.platform && content.platform.length > 0) ? content.platform.join(", ") : ""}</dd>
                        </dl>
                        <Row>
                            <Col sm="2">
                                <dl>
                                    <dt data-testid="appBuildStartLabel">App Build Start</dt>
                                    <dd data-testid="appBuildStartValue">{content.appBuildStart}</dd>
                                </dl>
                            </Col>
                            <Col sm="2">
                                <dl>
                                    <dt data-testid="appBuildEndLabel">App Build End</dt>
                                    <dd data-testid="appBuildEndValue">{content.appBuildEnd}</dd>
                                </dl>
                            </Col>
                        </Row>
                    </Fragment>
                }
                <Row>
                    <Col sm="3">
                        <dl>
                            <dt data-testid="campusLabel">Campus</dt>
                            <dd data-testid="campusValue">{arrayToString(content.campus)}</dd>
                        </dl>
                    </Col>
                    <Col sm="3">
                        <dl>
                            <dt data-testid="admitTermLabel">DFI Admit Term</dt>
                            <dd data-testid="admitTermValue">{arrayToString(content.admitTerm)}</dd>
                        </dl>
                    </Col>
                    <Col sm="3">
                        <dl>
                            <dt data-testid="admitTypeLabel">Admit Type</dt>
                            <dd data-testid="admitTypeValue">{arrayToString(content.admitType)}</dd>
                        </dl>
                    </Col>
                    <Col sm="3">
                        <dl>
                            <dt data-testid="audienceLabel">Audience</dt>
                            <dd data-testid="audienceValue">{arrayToString(content.affiliation)}</dd>
                        </dl>
                    </Col>
                </Row>
            </section>
            <section className="mt-5">
                <header>
                    <h2>Article</h2>
                </header>
                <Article imgSrc={content.metadata.thumbnailURL} imgAlt={content.metadata.excerpt}
                        title={content.title} subtitle={content.subtitle} content={content.components} />
            </section>
        </article>
    )
}

ContentItem.propTypes = {
    content: PropTypes.object.isRequired
}

export default ContentItem;