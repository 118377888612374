import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Alert, Form, FormGroup, FormFeedback, Input, Label,
    Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Caption, OSUButton, OSUError, OSULoading } from "osu-react-components";
import { cloneDeep, find, forEach, isEmpty } from "lodash";
import ArticleSections from "../../../Common/components/ArticleSections";
import NavigationPrompt from "../../../Common/components/NavigationPrompt";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS } from "../../../util/constants";

const TITLE_MAX_LENGTH = 100;

export default function ChannelSection(props) {
    const { channelNotFound, channelSection, channelSectionState, createChannelSection, deleteChannelSection, 
        deleteChannelSectionState, getChannelSection, match = {}, removeChannelSectionArticle, removeChannelSectionArticleState,
        resetRemoveChannelSectionArticleState, resetChannelSectionState, resetDeleteChannelSectionState, resetUpdateArticlesPriorityState,
        resetUpdateChannelSectionState, updateArticlesPriority, updateArticlesPriorityState, updateChannelSection, updateChannelSectionState } = props;
    const params = match.params || {};
    const channel = params.channel;
    const sectionId = params.sectionId;
    const isNewSection = isEmpty(sectionId);
    const heading = isNewSection ? "Create Section" : "Edit and Organize Section";
    const history = useHistory();
    const alertsEl = useRef(null);
    const deleting = (deleteChannelSectionState === ACTION_STATE_LOADING);
    const saving = (channelSectionState === ACTION_STATE_LOADING);
    const updating = (updateChannelSectionState === ACTION_STATE_LOADING);
    const savingContent = (updateArticlesPriorityState === ACTION_STATE_LOADING);
    const removingContent = (removeChannelSectionArticleState === ACTION_STATE_LOADING);
    const [title, setTitle] = useState("");
    const [titleInvalid, setTitleInvalid] = useState(false);
    const onTitleChange = (event) => {
        const newTitle = event.target.value;
        setTitle(newTitle);
        setTitleInvalid(newTitle.length > TITLE_MAX_LENGTH);
    }
    const [articles, setArticles] = useState([]);
    const [hasArticleUpdates, setHasArticleUpdates] = useState(false);
    const onArticleDrag = (result) => {
        if(result && result.source && result.destination) { // handles an error due to multiple quick swaps
            const articlesUpdate = cloneDeep(articles);
            const sourceIndex = result.source.index;
            const source = articlesUpdate[sourceIndex];
            const destinationIndex = result.destination.index;

            articlesUpdate.splice(sourceIndex, 1);
            articlesUpdate.splice(destinationIndex, 0, source);
            forEach(articlesUpdate, (article, index) => {
                article.priority = (index + 1);
            });

            setArticles(articlesUpdate);
            setHasArticleUpdates(true);
        }
    }
    const onArticleMoveToTop = (articleId) => {
        const article = find(articles, article => {
            return (article.identifier === articleId);
        });
        article.priority = 1;

        const articlesUpdate = cloneDeep(articles).filter(article => {
            return (article.identifier !== articleId);
        });
        forEach(articlesUpdate, (articleUpdate, index) => {
            articleUpdate.priority = (index + 2);
        });

        articlesUpdate.unshift(article);
        setArticles(articlesUpdate);
        setHasArticleUpdates(true);
    };
    const onArticleRemove = (articleId) => {
        removeChannelSectionArticle(channel, sectionId, articleId);
    }
    const cancelArticleChanges = () => {
        setArticles(channelSection.articles);
        setHasArticleUpdates(false);
    }
    const saveArticleChanges = () => {
        const articlesPriority = articles.map(article => {
            return { articleId: article.identifier, priority: article.priority };
        });
        updateArticlesPriority(channel, sectionId, articlesPriority);
    }
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const hasUnsavedChanges = (isNewSection && !isEmpty(title) && channelSectionState !== ACTION_STATE_SUCCESS) ||
        (!isNewSection && (title !== channelSection.title || hasArticleUpdates));

    // get the channel section when the sectionId param is provided
    useEffect(() => {
        if(!isNewSection) getChannelSection(channel, sectionId);
    }, [channel, getChannelSection, isNewSection, sectionId]);

    // set the title after getting the channel section
    useEffect(() => {
        if(!isNewSection && channelSectionState === ACTION_STATE_SUCCESS) setTitle(channelSection.title);
    }, [channelSection.title, channelSectionState, isNewSection]);

    // set the articles after getting the channel section
    useEffect(() => {
        if(channelSection.articles) {
            setArticles(channelSection.articles);
        }
    }, [channelSection.articles]);

    // focus on alerts when there is an channel section error
    useEffect(() => {
        if(channelSectionState === ACTION_STATE_ERROR) alertsEl.current.focus();
    }, [channelSectionState]);

    // focus on alerts when there is an delete channel section error
    useEffect(() => {
        if(deleteChannelSectionState === ACTION_STATE_ERROR) alertsEl.current.focus();
    }, [deleteChannelSectionState]);

    // focus on alerts when there is a remove channel section article error
    // get the channel section when remove channel section article is successful
    useEffect(() => {
        if(removeChannelSectionArticleState === ACTION_STATE_ERROR) alertsEl.current.focus();
        if(removeChannelSectionArticleState === ACTION_STATE_SUCCESS) {
            resetRemoveChannelSectionArticleState();
            getChannelSection(channel, sectionId);
        }
    }, [channel, getChannelSection, removeChannelSectionArticleState, resetRemoveChannelSectionArticleState, sectionId]);

    // focus on alerts when there is an update channel section error
    useEffect(() => {
        if(updateChannelSectionState === ACTION_STATE_ERROR) alertsEl.current.focus();
    }, [updateChannelSectionState]);

    // focus on alerts when there is an update articles priority error
    // reset state when update articles priority is successful
    useEffect(() => {
        if(updateArticlesPriorityState === ACTION_STATE_ERROR) {
            alertsEl.current.focus();
        }
        if(updateArticlesPriorityState === ACTION_STATE_SUCCESS) {
            setHasArticleUpdates(false);
            resetUpdateArticlesPriorityState();
        }
    }, [resetUpdateArticlesPriorityState, updateArticlesPriorityState]);

    // redirect to the Edit Section page when section creation is successful
    useEffect(() => {
        if(isNewSection && channelSectionState === ACTION_STATE_SUCCESS) {
            history.push(`/organize/channel/${channel}/section/${channelSection.sectionId}`);
        }
    }, [channel, channelSection, channelSectionState, history, isNewSection]);

    // redirect to the Organize page when delete section is successful
    useEffect(() => {
        if(deleteChannelSectionState === ACTION_STATE_SUCCESS) history.push("/organize");
    }, [deleteChannelSectionState, history]);

    // reset channel section state when the component is unmounted
    useEffect(() => {
        return () => {
            resetChannelSectionState();
            if(!isNewSection)  {
                resetDeleteChannelSectionState();
                resetRemoveChannelSectionArticleState();
                resetUpdateArticlesPriorityState();
                resetUpdateChannelSectionState();
            }
        }
    }, [isNewSection, resetChannelSectionState, resetDeleteChannelSectionState, resetRemoveChannelSectionArticleState, resetUpdateArticlesPriorityState, resetUpdateChannelSectionState]);

    return (
        <div>
            <div data-testid="alerts" ref={alertsEl} tabIndex="-1">
                {deleteChannelSectionState === ACTION_STATE_ERROR &&
                    <Alert data-testid="delete-section-alert" color="danger" toggle={resetDeleteChannelSectionState}>
                        <h3>Delete Section Failure</h3>
                        <p className="mb-0">Failed to delete the section. Please retry to see if that resolves the issue.</p>
                    </Alert>
                }
                {(isNewSection && channelSectionState === ACTION_STATE_ERROR) &&
                    <Alert data-testid="save-section-alert" color="danger" toggle={resetChannelSectionState}>
                        <h3>Save Section Failure</h3>
                        <p className="mb-0">
                            {channelNotFound && 
                                `Channel '${channel}' not found.`
                            }
                            {!channelNotFound &&
                                "Failed to save the section. Please retry to see if that resolves the issue."
                            }
                        </p>
                    </Alert>
                }
                {removeChannelSectionArticleState === ACTION_STATE_ERROR &&
                    <Alert data-testid="remove-content-alert" color="danger" toggle={resetRemoveChannelSectionArticleState}>
                        <h3>Remove Content Failure</h3>
                        <p className="mb-0">Failed to remove the content item from the section. Please retry to see if that resolves the issue.</p>
                    </Alert>
                }
                {updateChannelSectionState === ACTION_STATE_ERROR &&
                    <Alert data-testid="update-section-alert" color="danger" toggle={resetUpdateChannelSectionState}>
                        <h3>Update Section Failure</h3>
                        <p className="mb-0">Failed to update the section. Please retry to see if that resolves the issue.</p>
                    </Alert>
                }
                {updateArticlesPriorityState === ACTION_STATE_ERROR &&
                    <Alert data-testid="update-content-alert" color="danger" toggle={resetUpdateArticlesPriorityState}>
                        <h3>Update Content Failure</h3>
                        <p className="mb-0">{`Failed to update {channel} Content. Please retry to see if that resolves the issue.`}</p>
                    </Alert>
                }
            </div>
            <div data-testid="breadcrumb" className="d-flex mb-3">
                <Link to="/organize"><Caption>Organize</Caption></Link>
                <Caption className="pl-1 pr-1">/</Caption>
                <Caption>{heading}</Caption>
            </div>
            <h1 data-testid="header">{channel} {heading}</h1>
            {(!isNewSection && channelSectionState === ACTION_STATE_LOADING) &&
                <OSULoading dataTestId="loading-section" text="Loading Section..." />
            }
            {(!isNewSection && channelSectionState === ACTION_STATE_ERROR) &&
                <OSUError dataTestId="error-section" text="Failed to retrieve section."
                small="true" actionText="Retry" ariaLabel="Retry to retrieve section" 
                onClick={() => getChannelSection(channel, sectionId)} />
            }
            {(isNewSection || (!isNewSection && channelSectionState === ACTION_STATE_SUCCESS)) &&
                <div>
                    {deleting && <OSULoading dataTestId="deleting-section" text="Deleting Section..." />}
                    {saving && <OSULoading dataTestId="saving-section" text="Saving Section..." />}
                    {updating && <OSULoading dataTestId="updating-section" text="Updating Section..." />}
                    {savingContent && <OSULoading dataTestId="saving-content" text="Saving Content..." />}
                    {removingContent && <OSULoading dataTestId="removing-content" text="Removing Content..." />}
                    <div data-testid="content" className={(deleting || saving || updating || savingContent || removingContent) ? "d-none" : ""}>
                        {!isNewSection && <h2 data-testid="section-title" className="mb-2"><span className="sr-only">Section: </span>{channelSection.title}</h2>}
                        <Form>
                            <FormGroup>
                                <Label for="title" className="font-weight-bold">Name</Label>
                                <Input type="text" id="title" maxLength={TITLE_MAX_LENGTH} value={title}
                                    invalid={titleInvalid} className="mb-2" style={{ maxWidth: "25rem" }} onChange={onTitleChange} />
                                <FormFeedback>Name cannot be greater than {TITLE_MAX_LENGTH} characters.</FormFeedback>
                            </FormGroup>
                        </Form>
                        {isNewSection &&
                            <div className="d-inline">
                                <OSUButton ariaLabel="Cancel and go back to the Organize page" onClick={() => history.push("/organize")} className="mr-1">Cancel</OSUButton>
                                <OSUButton ariaLabel="Save and create section" disabled={(!title || titleInvalid)} onClick={() => createChannelSection(channel, title)}>Save</OSUButton>
                            </div>
                        }
                        {!isNewSection && 
                            <div className="d-inline">
                                <OSUButton ariaLabel="Update the section name" disabled={title === channelSection.title || titleInvalid} className="mr-1"
                                    onClick={() => updateChannelSection(channel, sectionId, channelSection.title, title)}>Update</OSUButton>
                                <OSUButton ariaLabel="Delete the section" onClick={() => setIsDeleteModalOpen(true)}>Delete</OSUButton>
                                <Modal data-testid="delete-modal" isOpen={isDeleteModalOpen}>
                                    <ModalHeader>Delete Section Confirmation</ModalHeader>
                                    <ModalBody>
                                        <p>Are you sure you want to delete this section?</p>
                                    </ModalBody>
                                    <ModalFooter>
                                        <OSUButton ariaLabel="Cancel, do not delete the section" color="gray" 
                                            onClick={() => setIsDeleteModalOpen(false)}>Cancel</OSUButton>
                                        <OSUButton ariaLabel="Yes, delete the section" color="blue" 
                                            onClick={() => {
                                                deleteChannelSection(channel, sectionId, channelSection.title)
                                                setIsDeleteModalOpen(false);
                                            }}>Yes - Delete</OSUButton>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        }
                        {(!isNewSection && channelSection.articles) && 
                            <Fragment>
                                <hr className="my-4" />
                                <div data-testid="section-content">
                                    <h2 data-testid="section-content-header">{channel} Content</h2>
                                    <div className="my-3">
                                        {channelSection.articles.length > 0 &&
                                            <div className="d-inline">
                                                <OSUButton ariaLabel={`Cancel changes to ${channelSection.title} content priority`} color="gray" className="mr-1"
                                                    disabled={!hasArticleUpdates} onClick={() => cancelArticleChanges()}>Cancel</OSUButton>
                                                <OSUButton ariaLabel={`Save changes to ${channelSection.title} content priority`} color="blue"
                                                    disabled={!hasArticleUpdates} onClick={() => saveArticleChanges()}>Save</OSUButton>
                                            </div>
                                        }
                                        <div className="d-inline">
                                            <OSUButton ariaLabel="Add content to section" color="blue" className="float-right"
                                                onClick={() => history.push(`/organize/channel/${channel}/section/${sectionId}/add-content`)}>Add Content</OSUButton>
                                        </div>
                                    </div>
                                    <ArticleSections articles={articles} onDrag={onArticleDrag} onMoveToTop={onArticleMoveToTop} onRemove={onArticleRemove} />
                                </div>
                            </Fragment>
                        }
                        <NavigationPrompt dataTestId="navigation-prompt" prompt={hasUnsavedChanges} header="Unsaved Changes"
                            message="The page has unsaved changes which will be lost if not applied.  Please confirm that you still want to leave the page." />
                    </div>
                </div>
            }
        </div>
    );
}