import { combineReducers } from "redux";
import * as AnnouncementsReducers from "./Announcements/reducers";
import * as AuthenticationReducers from "./Authentication/reducers";
import * as ContentReducers from "./Content/reducers";
import * as NotificationReducers from "./Notification/reducers";
import * as OrganizeReducers from "./Organize/reducers";
import * as ProviderReducers from "./Provider/reducers";
import * as ReviewReducers from "./Review/reducers";

export default combineReducers({
    ...AnnouncementsReducers,
    ...AuthenticationReducers,
    ...ContentReducers,
    ...NotificationReducers,
    ...OrganizeReducers,
    ...ProviderReducers,
    ...ReviewReducers
});