import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Mui from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DateUtils from "@date-io/moment";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { every, find, pick, values } from "lodash";
import { Caption } from "osu-react-components";
import * as AUDIENCE from "../audience";
import useStyles from "../styles/Announcement";
import { buildCollegeLabel } from "../util";
import { ANNOUNCEMENT_AFFILIATION, ANNOUNCEMENT_DATE_FIELD_FORMAT, ANNOUNCEMENT_FIELD_LABELS, EVENT_ANNOUNCEMENT_RESET_STATE, NO, SCREEN_OPTIONS, YES } from "../../util/constants";
import { getFileRowCount } from "../../util/util";

const USERS_FILE_EXTENSION = ".csv";
const USERS_FILE_NAME_REGEX = new RegExp(`^[-_a-zA-Z0-9]+${USERS_FILE_EXTENSION}`);
const MAX_LENGTH_TITLE = "40";
const MAX_LENGTH_MESSAGE = "240";
const VALIDATION_MESSAGE = {
    FILE_NAME: "The file name can include only alphanumeric characters, spaces, dashes, and underscores.",
    INVALID_DATE: "Invalid Date",
    INVALID_FILE: "Invalid File",
    INVALID_URL: "Invalid URL",
    REQUIRED_FIELD: "Required Field"
};

function Announcement(props) {
    const { bulk, canDisable, id, requireAudience, state = {}, setState, setUsersFileCount } = props;
    const { academicCareer = [], academicProgram = [], affiliation = [], application = [], authenticated, college = [], campus = [], disabled = false, endDate = null,
        message = "", priority = 0, screen = "", startDate = null, title = "", url = "" } = state;
    const [endDateHelperText, setEndDateHelperText] = useState(null);
    const [screenFormat, setScreenFormat] = useState(null);
    const [usersCount, setUsersCount] = useState(null);
    const [userFileHelperText, setUsersFileHelperText] = useState(null);
    const [validation, setValidation] = useState({});
    const classes = useStyles();

    const requiredNameFields = ["title", "message", "url", "startDate", "endDate"];
    if(bulk === true) requiredNameFields.push("usersFile");
    if(requireAudience === true) requiredNameFields.push("authenticated");
    
    const isRequired = (fieldName) => (requiredNameFields.includes(fieldName))
    const isValid = useCallback((fieldName) => (validation[fieldName] === true), [validation]);
    const isValidDate = (date) => {
        if(date) {
            if(typeof date === "string") {
                const parsedDate = new DateUtils().parse(date, ANNOUNCEMENT_DATE_FIELD_FORMAT);
                return (parsedDate.isValid && parsedDate.isValid());
            } else {
                return (date.isValid && date.isValid());
            }
        } else {
            return false;
        }
    };
    const hasError = (fieldName) => (typeof validation?.[fieldName] === "undefined" ? false : !isValid(fieldName));

    const resetUsersFile = () => {
        document.getElementById("usersFile").value = null; // need to manually clear the input
        setUsersCount(null);
        setUsersFileHelperText(null);
    }

    const resetState = useCallback(() => {
        if(bulk === true) resetUsersFile();
        setEndDateHelperText(null);
        setScreenFormat(null);
        setValidation({});
    }, [bulk]);

    const trimValueToMaxLength = (value, maxLength) => {
        let trimmedValue = value;
        if(value && maxLength && value.length > maxLength) {
          trimmedValue = value.substring(0, maxLength);
        }
        return trimmedValue;
    };
    
    const validateText = (text) => (!!text);
    const validateURL = (url) => {
        try {
            new URL(url);
            return true;
        } catch(error) {
            return false
        }
    };
    
    const getDateValue = (date) => {
        return (date ? (date.isValid() ? date.toISOString() : date.creationData().input) : "");
    };

    const updateValidation = (fieldName, isFieldValid) => {
        const updatedValidation = { ...validation, [fieldName]: isFieldValid };
        setValidation(updatedValidation);
    };

    const updateValidationAndState = useCallback((fieldName, fieldValue, isFieldValid = true) => {
        const updatedValidation = { ...validation, [fieldName]: isFieldValid };
        setValidation(updatedValidation);
        const validationFlags = values(updatedValidation);
        const updatedState = { ...state, [fieldName]: fieldValue };
        const requiredFieldValues = values(pick(updatedState, requiredNameFields));
        const filteredRequiredFieldValues = requiredFieldValues.filter((value) => (typeof value !== "undefined" && value !== null && value !== ""));
        const isStateValid = (
            every(validationFlags, (validationFlag) => (validationFlag === true)) && 
            requiredFieldValues.length === requiredNameFields.length &&
            filteredRequiredFieldValues.length === requiredFieldValues.length
        );
        setState(updatedState, isStateValid);
    }, [requiredNameFields, setState, state, validation]);

    const updateCheckboxState = ((fieldName, fieldValue, checked) => {
        const fieldValues = (state?.[fieldName] ?? []);
        const fieldValueIndex = fieldValues.indexOf(fieldValue);
        if(checked === true) {
            if(fieldValueIndex === -1) {
                const updatedFieldValues = [...fieldValues, fieldValue];
                updateValidationAndState(fieldName, updatedFieldValues);
            }
        } else {
            if(fieldValueIndex !== -1) {
                const updatedFieldValues = [...fieldValues];
                updatedFieldValues.splice(fieldValueIndex, 1);
                updateValidationAndState(fieldName, updatedFieldValues);
            }
        }
    });

    const onUsersFileBlur = (event) => {
        const input = event.target;
        const file = (input.files.length > 0 ? input.files[0] : null);
        const isFileValid = (file ? USERS_FILE_NAME_REGEX.test(file.name) : false);
        updateValidation("usersFile", isFileValid);
        if(!isFileValid) setUsersFileHelperText((file ? VALIDATION_MESSAGE.FILE_NAME : VALIDATION_MESSAGE.REQUIRED_FIELD));
    };

    const onUsersFileChange = (event) => {
        const input = event.target;
        const file = (input.files.length > 0 ? input.files[0] : null);
        if(file) {
            let isFileValid = USERS_FILE_NAME_REGEX.test(file.name);
            let helperText = (isFileValid ? null : VALIDATION_MESSAGE.FILE_NAME);
            try {
                getFileRowCount(file, setUsersCount, true);
            } catch(error) { // ignore file read failures
                console.error("Failed to read users from file", error);
                isFileValid = false;
                helperText = VALIDATION_MESSAGE.INVALID_FILE;
                setUsersCount(null);
            }
            updateValidationAndState("usersFile", file, isFileValid);
            setUsersFileHelperText(helperText);
        } else {
            updateValidationAndState("usersFile", null, false);
            setUsersFileHelperText(VALIDATION_MESSAGE.REQUIRED_FIELD);
            setUsersCount(null);
        }
    };

    // add event listener to handle reset state
    useEffect(() => {
        document.addEventListener(EVENT_ANNOUNCEMENT_RESET_STATE, resetState);
        return () => {
            document.removeEventListener(EVENT_ANNOUNCEMENT_RESET_STATE, resetState);
        };
    }, [resetState]);

    // when showing disabled, default to true
    // trigger the click event to update state
    useEffect(() => {
        if(canDisable === true) document.getElementById("disabled").click();
    }, [canDisable]);

    // when the users count changes, call set the users file count
    useEffect(() => {
        if(bulk === true) setUsersFileCount(usersCount);
    }, [bulk, setUsersFileCount, usersCount]);

    // when start or end date changes, check endDate validatity and set endDate helper text (if necessary)
    useEffect(() => {
        let valid = true;
        let helperText = null;
        
        const isStartDateValid = (!!startDate && (isValid("startDate") || isValidDate(new DateUtils().parse(startDate))));
        const hasEndDate = (!!endDate);
        const isEndDateValid = (hasEndDate && ((isValid("endDate") || isValidDate(new DateUtils().parse(endDate))) || new Date(endDate).toString() !== VALIDATION_MESSAGE.INVALID_DATE)); // endDate could be a valid date, but invalid due to startDate
        const hasEndDateBeenValidated = (typeof validation?.endDate !== "undefined");
        
        if(isStartDateValid && isEndDateValid && !(new Date(endDate).getTime() > new Date(startDate).getTime())) {
            valid = false;
            helperText = "Must be later than Start";
        } else if(hasEndDateBeenValidated && isEndDateValid && !(new Date(endDate).getTime() > new Date().getTime())) {
            valid = false;
            helperText = "Must be in the future";
        } else if(!hasEndDate && hasEndDateBeenValidated) { // only show this error once the the user has interacted ith the field
            valid = false;
            helperText = VALIDATION_MESSAGE.REQUIRED_FIELD;
        } else if(hasEndDate && hasEndDateBeenValidated && !isValid("endDate")) {
            valid = false;
            helperText = VALIDATION_MESSAGE.INVALID_DATE;
        }

        if(hasEndDateBeenValidated && validation.endDate !== valid) updateValidationAndState("endDate", endDate, valid);
        if(endDateHelperText !== helperText) setEndDateHelperText(helperText);
    }, [endDate, endDateHelperText, isValid, startDate, updateValidationAndState, validation]);

    // when authenticated is set to false, reset other audience fields
    useEffect(() => {
        if(authenticated === false) {
            const fieldsToReset = [];
            if(academicCareer.length > 0) fieldsToReset.push("academicCareer");
            if(academicProgram.length > 0) fieldsToReset.push("academicProgram");
            if(affiliation.length > 0) fieldsToReset.push("affiliation");
            if(campus.length > 0) fieldsToReset.push("campus");
            if(college.length > 0) fieldsToReset.push("college");
            if(fieldsToReset.length > 0) {
                const updatedState = { ...state };
                const updatedValidation = { ...validation };
                for(const fieldName of fieldsToReset) {
                    updatedState[fieldName] = [];
                    delete updatedValidation[fieldName];
                }
                setState(updatedState);
                setValidation(updatedValidation);
            }
        }
    }, [academicCareer, academicProgram, affiliation, authenticated, campus, college, setState, state, validation]);

    return (
        <div id={id}>
            {bulk === true &&
                <Mui.Box marginBottom="1rem">
                    <Mui.TextField label={ANNOUNCEMENT_FIELD_LABELS["usersFile"]} variant="outlined" required={isRequired("usersFile")} className={`${classes.textField} ${classes.usersFile}`} 
                        FormHelperTextProps={{ id: "userFileHelperText", className: classes.userFileHelperText }} InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: USERS_FILE_EXTENSION, "aria-describedby": "userFileHelperText userFileDescription usersCount",
                            "aria-invalid": hasError("usersFile"), "aria-required": isRequired("usersFile"), id: "usersFile", type: "file"
                        }}
                        error={hasError("usersFile")} helperText={userFileHelperText} onBlur={onUsersFileBlur} onChange={onUsersFileChange} />
                    <Mui.FormHelperText id="userFileDescription" className={classes.userFileDescription}>A comma-delimited file (.csv) that includes a header and emplids (without quotes) of users to associate with the announcement.</Mui.FormHelperText>
                    {usersCount && <Caption id="usersCount" className={classes.usersCount}>File contains {usersCount} user(s).</Caption>}
                </Mui.Box>
            }
            <Mui.TextField label={ANNOUNCEMENT_FIELD_LABELS["title"]} variant="outlined" required={isRequired("title")} value={title}
                className={classes.textField} FormHelperTextProps={{ id: "titleHelperText" }}
                inputProps={{ "aria-describedby": "titleHelperText", "aria-invalid": hasError("title"), "aria-required": isRequired("title"), id: "title", maxLength: MAX_LENGTH_TITLE }}
                error={hasError("title")} helperText={hasError("title") && VALIDATION_MESSAGE.REQUIRED_FIELD}
                onBlur={(e) => updateValidation("title", validateText(e.target.value))}
                onChange={(e) => updateValidationAndState("title", trimValueToMaxLength(e.target.value, MAX_LENGTH_TITLE), validateText(e.target.value))} />
            <Mui.TextField label={ANNOUNCEMENT_FIELD_LABELS["message"]} variant="outlined" required={isRequired("message")} value={message}
                className={classes.textField} FormHelperTextProps={{ id: "messageHelperText" }}
                inputProps={{ "aria-describedby": "messageHelperText", "aria-invalid": hasError("message"), "aria-required": isRequired("message"), id: "message", maxLength: MAX_LENGTH_MESSAGE }}
                error={hasError("message")} helperText={hasError("message") && VALIDATION_MESSAGE.REQUIRED_FIELD}
                onBlur={(e) => updateValidation("message", validateText(e.target.value))}
                onChange={(e) => updateValidationAndState("message", trimValueToMaxLength(e.target.value, MAX_LENGTH_MESSAGE), validateText(e.target.value))} />
            <Mui.TextField label={ANNOUNCEMENT_FIELD_LABELS["url"]} variant="outlined" required={isRequired("url")} value={url}
                className={classes.textField} FormHelperTextProps={{ id: "urlHelperText" }}
                inputProps={{ "aria-describedby": "urlHelperText", "aria-invalid": hasError("url"), "aria-required": isRequired("url"), id: "url" }}
                error={hasError("url")} helperText={hasError("url") && (!url ? VALIDATION_MESSAGE.REQUIRED_FIELD : VALIDATION_MESSAGE.INVALID_URL)}
                onBlur={(e) => updateValidation("url", validateURL(e.target.value))}
                onChange={(e) => updateValidationAndState("url", e.target.value, validateURL(e.target.value))} />
            <MuiPickersUtilsProvider utils={DateUtils}>
                <Mui.Grid container spacing={1} className={classes.dates}>
                    <Mui.Grid item>
                        <KeyboardDateTimePicker label={ANNOUNCEMENT_FIELD_LABELS["startDate"]} inputVariant="outlined" format={ANNOUNCEMENT_DATE_FIELD_FORMAT} value={startDate}
                            required={isRequired("startDate")} className={classes.startDate}
                            inputProps={{ "aria-label": "Start Date", "aria-required": isRequired("startDate"), id: "startDate" }}
                            KeyboardButtonProps={{ "aria-label": "Select Start Date from calendar component" }}
                            error={hasError("startDate")} helperText={hasError("startDate") && (!startDate ? VALIDATION_MESSAGE.REQUIRED_FIELD : VALIDATION_MESSAGE.INVALID_DATE)}
                            onAccept={() => { if(validation?.startDate !== true) updateValidation("startDate", true); }}
                            onBlur={(e) => updateValidation("startDate", isValidDate(e.target.value))}
                            onChange={(date) => updateValidationAndState("startDate", getDateValue(date), isValidDate(date))} />
                    </Mui.Grid>
                    <Mui.Grid item>
                        <KeyboardDateTimePicker label={ANNOUNCEMENT_FIELD_LABELS["endDate"]} inputVariant="outlined" format={ANNOUNCEMENT_DATE_FIELD_FORMAT} value={endDate}
                            required={isRequired("endDate")} className={classes.endDate} minDate={new Date().toISOString()}
                            inputProps={{ "aria-label": "End Date", "aria-required": isRequired("endDate"), id: "endDate" }}
                            KeyboardButtonProps={{ "aria-label": "Select End Date from calendar component" }}
                            error={hasError("endDate")} helperText={endDateHelperText}
                            onAccept={() => { if(validation?.endDate !== true) updateValidation("endDate", true); }}
                            onBlur={(e) => updateValidation("endDate", isValidDate(e.target.value))}
                            onChange={(date) => updateValidationAndState("endDate", getDateValue(date), isValidDate(date))} />
                    </Mui.Grid>
                </Mui.Grid>
            </MuiPickersUtilsProvider>
            <Autocomplete id="screen" freeSolo options={SCREEN_OPTIONS} getOptionLabel={option => option.label} inputValue={screen} classes={{ root: classes.screen }}
                renderInput={(params) => (
                    <Mui.TextField {...params} label={ANNOUNCEMENT_FIELD_LABELS["screen"]} variant="outlined" required={isRequired("screen")}
                        onChange={(e) => { // screen provided by text entry
                            const value = e.target.value;
                            const screenLabel = value.split(":")[0]; // remove format
                            const screenOption = find(SCREEN_OPTIONS, [ "label", screenLabel ]);
                            const screenFormat = ((screenOption && screenOption.format) ? screenOption.format : null);
                            updateValidationAndState("screen", value);
                            setScreenFormat(screenFormat);
                        }}
                        FormHelperTextProps={{ id: "screenHelperText" }}
                        inputProps={{ ...params.inputProps, "aria-describedby": "screenHelperText screenFormat", "aria-invalid": hasError("screen"), "aria-required": isRequired("screen") }} />
                )}
                onChange={(e) => { // screen provided by dropdown selection
                    const screenLabel = e.target.textContent;
                    const screenOption = find(SCREEN_OPTIONS, [ "label", screenLabel ]);
                    const screenFormat = ((screenOption && screenOption.format) ? screenOption.format : null);
                    updateValidationAndState("screen", screenLabel);
                    setScreenFormat(screenFormat);
                }}
            />
            {screenFormat && <Mui.FormHelperText id="screenFormat" className={classes.screenFormat}>Format: <span dangerouslySetInnerHTML={{ __html: screenFormat }} /></Mui.FormHelperText>}
            <Mui.TextField label={ANNOUNCEMENT_FIELD_LABELS["priority"]} variant="outlined" type="number" required={isRequired("priority")}
                value={priority} className={classes.priority} FormHelperTextProps={{ id: "priorityHelperText" }}
                inputProps={{ "aria-describedby": "priorityHelperText", "aria-invalid": hasError("priority"), "aria-required": isRequired("priority"), id: "priority", min: 0, pattern: "[0-9]*" }}
                error={hasError("priority")} helperText={hasError("priority") && "Priority must be a positive number"}
                onKeyPress={(e) => { if(["e", "E", "-"].includes(e.key)) e.preventDefault(); }}
                onChange={(e) => updateValidationAndState("priority", Number(e.target.value))} />
            {canDisable &&
                <Mui.FormControlLabel label="Disabled" checked={(disabled === true)}
                    control={
                        <Mui.Checkbox id="disabled" name="disabled" inputProps={{ "aria-checked": (disabled === true), "aria-label": "Disable Announcement", "aria-required": isRequired("disabled") }}
                            onChange={(e) => updateValidationAndState("disabled", e.target.checked)} />
                    } />
            }
            {requireAudience === true &&
                <Mui.Box marginBottom="1rem">
                    <Mui.Typography component="h2" variant="h6">Select who will see this announcement</Mui.Typography>
                    <Mui.Box marginLeft="1rem" marginTop="1rem">
                        <Mui.FormControl component="fieldset" className={classes.formControl} aria-labelledby="applicationLabel applicationHelperText">
                            <Mui.FormLabel id="applicationLabel" component="legend" className={classes.legend}>Application</Mui.FormLabel>
                            <Mui.FormHelperText id="applicationHelperText" className={classes.helperText}>(All by default)</Mui.FormHelperText>
                            {Object.keys(AUDIENCE.APPLICATION).map(value => {
                                const label = AUDIENCE.APPLICATION[value];
                                return (
                                    <Mui.FormControlLabel key={value} label={label} checked={application.includes(value)} className={classes.checkbox}
                                        control={
                                            <Mui.Checkbox id={value} name={value} 
                                                inputProps={{ "aria-checked": application.includes(value), "aria-label": label, "aria-required": isRequired("application") }}
                                                onChange={(e) => updateCheckboxState("application", value, e.target.checked)} />
                                        } />
                                );
                            })}
                        </Mui.FormControl>
                        <Mui.FormControl className={classes.formControl} aria-labelledby="authenticationLabel">
                            <Mui.FormLabel id="authenticationLabel" required={true} component="legend" className={classes.legend}>Authentication</Mui.FormLabel>
                            <Mui.RadioGroup id="authenticationRadioGroup" aria-required={isRequired("authenticated")} name="authenticated"
                                value={(typeof authenticated === "undefined" ? null : (authenticated === true ? YES : NO))}
                                onChange={(e) => updateValidationAndState("authenticated", (e.currentTarget.value === YES))}>
                                {Object.keys(AUDIENCE.AUTHENTICATED).map(value => {
                                    const label = AUDIENCE.AUTHENTICATED[value];
                                    return (
                                        <Mui.FormControlLabel key={value} label={label} value={value} className={classes.radio} control={
                                            <Mui.Radio inputProps={{ "aria-checked": (authenticated === (value === YES)), "aria-required": isRequired("authenticated") }} />
                                        } />
                                    );
                                })}
                            </Mui.RadioGroup>
                        </Mui.FormControl>
                        {authenticated === true &&
                            <Mui.Box marginLeft="2rem">
                                <Mui.FormControl className={classes.formControl} aria-labelledby="audienceLabel">
                                    <Mui.FormLabel id="audienceLabel" required={true} component="legend" className={classes.legend}>Audience</Mui.FormLabel>
                                    {Object.keys(AUDIENCE.AFFILIATION).map(value => {
                                        const label = AUDIENCE.AFFILIATION[value];
                                        return (
                                            <Mui.FormControlLabel key={value} label={label} checked={affiliation.includes(value)} className={classes.checkbox}
                                                control={
                                                    <Mui.Checkbox id={value} name={value}
                                                        inputProps={{ "aria-checked": affiliation.includes(value), "aria-label": label, "aria-required": isRequired("affiliation") }}
                                                        onChange={(e) => updateCheckboxState("affiliation", value, e.target.checked)} />
                                                } />
                                        );
                                    })}
                                </Mui.FormControl>
                            </Mui.Box>
                        }
                    </Mui.Box>                   
                    {authenticated === true &&
                        <Mui.Box marginLeft="3rem">
                            {affiliation.includes(ANNOUNCEMENT_AFFILIATION.FACULTY_AND_STAFF) &&
                                <Mui.Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Mui.FormControl component="fieldset" className={classes.formControl} aria-labelledby="collegeLabel collegeHelperText">
                                        <Mui.FormLabel id="collegeLabel" component="legend" className={classes.legend}>Faculty/Staff College</Mui.FormLabel>
                                        <Mui.FormHelperText id="collegeHelperText" className={classes.helperText}>(All by default)</Mui.FormHelperText>
                                        {AUDIENCE.COLLEGE.map(value => {
                                            const label = buildCollegeLabel(value);
                                            return (
                                                <Mui.FormControlLabel key={value} label={label} checked={college.includes(value)} className={classes.checkbox}
                                                    control={
                                                        <Mui.Checkbox id={value} name={value} 
                                                            inputProps={{ "aria-checked": college.includes(value), "aria-label": label, "aria-required": isRequired("college") }}
                                                            onChange={(e) => updateCheckboxState("college", value, e.target.checked)} />
                                                    } />
                                            );
                                        })}
                                    </Mui.FormControl>
                                </Mui.Grid>
                            }
                            {affiliation.includes(ANNOUNCEMENT_AFFILIATION.STUDENTS) &&
                                <Mui.Grid container className={classes.grid}>
                                    <Mui.Grid item xs={12} sm={6} md={6} lg={4}>
                                        <Mui.FormControl component="fieldset" className={classes.formControl} aria-labelledby="campusLabel campusHelperText">
                                            <Mui.FormLabel id="campusLabel" component="legend" className={classes.legend}>Student Campus</Mui.FormLabel>
                                            <Mui.FormHelperText id="campusHelperText" className={classes.helperText}>(All by default)</Mui.FormHelperText>
                                            {AUDIENCE.CAMPUS.map(label => (
                                                <Mui.FormControlLabel key={label} label={label} checked={campus.includes(label)} className={classes.checkbox}
                                                    control={
                                                        <Mui.Checkbox id={label} name={label} 
                                                            inputProps={{ "aria-checked": campus.includes(label), "aria-label": label, "aria-required": isRequired("campus") }}
                                                            onChange={(e) => updateCheckboxState("campus", label, e.target.checked)} />
                                                    } />
                                            ))}
                                        </Mui.FormControl>
                                    </Mui.Grid>
                                    <Mui.Grid item xs={12} sm={6} md={6} lg={4}>
                                        <Mui.FormControl component="fieldset" className={classes.formControl} aria-labelledby="academicCareerLabel academicCareerHelperText">
                                            <Mui.FormLabel id="academicCareerLabel" component="legend" className={classes.legend}>Student Academic Career</Mui.FormLabel>
                                            <Mui.FormHelperText id="academicCareerHelperText" className={classes.helperText}>(All by default)</Mui.FormHelperText>
                                            {Object.keys(AUDIENCE.ACADEMIC_CAREER).map(value => {
                                                const label = AUDIENCE.ACADEMIC_CAREER[value];
                                                return (
                                                    <Mui.FormControlLabel key={value} label={label} checked={academicCareer.includes(value)} className={classes.checkbox}
                                                        control={
                                                            <Mui.Checkbox id={value} name={value} 
                                                                inputProps={{ "aria-checked": academicCareer.includes(value), "aria-label": label, "aria-required": isRequired("academicCareer") }}
                                                                onChange={(e) => updateCheckboxState("academicCareer", value, e.target.checked)} />
                                                        } />
                                                );
                                            })}
                                        </Mui.FormControl>
                                    </Mui.Grid>
                                    <Mui.Grid item xs={12} sm={6} md={6} lg={4}>
                                        <Mui.FormControl component="fieldset" className={classes.formControl} aria-labelledby="academicProgramLabel academicProgramHelperText">
                                            <Mui.FormLabel id="academicProgramLabel" component="legend" className={classes.legend}>Student Academic Program</Mui.FormLabel>
                                            <Mui.FormHelperText id="academicProgramHelperText" className={classes.helperText}>(All by default)</Mui.FormHelperText>
                                            {Object.keys(AUDIENCE.ACADEMIC_PROGRAM).map(value => {
                                                const label = AUDIENCE.ACADEMIC_PROGRAM[value];
                                                return (
                                                    <Mui.FormControlLabel key={value} label={label} checked={academicProgram.includes(value)} className={classes.checkbox}
                                                        control={
                                                            <Mui.Checkbox name={value} 
                                                                inputProps={{ "aria-checked": academicProgram.includes(value), "aria-label": label, "aria-required": isRequired("academicProgram") }}
                                                                onChange={(e) => updateCheckboxState("academicProgram", value, e.target.checked)} />
                                                        } />
                                                );
                                            })}
                                        </Mui.FormControl>
                                    </Mui.Grid>
                                </Mui.Grid>
                            }
                        </Mui.Box>
                    }
                </Mui.Box>
            }
        </div>
    );
};

Announcement.defaultProps = {
    bulk: false,
    canDisable: false,
    id: "announcement",
    requireAudience: false,
    setUsersFileCount: () => { console.log("Function 'setUsersFileCount' not implemented"); },
    state: {}
};

Announcement.propTypes = {
    bulk: PropTypes.bool,
    canDisable: PropTypes.bool, 
    id: PropTypes.string,
    requireAudience: PropTypes.bool,
    setState: PropTypes.func.isRequired,
    setUsersFileCount: PropTypes.func,
    state: PropTypes.object
}

export default Announcement;