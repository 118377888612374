import React from "react";
import { Image } from "react-bootstrap";
import { forEach, isEmpty } from "lodash";
import { Body1, Caption } from "osu-react-components";
import {
    ADD_CHANNEL_SECTION_ARTICLE_ID,
    ADD_CHANNEL_SECTION_ARTICLE_STATE,
    AVAILABLE_CONTENT, AVAILABLE_CONTENT_FILTER, AVAILABLE_CONTENT_STATE,
    CHANNEL_NOT_FOUND,
    CHANNELS, CHANNELS_STATE,
    CHANNEL_SECTION, CHANNEL_SECTION_STATE, CHANNEL_SECTION_TITLE,
    CHANNEL_SECTIONS, CHANNEL_SECTIONS_STATE,
    CREATE_CHANNEL_STATE,
    DELETE_CHANNEL_STATE,
    DELETE_CHANNEL_SECTION_STATE,
    REMOVE_CHANNEL_SECTION_ARTICLE_STATE,
    SELECTED_CHANNEL,
    UPDATE_ARTICLES_PRIORITY_STATE,
    UPDATE_CHANNEL_SECTION_STATE,
    UPDATE_CHANNEL_SECTIONS_PRIORITY_STATE
} from "../actions-index";
import { ACTION_STATE_SUCCESS, PROVIDER_MOBILE_APPS } from "../util/constants";
import { arrayToString, formatDateString } from "../util/util";

export function availableContent(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case AVAILABLE_CONTENT:
            return { 
                data: formatAvailableContent(payload),
                filter: {},
                state: !isEmpty(payload) ? ACTION_STATE_SUCCESS : state.state
            };
        case AVAILABLE_CONTENT_STATE:
            return { ...state, state: payload };
        case AVAILABLE_CONTENT_FILTER:
            const { field, value } = payload;
            const filter = {...state.filter};
            if(isEmpty(value)) {
                delete filter[field];
            } else {
                filter[field] = value;
            }
            return { ...state, filter };
        default:
            return state;
    }
}

function formatAvailableContent(content) {
    forEach(content, item => {
        item.channelDisplay = arrayToString(item.channel);
        item.image = (<Image src={item.thumbnailURL} alt="Content Image" fluid rounded />);
        item.publishStartDateDisplay = formatDateString(item.publishStartDate);
        item.publishEndDateDisplay = formatDateString(item.publishEndDate);
        if(item.provider === PROVIDER_MOBILE_APPS) {
            const platforms = (item?.platform?.length ? item.platform.join(", ") : "");
            const appBuild = (item.appBuildStart ? 
                (item.appBuildEnd ?
                    `${item.appBuildStart}-${item.appBuildEnd}`:
                    item.appBuildStart
                ) :
                ""
            );
            item.title = (
                <div>
                    <Body1 color="black">{item.title}</Body1>
                    <Caption>Platform(s): {platforms}</Caption>
                    <Caption>App Build: {appBuild}</Caption>
                </div>
            );
        }
    });
    return content;
}

export function addChannelSectionArticleId(state = null, action = {}) {
    return (action.type === ADD_CHANNEL_SECTION_ARTICLE_ID) ? action.payload : state;
}

export function addChannelSectionArticleState(state = "", action = {}) {
    return (action.type === ADD_CHANNEL_SECTION_ARTICLE_STATE) ? action.payload : state;
}

export function removeChannelSectionArticleState(state = "", action = {}) {
    return (action.type === REMOVE_CHANNEL_SECTION_ARTICLE_STATE) ? action.payload : state;
}

export function selectedChannel(state = null, action = {}) {
    return (action.type === SELECTED_CHANNEL) ? action.payload : state;
}

export function channels(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case CHANNELS:
            return { 
                data: payload,
                state: !isEmpty(payload) ? ACTION_STATE_SUCCESS : state.state
            };
        case CHANNELS_STATE:
            return { ...state, state: payload };
        default:
            return state;
    }
}

export function createChannelState(state = "", action = {}) {
    return (action.type === CREATE_CHANNEL_STATE) ? action.payload : state;
}

export function deleteChannelState(state = "", action = {}) {
    return (action.type === DELETE_CHANNEL_STATE) ? action.payload : state;
}

export function channelSections(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case CHANNEL_SECTIONS:
            return { 
                data: payload,
                state: !isEmpty(payload) ? ACTION_STATE_SUCCESS : state.state
            };
        case CHANNEL_SECTIONS_STATE:
            return { ...state, state: payload };
        default:
            return state;
    }
}

export function channelSection(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case CHANNEL_SECTION:
            return { 
                data: payload,
                state: !isEmpty(payload) ? ACTION_STATE_SUCCESS : state.state
            };
        case CHANNEL_SECTION_STATE:
            return { ...state, state: payload };
        case CHANNEL_SECTION_TITLE:
            const data = state.data || {};
            return { ...state, data: { ...data, title: payload } };
        default:
            return state;
    }
}

export function channelNotFound(state = false, action = {}) {
    return (action.type === CHANNEL_NOT_FOUND) ? action.payload : state;
}

export function deleteChannelSectionState(state = "", action = {}) {
    return (action.type === DELETE_CHANNEL_SECTION_STATE) ? action.payload : state;
}

export function updateChannelSectionState(state = "", action = {}) {
    return (action.type === UPDATE_CHANNEL_SECTION_STATE) ? action.payload : state;
}

export function updateChannelSectionsPriorityState(state = "", action = {}) {
    return (action.type === UPDATE_CHANNEL_SECTIONS_PRIORITY_STATE) ? action.payload : state;
}

export function updateArticlesPriorityState(state = "", action = {}) {
    return (action.type === UPDATE_ARTICLES_PRIORITY_STATE) ? action.payload : state;
}