import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BulkAnnouncements from "../components/BulkAnnouncements";
import { getBulkAnnouncements, resetBulkAnnouncements } from "../../actions-index";

const mapStateToProps = (state) => {
    const { data: bulkAnnouncements = [], state: bulkAnnouncementsState = "" } = state.bulkAnnouncements;
    return {
        bulkAnnouncements,
        bulkAnnouncementsState
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBulkAnnouncements: () => dispatch(getBulkAnnouncements()),
        resetBulkAnnouncements: () => dispatch(resetBulkAnnouncements())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkAnnouncements));