import React from "react";
import PropTypes from "prop-types";
import Heading from "./Heading";
import Photo from "./Photo";

function Gallery(props) {
    const { items, text } = props;
    return (
        <div>
            {text && <Heading>{text}</Heading>}
            {items.length > 0 && items.map((item, index) => {
                const { accessiblityCaption, caption, URL } = item;
                return ((URL && accessiblityCaption) ? (<Photo key={index} alt={accessiblityCaption} caption={caption} src={URL} />) : null);
            })}
        </div>
    );
}

Gallery.defaultProps = {
    items: [],
    text: null
}

Gallery.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        accessiblityCaption: PropTypes.string.isRequired,
        caption: PropTypes.string,
        URL: PropTypes.string.isRequired
    })),
    text: PropTypes.string
}

export default Gallery;