import React, { useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar as MuiAppBar, Toolbar, Typography, ButtonBase } from "@material-ui/core";
import useStyles from "../styles/theme";
import { APPLICATION_NAME } from "../../util/constants";

const brandIcon = require("../../Common/assets/block-osu.svg");

function AppBar(props) {
    const classes = useStyles();
    const { className, determineHeight, elevation } = props;
    const ref = useRef({ current: { clientHeight: null } });
    useEffect(() => { determineHeight(ref.current.clientHeight) }, [ref, determineHeight]);
    return (
        <MuiAppBar ref={ref} elevation={elevation} className={className} position="sticky" color="inherit">
            <Toolbar>
                <ButtonBase aria-label="Navigate to home" component={RouterLink} to="/">
                    <img alt="The Ohio State University Block O Brand" className={`${classes.icon} ${classes.brandIcon}`} src={brandIcon} />
                </ButtonBase>
                <Typography color="secondary" className={classes.title} variant="h6" noWrap>
                    {APPLICATION_NAME}
                </Typography>
                {props.children}
            </Toolbar>
        </MuiAppBar>
    );
}

AppBar.defaultProps = {
    className: '',
    determineHeight: (height) => ({ height }),
    elevation: 0
}
  
AppBar.propTypes = {
    className: PropTypes.string,
    determineHeight: PropTypes.func.isRequired,
    elevation: PropTypes.number.isRequired
}

export default AppBar;