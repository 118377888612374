import React from "react";
import GoogleMap from "google-map-react";
import PropTypes from "prop-types";
import { Icon } from "osu-react-components";
import { Col, Row } from "reactstrap";
import "../styles/map.css";

function Map(props) {
    if(props.locations.length === 0) return null;
    return (
        <Row className="d-flex justify-content-center mt-2">
            <Col sm="12" md="10" lg="8" xl="6" className="map-container">
                <GoogleMap bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY , libraries: ["places", "geometry"] }} 
                    defaultCenter={props.defaultCenter} defaultZoom={props.defaultZoom} onClick={props.onMapClicked}
                    onGoogleApiLoaded={({ map, maps }) => props.onMapLoaded(map, maps)} yesIWantToUseGoogleMapApiInternals>
                        {props.locations.map((location, index) => (
                            <MapIcon key={index} lat={location.lat} lng={location.lng} />
                        ))}
                </GoogleMap>
            </Col>
        </Row>
    );
}

Map.defaultProps = {
    defaultCenter: {
        lat: 40.001773,
        lng: -83.019771
    },
    defaultZoom: 15,
    locations: [],
    onMapClicked: ({ x, y, lat, lng, event }) => console.log(x, y, lat, lng, event),
    onMapLoaded: (map, maps) => console.log("Map", map, "Maps", maps)
}

Map.propTypes = {
    defaultCenter: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
    }),
    defaultZoom: PropTypes.number,
    locations: PropTypes.arrayOf(
        PropTypes.shape({
            accessibilityCaption: PropTypes.string,
            caption: PropTypes.string.isRequired,
            lat: PropTypes.number.isRequired,
            lng: PropTypes.number.isRequired
        })
    ),
    onMapClicked: PropTypes.func,
    onMapLoaded: PropTypes.func
}

export default Map;

function MapIcon(props) {
    return (
        <div data-testid="map-icon" data-lat={props.lat} data-lng={props.lng} className="map-icon">
            <Icon type="map-pin" size="lg" />
        </div>
    );
}

MapIcon.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
}