import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline, Container } from "@material-ui/core";
import { responsiveFontSizes } from "@material-ui/core/styles";
import { SessionTimer } from "osu-react-components";
import { isEmpty, toInteger } from "lodash";
import aws_exports from "../../aws-exports";
import apiConfig from "../../api-config";
import CustomAuthStorage from "../../util/CustomAuthStorage";
import NavBar from "../../Navbar/components";
import Routes from "./Routes";
import theme from "../styles/theme";
import "../styles/index.css";

Auth.configure({ storage: new CustomAuthStorage(apiConfig.customAuthStorage) });
Amplify.configure({ ...aws_exports, ...apiConfig });

const timeoutInMinutes = !isEmpty(process.env.REACT_APP_SESSION_TIMEOUT_IN_MINUTES) ? toInteger(process.env.REACT_APP_SESSION_TIMEOUT_IN_MINUTES) : 60;

export const AppContext = React.createContext();

export default function App(props) {
  const { getUser, signIn, signOut, user } = props;
  const [navHeight, setNavHeight] = useState();
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const history = useHistory();
  const { pathname } = useLocation();
  const contextProviderValue = {
    elevation: { standard: 1, high: 2, navigation: 4 },
    navHeight,
    setNavHeight: (height) => !!height && height !== navHeight && setNavHeight(height),
    screenSize,
    getUser,
    signIn,
    signOut,
    user
  };

  if(isEmpty(user.state) && !["/signin", "/signout"].includes(pathname)) signIn();

  useEffect(() => {
    window.addEventListener("resize", () => setScreenSize(window.innerWidth));
  }, []); // on mount

  const containerEl = useRef(null);
  useEffect(() => {
    containerEl.current.focus();
  }, [pathname]);

  const sessionSignOut = () => {
    signOut();
    if(pathname !== "/signout") history.push("/signout");
  }

  return (
    <AppContext.Provider value={contextProviderValue}>
      <ThemeProvider theme={responsiveFontSizes(theme(false, contextProviderValue.elevation))}>
        <SessionTimer timeoutInMinutes={timeoutInMinutes} signOut={sessionSignOut} enabled={!isEmpty(user.state)}>
          <div className="App">
            <CssBaseline />
            <a id="skipToMain" href="#main" className="sr-only sr-only-focusable">Skip to main content</a>
            <NavBar className="mb-2" />
            <Container id="main" component="main" className="container" ref={containerEl} tabIndex="-1">
              <div className="pb-5">
                <Routes pathname={pathname} />
              </div>
            </Container>
          </div>
        </SessionTimer>
      </ThemeProvider>
    </AppContext.Provider>
  );
}