import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ContentItem from "../components/ContentItem";
import { getContentItem, resetUpdateContentItemStatusState, updateContentItemStatus } from "../../actions-index";

const mapStateToProps = (state) => {
    const { data = null, state: contentItemState = "" } = state.contentItem;
    return {
        data,
        state: contentItemState,
        updateContentItemStatusState: state.updateContentItemStatusState
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getContentItem: (itemId) => dispatch(getContentItem(itemId)),
        resetUpdateContentItemStatusState: () => dispatch(resetUpdateContentItemStatusState()),
        updateContentItemStatus: (item, status) => dispatch(updateContentItemStatus(item, status))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentItem));