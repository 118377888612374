import { API } from "aws-amplify";
import { GET_PROVIDER_CONTENT_STATE, PROVIDERS, PROVIDERS_STATE } from "../actions-index";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS } from "../util/constants";
import { buildAction } from "../util/util";
import { handleUnauthenticated } from "../Authentication/util";

export function getProviders() {
    return dispatch => {
        dispatch(buildAction(PROVIDERS_STATE, ACTION_STATE_LOADING));
        API.get(process.env.REACT_APP_API_NAME, "/providers")
        .then(response => {
            const providers = (response.data ? response.data : {}); // map
            dispatch(buildAction(PROVIDERS, providers));
            dispatch(buildAction(PROVIDERS_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Get Providers Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(PROVIDERS_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function getProviderContent(provider) {
    return dispatch => {
        dispatch(buildAction(GET_PROVIDER_CONTENT_STATE, ACTION_STATE_LOADING));
        API.post(process.env.REACT_APP_API_NAME, `/content/provider/${provider}`)
        .then(() => {
            dispatch(buildAction(GET_PROVIDER_CONTENT_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Get Provider Content Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(GET_PROVIDER_CONTENT_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}