import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Review from "../components";
import { filterReviewContent, getReviewContent } from "../../actions-index";
import _ from "lodash";

const applyFilter = (data, filter) => {
    if(data.length > 0 && !_.isEmpty(filter)) {
        return _.filter(data, item => {
            let matches = true;
            for(const key in filter) {
                const value = filter[key];
                const field = item[key];
                if((Array.isArray(field) && field.includes(value)) || 
                    (key === "title" && field.toLowerCase().includes(value.toLowerCase())) || // search query (partial text search)
                    (typeof field === "string" && field === value)) {
                    continue;
                } else {
                    matches = false;
                }
            }
            return matches;
        });
    } else {
        return data;
    }
};

const buildFilterOptions = (data) => {
    const filterOptions = { providers: [], statuses: [], channels: [] };
    if(data.length > 0) {
        const providers = new Set(), statuses = new Set(), channels = new Set();
        _.forEach(data, item => {
            providers.add(item.provider);
            statuses.add(item.status);
            _.forEach(item.channel, channel => {
                channels.add(channel);
            });
        });
        function buildOptions(values) {
            if(values.length > 0) {
                values.sort((a, b) => {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                });
                return values.map(value => {
                    return { label: value, value };
                });
            } else {
                return [];
            }
        }
        filterOptions.providers = buildOptions(_.compact(Array.from(providers)));
        filterOptions.statuses = buildOptions(_.compact(Array.from(statuses)));
        filterOptions.channels = buildOptions(_.compact(Array.from(channels)));
    }
    return filterOptions
};

const mapStateToProps = (state) => {
    const { data = [], filter = {}, state: contentState = "" } = state.reviewContent;
    let filteredData = applyFilter(data, filter);
    const filterOptions = buildFilterOptions(filteredData);
    return {
        data: filteredData,
        filter,
        filterOptions,
        state: contentState
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        filterReviewContent: (field, value) => dispatch(filterReviewContent(field, value)),
        getReviewContent: () => dispatch(getReviewContent())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Review));