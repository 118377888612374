import React, { useContext, useState } from "react";
import { Button, CircularProgress, Menu, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PropTypes from "prop-types";
import { AppContext } from "../../App/components";
import { ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, APPLICATION_NAME } from "../../util/constants";
import useStyles from "../styles/theme";

function Account(props) {
    const classes = useStyles();

    const [userMenuEl, setUserMenuEl] = useState(null);
    const onUserMenuClick = event => {
        setUserMenuEl(event.currentTarget);
    };
    const onUserMenuClose = () => {
        setUserMenuEl(null);
    };

    const { signIn, signOut, user } = useContext(AppContext);
    const onLogin = () => {
        signIn();
    }
    const onLogout = () => {
        onUserMenuClose();
        signOut();
    }

    switch(user.state) {
        case ACTION_STATE_SUCCESS:
            return [
                <Button id="user-menu-button" key="user-menu-button" className={props.className} size="large" color="primary"
                    aria-label="User Menu" aria-controls="user-menu" aria-haspopup="true" onClick={onUserMenuClick}>
                    Hello, {user.name} <ArrowDropDownIcon />
                </Button>,
                <Menu key="user-menu" id="user-menu" anchorEl={userMenuEl} keepMounted open={Boolean(userMenuEl)} onClose={onUserMenuClose}>
                    <MenuItem aria-label={`Log out of ${APPLICATION_NAME}`} onClick={onLogout}>Log Out</MenuItem>
                </Menu>
            ];
        case ACTION_STATE_LOADING:
            return (<CircularProgress className={`${classes.loading}${props.className ? ` ${props.className}` : ''}`} color="secondary" />);
        default:
            return (<Button className={props.className} color="primary" aria-label={`Log in to ${APPLICATION_NAME}`} onClick={onLogin}>Log In</Button>);
    }
}

Account.defaultProps = {
    className: ''
};
  
Account.propTypes = {
    className: PropTypes.string
};

export default Account;