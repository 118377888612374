import { compact } from "lodash";
import Moment from "moment";
import { DATE_FORMAT, SORT_DIRECTION } from "./constants";

export function buildAction(type, payload) {
    return { type, payload };
}

export function arrayToString(array) {
    return Array.isArray(array) ? array.join(", ") : "";
}

export function formatDateString(dateString) {
    const formatted = (dateString && dateString.includes("/"));
    if(formatted) {
        return dateString;
    } else {
        const date = Moment(dateString);
        return date.isValid() ? date.format(DATE_FORMAT) : "";
    }
}

export function getUserRoleFromChannel(channel) {
    return channel.replace(/\s/g, "").toUpperCase();
}

export const getSortComparator = (sortBy, sortDirection, sortTransformer = null) => {
    const descendingComparator = (a, b, sortBy) => {
        const aField = (sortTransformer ? sortTransformer(a[sortBy]) : a[sortBy]);
        const bField = (sortTransformer ? sortTransformer(b[sortBy]) : b[sortBy]);
        if(bField < aField) return -1;
        if(bField > aField) return 1;
        return 0;
    };
    return (sortDirection === SORT_DIRECTION.DESCENDING ? 
        (a, b) => descendingComparator(a, b, sortBy) : 
        (a, b) => -descendingComparator(a, b, sortBy)
    );
};

export const sort = (rows, comparator) => {
    const sortedRows = rows.map((row, index) => [row, index]);
    sortedRows.sort((a, b) => {
        const result = comparator(a[0], b[0]);
        if(result !== 0) return result;
        return (a[1] - b[1]);
    });
    return sortedRows.map((row) => row[0]);
};

export function getFileRowCount(file, callback, ignoreHeader = false) {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
        const fileString = fileReader.result.toString("utf-8");
        const fileRows = compact(fileString.split(/\r|\n/)); // compact to remove empty rows
        callback((ignoreHeader ? (fileRows.length - 1) : fileRows.length));
    };
    fileReader.readAsText(file);
};