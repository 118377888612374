import * as Mui from "@material-ui/core";

const useStyles = Mui.makeStyles((theme) => {
    return {
        checkbox: {
            display: "block",
            marginLeft: "1rem"
        },
        dates: {
            marginBottom: "1.5rem !important"
        },
        endDate: {
            "& input": {
                [theme.breakpoints.down("xs")]: {
                    width: "12.5rem"
                },
                [theme.breakpoints.between("sm", "md")]: {
                    width: "26.5rem"
                },
                [theme.breakpoints.up("md")]: {
                    width: "10.438rem"
                }
            }
        },
        formControl: {
            display: "block"
        },
        grid: {
            display: "flex",
            flexGrow: 1,
            flexWrap: "wrap"
        },
        helperText: {
            fontSize: "0.75rem !important",
            marginBottom: "0rem !important"
        },
        legend: {
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "1rem",
            fontWeight: 500
        },
        priority: {
            display: "block",
            marginTop: "1.5rem",
            marginBottom: "1rem",
            "& input": {
                width: "16rem"
            }
        },
        radio: {
            marginLeft: "1rem"
        },
        screen: {
            display: "block",
            width: "31.75rem",
            [theme.breakpoints.down("xs")]: {
                width: "17.75rem"
            }
        },
        screenFormat: {
            fontSize: "100%",
            fontWeight: 400,
        },
        startDate: {
            [theme.breakpoints.down("sm")]: {
                marginBottom: "1rem"
            },
            "& input": {
                [theme.breakpoints.down("xs")]: {
                    width: "12.5rem"
                },
                [theme.breakpoints.between("sm", "md")]: {
                    width: "26.5rem"
                },
                [theme.breakpoints.up("md")]: {
                    width: "10.438rem"
                }
            }
        },
        textField: {
            display: "block",
            marginTop: "1rem",
            marginBottom: "1.5rem",
            "& input": {
                width: "30rem",
                [theme.breakpoints.down("xs")]: {
                    width: "16rem"
                }
            }
        },
        usersFile: {
            marginBottom: "0rem"
        },
        userFileHelperText: {
            marginBottom: "0rem !important"
        },
        userFileDescription: {
            fontSize: "75% !important",
            marginBottom: "0rem !important",
            marginLeft: "0.95rem !important"
        },
        usersCount: {
            marginLeft: "0.95rem !important"
        }
    };
});

export default useStyles;