import { isEmpty } from "lodash";
import { USER, USER_STATE } from "../actions-index";
import { ACTION_STATE_SUCCESS } from "../util/constants";

export function user(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case USER:
            return { 
                data: payload, 
                state: !isEmpty(payload) ? ACTION_STATE_SUCCESS : state.state
            };
        case USER_STATE:
            return {
                ...state,
                state: payload
            };
        default:
            return state;
    }
}