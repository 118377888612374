import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Icon, OSUButton } from "osu-react-components";
import ArticleSection from "./ArticleSection";

function ChannelSections(props) {
    const { channel, onDrag, sections = [] } = props;
    const hasSections = sections.length > 0;

    return (
        <div data-testid="channel-sections">
            {!hasSections &&
                <div data-testid="no-channel-sections" className="font-weight-bold my-2 text-center">There are currently no sections for this channel.</div>
            }
            {hasSections &&
                <DragDropContext onDragEnd={onDrag}>
                    <Droppable droppableId="channelSections">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {sections.map((section, index) => {
                                    return (<ChannelSection key={section.sectionId} channel={channel} section={section} index={index} />);
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </div>
    );
}

ChannelSections.propTypes = {
    channel: PropTypes.string.isRequired,
    onDrag: PropTypes.func.isRequired,
    sections: PropTypes.arrayOf(PropTypes.shape({
        articles: PropTypes.array,
        sectionId: PropTypes.string.isRequired,
        sectionPriority: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired
    }))
}

export default ChannelSections;

function ChannelSection(props) {
    const { channel, index, section } = props;
    const { articles } = section;
    const [isExpandable, setIsExpandable] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    
    useEffect(() => {
        if(articles.length > 0) setIsExpandable(true);
    }, [articles]);

    return (
        <Draggable draggableId={section.sectionId} index={index}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} 
                    {...provided.dragHandleProps} className="mb-2">
                    <Icon type="align-justify" className="d-inline mr-2" />
                    <Link to={`/organize/channel/${channel}/section/${section.sectionId}`}>
                        <h3 className="d-inline">{section.title}</h3>
                    </Link>
                    {isExpandable &&
                        <OSUButton link className="float-right"
                            ariaLabel={isExpanded ? "Hide section content" : "Show section content"}
                            onClick={() => setIsExpanded(!isExpanded)}>
                            <Icon type={isExpanded ? "chevron-down" : "chevron-up"} />
                        </OSUButton>
                    }
                    <hr />
                    {isExpanded &&
                        <div>
                            {articles.map((article, index) => {
                                return (<ArticleSection key={index} article={article} />);
                            })}
                        </div>
                    }
                </div>
            )}
        </Draggable>
    );
}

ChannelSection.propTypes = {
    channel: PropTypes.string.isRequired,
    section: PropTypes.shape({
        articles: PropTypes.array,
        sectionId: PropTypes.string.isRequired,
        sectionPriority: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired
    }).isRequired
}