import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { Alert } from "reactstrap";
import { Typography } from "@material-ui/core";
import { OSUButton, OSUError, OSULoading, Subtitle2 } from "osu-react-components";
import { cloneDeep, forEach, sortBy } from "lodash";
import { AppContext } from "../../App/components";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, CHANNELS } from "../../util/constants";
import { getUserRoleFromChannel } from "../../util/util";
import ChannelSections from "../../Common/components/ChannelSections";
import NavigationPrompt from "../../Common/components/NavigationPrompt";
import "../styles/index.css";

export default function Organize(props) {
  const {
    deleteChannel, deleteChannelState,
    getChannels, channels, channelsState,
    getChannelSections, channelSections, channelSectionsState,
    resetChannels, resetDeleteChannelState, resetSelectedChannel, resetUpdateChannelSectionsPriorityState,
    selectedChannel, setSelectedChannel,
    updateChannelSectionsPriority, updateChannelSectionsPriorityState
  } = props;
  const { user } = useContext(AppContext);
  const { canManageCompositeChannels, isAdmin, isApprover } = user;
  const history = useHistory();
  const { pathname } = useLocation();
  const alertsEl = useRef(null);
  const [channelOptions, setChannelOptions] = useState([]);
  const [sections, setSections] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const isDeletingChannel = (deleteChannelState === ACTION_STATE_LOADING);
  const deleteChannelError = (deleteChannelState === ACTION_STATE_ERROR);
  const channelDeleted = (deleteChannelState === ACTION_STATE_SUCCESS);
  const navigationPromptMessage = "Changes made to the sections will not be applied until the Save button is clicked. " + 
    "Please confirm that you want to leave the page without saving.";

  if(channelsState === "") getChannels();

  // channel updates
  useEffect(() => {
    // filter channels by role (unless admin)
    let approvedChannels = isAdmin ? 
      [...channels] : 
      channels.filter(channel => {
        return (channel.composite ? canManageCompositeChannels : user.roles.includes(getUserRoleFromChannel(channel.key)));
      }
    );
    let channelOptions = [];
    let compositeChannelOptions = [];
    for(const channel of approvedChannels) {
      const composite = (channel.composite === true);
      const option = { label: channel.label, value: channel.key, composite };
      if(composite) {
        option.channels = channel.channels;
        compositeChannelOptions.push(option);
      } else {
        channelOptions.push(option);
      }
    }
    channelOptions = sortBy(channelOptions, ["label"]);
    compositeChannelOptions = sortBy(compositeChannelOptions, ["label"]);
    if(compositeChannelOptions.length > 0) channelOptions.push({ label: "Created Channels", options: compositeChannelOptions });
    setChannelOptions(channelOptions);
  }, [canManageCompositeChannels, channels, isAdmin, isApprover, user.roles]);

  // channel options updates
  useEffect(() => {
    if(channelOptions.length === 1) setSelectedChannel(channelOptions[0]);
  }, [channelOptions, setSelectedChannel]);

  // selected channel updates
  useEffect(() => {
    if(selectedChannel) {
      getChannelSections(selectedChannel.value);
    }
  }, [selectedChannel, getChannelSections]);

  // channel section updates
  useEffect(() => {
    setSections(channelSections);
    resetUpdateChannelSectionsPriorityState();
  }, [channelSections, resetUpdateChannelSectionsPriorityState]);

  // update channel sections priority state updates
  useEffect(() => {
    if(updateChannelSectionsPriorityState === ACTION_STATE_SUCCESS) {
      setHasChanges(false);
      resetUpdateChannelSectionsPriorityState();
    } else if(updateChannelSectionsPriorityState === ACTION_STATE_ERROR) {
      alertsEl.current.focus();
    }
  }, [updateChannelSectionsPriorityState, resetUpdateChannelSectionsPriorityState]);

  // delete channel updates
  useEffect(() => {
    if(deleteChannelError || channelDeleted) {
      alertsEl.current.focus();
      if(channelDeleted) {
        resetSelectedChannel(); // reset the selected channel since it was deleted
        resetChannels(); // force channels to reload
      }
    }
  }, [channelDeleted, deleteChannelError, resetChannels, resetSelectedChannel])

  // will unmount (pathname changes)
  useEffect(() => {
    return () => {
      resetDeleteChannelState()
      resetUpdateChannelSectionsPriorityState();
    };
  }, [pathname, resetDeleteChannelState, resetUpdateChannelSectionsPriorityState]);

  const onChannelSectionDrag = (result) => {
    if(result && result.source && result.destination) { // handles an error due to multiple quick swaps
      const sectionsUpdate = cloneDeep(sections);
      const sourceIndex = result.source.index;
      const source = sectionsUpdate[sourceIndex];
      const destinationIndex = result.destination.index;

      sectionsUpdate.splice(sourceIndex, 1);
      sectionsUpdate.splice(destinationIndex, 0, source);
      forEach(sectionsUpdate, (section, index) => {
        section.sectionPriority = (index + 1);
      });

      setSections(sectionsUpdate);
      setHasChanges(true);
    }
  };

  const cancelChanges = () => {
    setHasChanges(false);
    setSections(channelSections);
  }

  const saveChanges = () => {
    const sectionsPriority = sections.map(section => {
      return { sectionId: section.sectionId, priority: section.sectionPriority };
    });
    updateChannelSectionsPriority(selectedChannel.value, sectionsPriority);
  }
  const saving = (updateChannelSectionsPriorityState === ACTION_STATE_LOADING);
  
  return (
    <div>
      <div data-testid="alerts" ref={alertsEl} tabIndex="-1">
          {updateChannelSectionsPriorityState === ACTION_STATE_ERROR &&
              <Alert data-testid="save-alert" color="danger" toggle={resetUpdateChannelSectionsPriorityState}>
                  <h3>Save Failure</h3>
                  <p className="mb-0">
                      Failed to save changes.  Please retry to see if that resolves the issue.
                  </p>
              </Alert>
          }
          <Alert data-testid="delete-error-alert" color="danger" isOpen={deleteChannelError} toggle={resetDeleteChannelState}>
              <Subtitle2>Delete Channel Failure:</Subtitle2>
              <p className="mb-0">An error occurred while deleting the channel. Please retry to see if that resolves the issue.</p>
          </Alert>
          <Alert data-testid="delete-success-alert" color="success" isOpen={channelDeleted} toggle={resetDeleteChannelState}>
              <Subtitle2>Delete Channel Success:</Subtitle2>
              <p className="mb-0">The channel was successfully deleted.</p>
          </Alert>
      </div>
      <h1 data-testid="heading">Organize Your Content By Selecting a Channel</h1>
      {channelsState === ACTION_STATE_LOADING &&
        <OSULoading dataTestId="loading-channels" text="Loading Channels..." />
      }
      {channelsState === ACTION_STATE_ERROR &&
        <OSUError dataTestId="error-channels" text="Failed to retrieve channels."
          small="true" actionText="Retry" ariaLabel="Retry to retrieve channels" 
          onClick={() => getChannels()} />
      }
      {channelsState === ACTION_STATE_SUCCESS &&
        <div>
          {saving &&
            <OSULoading dataTestId="saving" text="Saving..." />
          }
          {isDeletingChannel && <OSULoading dataTestId="deletingChannel" text="Deleting Channel..." />}
          <div data-testid="content" className={(saving || isDeletingChannel) ? "d-none" : ""}>
            {(isAdmin || canManageCompositeChannels) && <Link to="/organize/channel/create">Create Channel</Link>}
            <div className="input-group mt-2">
              <form data-testid="channelForm" className="mr-1" style={{ width: "15rem" }}>
                <Select name="channelSelect" aria-label="Select a Channel" placeholder="Select a Channel"
                  options={channelOptions} noOptionsMessage={() => { return "There are no channels" }}
                  getOptionLabel={(option) => (option.composite ? `${option.label} (${option.value})` : option.label)}
                  value={selectedChannel} isDisabled={hasChanges}
                  onChange={(option) => setSelectedChannel(option)} />
              </form>
              {(isAdmin || canManageCompositeChannels) && selectedChannel && selectedChannel.composite === true &&
                <OSUButton ariaLabel={`Delete channel ${selectedChannel.label}`} color="blue"
                  onClick={() => deleteChannel(selectedChannel.value)}>Delete</OSUButton>
              }
            </div>
            {selectedChannel && selectedChannel.composite === true &&
              <div className="mt-2">
                <Typography variant="body1" component="div"><b>Key:</b> {selectedChannel.value}</Typography>
                <Typography variant="body1" component="div"><b>Content:</b> {selectedChannel.channels.map(channel => (CHANNELS[channel])).join(", ")}</Typography>
              </div>
            }
            <hr />
            {selectedChannel && channelSectionsState === ACTION_STATE_LOADING &&
              <OSULoading dataTestId="loading-channel-sections" text={`Loading ${selectedChannel.label} Sections...`} />
            }
            {selectedChannel && channelSectionsState === ACTION_STATE_ERROR &&
              <OSUError dataTestId="error-channel-sections" text={`Failed to retrieve ${selectedChannel.label} sections.`}
                small="true" actionText="Retry" ariaLabel={`Retry to retrieve ${selectedChannel.label} sections.`}
                onClick={() => getChannelSections(selectedChannel.value)} />
            }
            {selectedChannel && channelSectionsState === ACTION_STATE_SUCCESS &&
              <div>
                <h2 data-testid="heading-sections" className="mb-2">{selectedChannel.label} Sections</h2>
                <ChannelSections channel={selectedChannel.label} sections={sections} onDrag={onChannelSectionDrag} />
                {sections.length > 0 &&
                  <div className="d-inline">
                    <OSUButton ariaLabel={`Cancel changes to ${selectedChannel.label} sections priority`} color="gray" className="mr-1"
                      disabled={!hasChanges} onClick={() => cancelChanges()}>Cancel</OSUButton>
                    <OSUButton ariaLabel={`Save changes to ${selectedChannel.label} sections priority`} color="blue"
                      disabled={!hasChanges} onClick={() => saveChanges()}>Save</OSUButton>
                  </div>
                }
                <div className="d-inline">
                  <OSUButton ariaLabel="Create Section" color="blue" className="float-right"
                    onClick={() => history.push(`/organize/channel/${selectedChannel.label}/section`)}>Create Section</OSUButton>
                </div>
                <NavigationPrompt dataTestId="navigation-prompt" prompt={hasChanges} header="Unsaved Changes" message={navigationPromptMessage} />
              </div>
            }
          </div>
        </div>
      }
    </div>
  );
}