import * as Mui from "@material-ui/core";

const useStyles = Mui.makeStyles((theme) => {
    return {
        announcementsTable: {
            "& table, thead, tbody, th, td, tr": {
                [theme.breakpoints.down("sm")]: { /* force table to not be a table anymore */
                    display: "block"
                }
            },
            "& thead tr": {
                [theme.breakpoints.down("sm")]: { /* hide table headers (without using display, to support accessibility) */
                    left: "-9999px",
                    position: "absolute",
                    top: "-9999px"
                }
            },
            "& tr": {
                [theme.breakpoints.down("sm")]: {
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                    paddingBottom: "0rem",
                    paddingTop: "1rem"
                }
            },
            "& tbody > tr:hover": {
                cursor: "pointer",
                backgroundColor: "rgba(0, 0, 0, .04)"
            },
            "& td": {
                overflowWrap: "break-word",
                [theme.breakpoints.down("sm")]: { /* behave like a row */
                    borderBottom: 0,
                    marginTop: "0.1rem",
                    marginBottom: "0.1rem",
                    minHeight: "2rem",
                    paddingBottom: "0rem",
                    paddingLeft: "6rem",
                    paddingRight: "0rem",
                    paddingTop: "0rem",
                    position: "relative",
                    textAlign: "left"
                }
            },
            "& td:before": {
                [theme.breakpoints.down("sm")]: { /* behave like a table header */
                    content: "attr(data-header)",
                    fontWeight: 600,
                    left: "0rem",
                    position: "absolute",
                    whiteSpace: "nowrap"
                }
            },
            "& .expandCollapse": {
                [theme.breakpoints.down("sm")]: {
                    textAlign: "right"
                }
            },
            "& .expandedRow": {
                backgroundColor: "rgba(0, 0, 0, 0.02)",
                [theme.breakpoints.down("sm")]: {
                    paddingBottom: "1rem"
                },
                "& td": {
                    [theme.breakpoints.down("sm")]: {
                        paddingLeft: "0",
                    }
                }
            },
            "& .expandedRow:hover": {
                cursor: "default !important",
                backgroundColor: "initial !important"
            },
            "& .expandedContent": {
                marginLeft: "0.5rem",
                marginRight: "0.5rem"
            },
            "& .noDataMessage": {
                textAlign: "center !important",
                [theme.breakpoints.down("sm")]: {
                    paddingLeft: "0 !important",
                }
            }
        },
        findSection: {
            display: "flex",
            flexWrap: "wrap",
            marginBottom: "1rem",
            marginTop:"1.5rem",
            "@media(max-width: 468px)": {
                marginBottom: 0
            }
        },
        findButton: {
            "@media(max-width: 468px)": {
                marginBottom: "1rem",
                marginTop: "1rem"
            }
        },
        findInput: {
            width: "22rem",
            "@media(max-width: 468px)": {
                width: "18rem"
            }
        },
        pagination: {
            "& p": {
                marginBottom: "0.2rem !important"
            },
            [theme.breakpoints.down("xs")]: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap"
            }
        },
        sortDirectionRadio: {
            paddingBottom: 0,
            paddingTop: 0
        },
        sortDirectionRadioGroup: {
            marginBottom: "0.5rem"
        },
        sortDirectionRadioGroupLabel: {
            marginBottom: 0
        },
        url: {
            wordWrap: "break-word",
            wordBreak: "break-all",
            hyphens: "auto"
        }
    };
});

export default useStyles;