import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CreateChannel from "../../components/CreateChannel";
import { CHANNELS_STATE, createChannel, CREATE_CHANNEL_STATE, resetState } from "../../../actions-index";

const mapStateToProps = (state) => {
    return {
        createChannelState: state.createChannelState
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createChannel: (key, name, channels) => dispatch(createChannel(key, name, channels)),
        resetChannelsState: () => dispatch(resetState(CHANNELS_STATE, "")), // forces channels to reload
        resetCreateChannelState: () => dispatch(resetState(CREATE_CHANNEL_STATE, ""))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateChannel));