import moment from "moment";
import * as AUDIENCE from "./audience";
import { ANNOUNCEMENT_DATE_DISPLAY_FORMAT, ANNOUNCEMENT_TYPE } from "../util/constants";
import { buildCollegeLabel, getAudience } from "./util";

export function transformAnnouncements(announcements, forDisplay = false) {
    const transformedAnnouncements = [];
    for(const announcement of announcements) {
        if(announcement.id.startsWith(ANNOUNCEMENT_TYPE.GROUP)) {
            const { authenticated, affiliation, campus, createdBy, createdDate, disabled, dismissed, endDate, id, message, priority, screen, startDate, title, updatedBy, updatedDate, url } = announcement;
            const idTokens = id.split("#"); // GROUP#{group}#{id}
            const group = idTokens[1];
            const announcementId = idTokens[2];
            const application = (announcement?.application ?? []).map(item => (forDisplay ? (AUDIENCE.APPLICATION[item] ?? "?") : item));
            const audience = getAudience(authenticated, affiliation);
            const academicCareer = (announcement?.academicCareer ?? []).map(item => (forDisplay ? (AUDIENCE.ACADEMIC_CAREER[item] ?? "?") : item));
            const academicProgram = (announcement?.academicProgram ?? []).map(item => (forDisplay ? (AUDIENCE.ACADEMIC_PROGRAM[item] ?? "?") : item));
            const college = (announcement?.college ?? []).map(item => (forDisplay ? buildCollegeLabel(item) : item));
            transformedAnnouncements.push({
                isGroup: true,
                group,
                id: announcementId,
                application,
                audience,
                title,
                startDate: (forDisplay ? moment(startDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : startDate),
                endDate: (forDisplay ? moment(endDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : endDate),
                priority,
                message,
                authenticated,
                affiliation,
                createdBy,
                createdDate: (forDisplay ? moment(createdDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : createdDate),
                updatedBy,
                updatedDate: (forDisplay ? moment(updatedDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : updatedDate),
                disabled,
                dismissed,
                screen: (screen ?? ""),
                url,
                academicCareer,
                academicProgram,
                campus,
                college
            });
        } else if(announcement.id.startsWith(ANNOUNCEMENT_TYPE.BULK)) {
            const { createdBy, createdDate, endDate, id, message, priority, screen, startDate, status, title, updatedBy, updatedDate, url, usersCount, usersFile = "" } = announcement;
            const usersFileTokens = usersFile.split("/");
            transformedAnnouncements.push({
                id,
                title,
                startDate: (forDisplay ? moment(startDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : startDate),
                endDate: (forDisplay ? moment(endDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : endDate),
                priority,
                message,
                createdBy,
                createdDate: (forDisplay ? moment(createdDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : createdDate),
                updatedBy,
                updatedDate: (forDisplay ? moment(updatedDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : updatedDate),
                screen: (screen ?? ""),
                status,
                url,
                users: usersCount,
                usersFile: usersFileTokens[usersFileTokens.length - 1]
            });
        } else {
            const { createdDate, dismissed, endDate, id, message, priority, screen, startDate, title, updatedDate, url } = announcement;
            transformedAnnouncements.push({
                id,
                isGroup: false,
                audience: title,
                title: message,
                startDate: (
                    startDate ?
                    (forDisplay ? moment(startDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : startDate) :
                    (forDisplay ? moment(createdDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : createdDate)
                ),
                endDate: (
                    endDate ?
                    (forDisplay ? moment(endDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : endDate) :
                    ""
                ),
                priority,
                createdDate: (forDisplay ? moment(createdDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : createdDate),
                updatedDate: (forDisplay ? moment(updatedDate).format(ANNOUNCEMENT_DATE_DISPLAY_FORMAT) : updatedDate),
                dismissed,
                screen: (screen ?? ""),
                url
            });
        }
    }
    return transformedAnnouncements;
}

export function transformDateStringToTime(dateString) {
    return new Date(dateString).getTime();
}