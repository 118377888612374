import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import * as Mui from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { keys } from "lodash";
import moment from "moment";
import Announcement from "./Announcement";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, ANNOUNCEMENT_DATE_FIELD_FORMAT, ANNOUNCEMENT_FIELD_LABELS,
    EVENT_ANNOUNCEMENT_RESET_STATE } from "../../util/constants";

export default function BulkAnnouncement(props) {
    const { bulkAnnouncementOperationState, createBulkAnnouncement, resetBulkAnnouncementFileKey, resetBulkAnnouncementOperationState, resetBulkAnnouncements } = props;
    const history = useHistory();
    const errorAlert = useRef(null);
    const successAlert = useRef(null);
    const [announcement, setAnnouncement] = useState({});
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isValidAnnouncement, setValidAnnouncement] = useState(false);
    const [usersFileCount, setUsersFileCount] = useState(null);

    const isOperationRunning = (bulkAnnouncementOperationState === ACTION_STATE_LOADING);
    const operationError = (bulkAnnouncementOperationState === ACTION_STATE_ERROR);
    const operationSuccess = (bulkAnnouncementOperationState === ACTION_STATE_SUCCESS)

    // when the announcement operation fails, set the focus on the error alert
    useEffect(() => {
        if(operationError) errorAlert.current.focus();
    }, [operationError]);

    // when the announcement operation is successful...
    // - set the focus on the success alert
    // - clear the announcement state to reset the form
    // - send event to reset announcement
    // - clear bulk announcement file key from state
    // - reset bulk announcements state so that it pulls fresh data
    useEffect(() => {
        if(operationSuccess) {
            successAlert.current.focus();
            setState({}, false);
            document.dispatchEvent(new Event(EVENT_ANNOUNCEMENT_RESET_STATE));
            resetBulkAnnouncementFileKey();
            resetBulkAnnouncements();
        }
    }, [operationSuccess, resetBulkAnnouncementFileKey, resetBulkAnnouncements]);

    // on unmount, reset state
    useEffect(() => {
        return () => {
            resetBulkAnnouncementFileKey();
            resetBulkAnnouncementOperationState();
        };
    }, [resetBulkAnnouncementFileKey, resetBulkAnnouncementOperationState]);

    const setState = (state, isValid) => {
        setAnnouncement(state);
        setValidAnnouncement(isValid);
    };
      
    const save = () => {
        setIsConfirmationModalOpen(false);
        window.scrollTo(0, 0); // scroll to the top of the page so that the loading indicator is visible
        createBulkAnnouncement({ ...announcement, usersCount: usersFileCount });
    };

    return (
        <div>
            {operationError && 
                <Alert id="errorAlert" severity="error" ref={errorAlert} tabIndex={-1} className="mb-1" onClose={() => resetBulkAnnouncementOperationState()}>There was an error creating the announcement.</Alert>
            }
            {operationSuccess && 
                <Alert id="successAlert" severity="success" ref={successAlert} tabIndex={-1} className="mb-1" onClose={() => resetBulkAnnouncementOperationState()}>Announcement created successfully.</Alert>
            }
            <div className="d-flex mb-3">
                <Link id="announcementsLink" aria-label="Navigate to Announcements" to="/announcements" className="d-flex p-0">
                    <Mui.Typography variant="caption" color="primary">Announcements</Mui.Typography>
                </Link>
                <Mui.Typography variant="caption" className="px-1">/</Mui.Typography>
                <Mui.Typography variant="caption" color="secondary">Bulk Announcement</Mui.Typography>
            </div>
            <h1 id="heading" className="mt-3">Bulk Announcement</h1>
            {isOperationRunning &&
                <Mui.Box display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center" marginTop="2rem">
                    <Mui.CircularProgress size="20px" aria-describedby="bulkAnnouncementOperationRunning" className="mb-2 mr-1" />
                    <Mui.Typography id="bulkAnnouncementOperationRunning" variant="body1" aria-live="assertive">Creating announcement...</Mui.Typography>
                </Mui.Box>
            }
            <Mui.Box id="content" visibility={(isOperationRunning ? "hidden" : null)}>
                <Announcement bulk={true} state={announcement} setState={setState} setUsersFileCount={setUsersFileCount} />
                <Mui.Box display="flex" justifyContent="space-between" maxWidth="17.9rem">
                    <Mui.Button variant="contained" color="secondary" aria-label="Cancel Create" onClick={() => history.push("/announcements")}>Cancel</Mui.Button>
                    <Mui.Button variant="contained" color="primary" aria-label="Create Announcement" disabled={!isValidAnnouncement} onClick={() => setIsConfirmationModalOpen(true)}>Create</Mui.Button>
                </Mui.Box>
            </Mui.Box>
            <ConfirmationModal data={announcement} openState={[isConfirmationModalOpen, setIsConfirmationModalOpen]} onConfirm={save} usersCount={usersFileCount} />
        </div>
    );
}

function ConfirmationModal(props) {
    const { data = {}, openState, onConfirm, usersCount = 0 } = props;
    return (
        <Modal isOpen={openState[0]}>
            <ModalHeader>Create Bulk Announcement Confirmation</ModalHeader>
            <ModalBody>
                <Mui.Typography variant="body2">
                    Please confirm that you want to create the following bulk announcement impacting
                    <Mui.Typography component="span" variant="subtitle2"> {usersCount} user(s)</Mui.Typography>:
                </Mui.Typography>
                <Mui.Grid container spacing={1} direction="column" justifyContent="center">
                    {keys(ANNOUNCEMENT_FIELD_LABELS).map(key => {
                        const label = ANNOUNCEMENT_FIELD_LABELS[key];
                        let value = data?.[key];
                        switch(key) {
                            case "usersFile":
                                value = data[key]?.name;
                                break;
                            case "startDate":
                            case "endDate":
                                if(value) value = moment(value).format(ANNOUNCEMENT_DATE_FIELD_FORMAT);
                                break
                            case "priority":
                                if(!value) value = 0;
                                break;
                            default:
                        }
                        return (
                            <Mui.Grid key={key} item style={{ marginLeft: "1rem"}}>
                                <Mui.Typography component="span" variant="subtitle2">{label}: </Mui.Typography>
                                <Mui.Typography component="span" variant="body2">{value}</Mui.Typography>
                            </Mui.Grid>
                        );
                    })}
                </Mui.Grid>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <Mui.Button variant="contained" color="secondary" onClick={() => openState[1](false)}>Cancel</Mui.Button>
                <Mui.Button variant="contained" color="primary" onClick={onConfirm}>Confirm</Mui.Button>
            </ModalFooter>
        </Modal>
    );
}