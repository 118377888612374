import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { CircularProgress, Button } from "@material-ui/core";
import { AppContext } from "../../App/components";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, APPLICATION_NAME } from "../../util/constants";
import { redirectToSignIn } from "../util";
import { isEmpty } from "lodash";

export default function SignIn() {
    const { getUser, user } = useContext(AppContext);
    if(isEmpty(user.state)) getUser();
    switch(user.state) {
        case ACTION_STATE_LOADING:
            return (
                <div>
                    <h1 className="sr-only">Log In</h1>
                    <h2 className="d-flex flex-column align-items-center">
                        Logging in...
                        <CircularProgress className="mt-2" width={60} height={60} color="primary" />
                    </h2>
                </div>
            );
        case ACTION_STATE_ERROR:
            return (
                <div>
                    <h1 className="sr-only">Log In</h1>
                    <h2 className="d-flex flex-column align-items-center">
                        There was an error logging you in.
                        <Button className="mt-2" variant="contained" color="primary" aria-label={`Retry logging in to ${APPLICATION_NAME}`} onClick={() => redirectToSignIn()}>Log In</Button>
                    </h2>
                </div>
            );
        case ACTION_STATE_SUCCESS:
            return (<Redirect to="/" />);
        default:
            return null;
    }
}