import { makeStyles } from "@material-ui/core/styles";
import { STYLESHEET_LENGTH } from "../../util/constants";

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1
    },
    links: {
        paddingRight: theme.spacing(4),
        fontWeight: theme.typography.fontWeightMedium
    },
    icon: {
        width: theme.spacing(7),
        height: "100%",
    },
    brandIcon: {
        padding: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    loading: {
        padding: theme.spacing()
    }
}), { index: STYLESHEET_LENGTH });

export default useStyles