import React, { createElement } from "react";
import Markdown from "react-markdown";
import PropTypes from "prop-types";
import Photo from "./Photo";

function Body(props) {
    return (
        <Markdown source={props.text}
            renderers={
                { 
                    image: (props) => {
                        return (<Photo alt={props.alt} src={props.src} />);
                    },
                    link: (props) => {
                        return (<a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>);
                    },
                    list: (props) => createElement(props.ordered ? 'ol' : 'ul', { children: props.children, className: `${props.className || ''} stylized`.trim() })
                }
            } 
        />
    );
}

Body.propTypes = {
    text: PropTypes.string.isRequired
}

export default Body;