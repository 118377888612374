import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { Body2, Caption, Icon, OSUError, OSULoading, PaginationWrapper } from "osu-react-components";
import { chunk, isEmpty, map } from "lodash";
import moment from "moment";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, APPLICATION_PACKAGES, NOTIFICATION_STATUS_SKIPPED } from "../../util/constants";
import "../styles/history.css";

const DATE_FORMAT = "MM/DD/YYYY hh:mm:ss A";

export default function NotificationHistory(props) {
    const { getNotificationHistory, notificationHistory, notificationHistoryState } = props;

    const [tableKeys] = useState([
        { label: "Start Date", key: "startDate", width: 26 },
        { label: "EMPLID", key: "emplid", width: 16 },
        { label: "Status", key: "status", width: 16 },
        { label: "Sent Date", key: "sentDate", width: 26 },
        { label: "Users", key: "users", width: 8 },
        { label: "Devices", key: "devices", width: 8 }
    ]);
    const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState(null); // initialize to null to avoid an issue with the pagination wrapper setting rows per page to ALL when the data is loading
    const [tableDataIndex, setTableDataIndex] = useState(0);

    // on mount, get notification history
    useEffect(() => {
        getNotificationHistory();
    }, [getNotificationHistory]);

    // when notification history is returned, build table data
    useEffect(() => {
        if(notificationHistoryState === ACTION_STATE_SUCCESS) {
            if(notificationHistory.length > 0) {
                const formatDateStringForDisplay = (dateString) => {
                    const date = (!isEmpty(dateString) ? moment(dateString) : null);
                    return ((date !== null && date.isValid()) ? date.format(DATE_FORMAT) : "");
                };
                setTableData(notificationHistory.map((item, index) => {
                    const renderStatusTooltip = (status, index) => {
                        if(status === NOTIFICATION_STATUS_SKIPPED) {
                            const text = "The notification was skipped because another notification was processing when it was submitted.";
                            return (
                                <span id={`statusTooltip${index}`} role="tooltip" tabIndex="0" aria-describedby={`statusTooltip${index}Desc`} className="p-1">
                                    <Icon type="info-circle" color="blue" aria-hidden="true" />
                                    <span id={`statusTooltip${index}Desc`} className="sr-only">{text}</span>
                                    <UncontrolledTooltip placement="top" target={`statusTooltip${index}`} innerClassName="notification-status-tooltip">{text}</UncontrolledTooltip>
                                </span>
                            );
                        }
                        return null;
                    };
                    return {
                        startDate: formatDateStringForDisplay(item.execution.startDate),
                        emplid: item.user.emplid,
                        status: item.execution.status,
                        sentDate: formatDateStringForDisplay(item.notification.sentDate),
                        users: item.notification.file.users,
                        devices: item.notification.devices,
                        expandedContent: (
                            <div className="d-flex flex-wrap">
                                <section className="flex-column py-1 px-2">
                                    <Caption color="gray">Execution</Caption>
                                    <Body2>Name: {item.execution.name}</Body2>
                                    <Body2>Start Date: {formatDateStringForDisplay(item.execution.startDate)}</Body2>
                                    <Body2>Status: {item.execution.status} {renderStatusTooltip(item.execution.status, index)}</Body2>
                                </section>
                                <section className="flex-column py-1 px-2">
                                    <Caption color="gray">User</Caption>
                                    <Body2>EMPLID: {item.user.emplid}</Body2>
                                    <Body2>Name: {item.user.name}</Body2>
                                    <Body2>Email: {item.user.email}</Body2>
                                </section>
                                <section className="flex-column py-1 px-2">
                                    <Caption color="gray">Notification</Caption>
                                    <Body2>Application(s): {item.notification.appPackages.map(appPackage => APPLICATION_PACKAGES[appPackage]).join(", ")}</Body2>
                                    <Body2>Devices: {item.notification.devices}</Body2>
                                    <Body2>Sent Date: {formatDateStringForDisplay(item.notification.sentDate)}</Body2>
                                    <Caption color="gray">File</Caption>
                                    <div className="pl-1">
                                        <Body2>Name: {item.notification.file.name}</Body2>
                                        <Body2>Users: {item.notification.file.users}</Body2>
                                        <Body2>Unprocessed: {item.notification.file.unprocessed}</Body2>
                                    </div>
                                    <Caption color="gray">Message</Caption>
                                    <div className="pl-1">
                                        <Body2>Title: {item.notification.message.title}</Body2>
                                        <Body2>Body: {item.notification.message.body}</Body2>
                                        <Body2>Screen: {item.notification.message.screen}</Body2>
                                        <Body2>On Screen Text: {item.notification.message.onScreenText}</Body2>
                                    </div>
                                </section>
                            </div>
                        )
                    };
                }));
            } else {
                setTableData([]);
            }
        }
    }, [notificationHistoryState, notificationHistory]);

    let tableDataChunks = [[]];
    if(!isEmpty(tableData)) {
        if(tableRowsPerPage === null) { // ALL
            tableDataChunks = [[...tableData]]; // all data in one chunk
        } else {
            tableDataChunks = chunk(tableData, tableRowsPerPage); // data chunked by rows per page
        }
    }

    return (
        <div>
            {notificationHistoryState === ACTION_STATE_LOADING &&
                <OSULoading dataTestId="notification-history-loading" text="Loading Notification History..." />
            }
            {notificationHistoryState === ACTION_STATE_ERROR && 
                <OSUError dataTestId="notification-history-error" text="Failed to load Notification History."
                    small="true" actionText="Retry" ariaLabel="Retry loading Notification History" 
                    onClick={() => getNotificationHistory()} />
            }
            {(notificationHistoryState === ACTION_STATE_SUCCESS && tableData !== null) &&
                <Fragment>
                    <div className="d-flex mb-3">
                        <Link data-testid="notificationLink" aria-label="Navigate to Notification" to="/notification" className="d-flex p-0">
                            <Caption>Notification</Caption>
                        </Link>
                        <Caption className="px-1">/</Caption>
                        <Caption color="gray">History</Caption>
                    </div>
                    <div className="d-flex justify-content-between">
                        <h1 data-testid="heading">Notification History</h1>
                        <button aria-label="Refresh Notification History" className="btn osu-btn-blue link" onClick={() => getNotificationHistory()}>
                            <Icon type="refresh" size="lg" />
                        </button>
                    </div>
                    <PaginationWrapper
                        persist
                        totalPageCount={tableDataChunks.length}
                        updateDataIndex={(value) => setTableDataIndex(value)}
                        updateRowsPerPage={(value) => setTableRowsPerPage(value)}
                        dataIndex={tableDataIndex}
                        rowsPerPageOptions={[10, 20, 30]}
                        rowsPerPage={tableRowsPerPage}
                        resultsData={{ 
                            shownResults: (tableDataChunks[tableDataIndex] ? tableDataChunks[tableDataIndex].length : 0),
                            totalResults: (tableData ? tableData.length : 0)
                        }}
                        showOptionalCount={true}>
                        <table id="notificationHistoryTable" className="w-100">
                            <thead>
                                <tr>
                                    {tableKeys.map((item, index) => (<th key={`th${index}`}>{item.label}</th>))}
                                    <th><span className="sr-only">Expand/Collapse Content</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableDataChunks[tableDataIndex] && tableDataChunks[tableDataIndex].length > 0 ? 
                                    (tableDataChunks[tableDataIndex].map((item, index) => 
                                        (<TableRow key={`tr-${tableDataIndex + 1}-${index + 1}`} data={item} dataHeaders={map(tableKeys, "label")}
                                            pageIndex={tableDataIndex + 1} rowIndex={index + 1} />)
                                    )) :
                                    (<tr><td colSpan="6" className="py-2 text-center">No notifications have been sent</td></tr>)
                                }
                            </tbody>
                        </table>
                    </PaginationWrapper>
                </Fragment>
            }
        </div>
    );
};

function TableRow(props) {
    const { data, dataHeaders, pageIndex, rowIndex } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const onClick = () => setIsExpanded(!isExpanded);
    const expandedContentRowId = `tr-${pageIndex}-${rowIndex}-expanded`;
    return (
        <Fragment>
            <tr aria-controls={expandedContentRowId} aria-expanded={isExpanded} onClick={onClick}>
                <td data-header={dataHeaders[0]}>{data.startDate}</td>
                <td data-header={dataHeaders[1]}>{data.emplid}</td>
                <td data-header={dataHeaders[2]}>{data.status}</td>
                <td data-header={dataHeaders[3]}>{data.sentDate}</td>
                <td data-header={dataHeaders[4]}>{data.users}</td>
                <td data-header={dataHeaders[5]}>{data.devices}</td>
                <td className="d-flex justify-content-end">
                    <button aria-label={`Click to ${(isExpanded ? "collapse" : "expand")} content for row ${rowIndex}`} aria-controls={expandedContentRowId}
                        aria-expanded={isExpanded} onClick={onClick} className="btn osu-btn-blue link py-2">
                            <Icon color="blue" type={`chevron-${(isExpanded ? "down" : "up")}`} className="align-self-center" />
                    </button>
                </td>
            </tr>
            {isExpanded && 
                <tr id={expandedContentRowId} className="expanded-content-row">
                    <td colSpan="7">{data.expandedContent}</td>
                </tr>
            }
        </Fragment>
    );
}