import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Organize from "../components";
import { CHANNELS_STATE, deleteChannel, DELETE_CHANNEL_STATE, getChannels, getChannelSections, resetState, resetUpdateChannelSectionsPriorityState,
    SELECTED_CHANNEL, setSelectedChannel, updateChannelSectionsPriority } from "../../actions-index";

const mapStateToProps = (state) => {
    const { data: channels = [], state: channelsState = "" } = state.channels;
    const { data: channelSections = [], state: channelSectionsState = "" } = state.channelSections;
    return {
        channels,
        channelsState,
        channelSections,
        channelSectionsState,
        deleteChannelState: state.deleteChannelState,
        selectedChannel: state.selectedChannel,
        updateChannelSectionsPriorityState: state.updateChannelSectionsPriorityState
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteChannel: (key) => dispatch(deleteChannel(key)),
        getChannels: () => dispatch(getChannels()),
        getChannelSections: (channel) => dispatch(getChannelSections(channel)),
        resetChannels: () => dispatch(resetState(CHANNELS_STATE, "")),
        resetDeleteChannelState: () => dispatch(resetState(DELETE_CHANNEL_STATE, "")),
        resetSelectedChannel: () => dispatch(resetState(SELECTED_CHANNEL, null)),
        resetUpdateChannelSectionsPriorityState: () => dispatch(resetUpdateChannelSectionsPriorityState()),
        setSelectedChannel: (channel) => dispatch(setSelectedChannel(channel)),
        updateChannelSectionsPriority: (channel, sections) => dispatch(updateChannelSectionsPriority(channel, sections))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Organize));