import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Announcement from "./Announcement";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_NOT_FOUND, ACTION_STATE_SUCCESS, EVENT_ANNOUNCEMENT_RESET_STATE } from "../../util/constants";

export default function GroupAnnouncement(props) {
    const { announcement: existingAnnouncement, announcementOperationState, announcementState, createAnnouncement, getAnnouncement, resetAnnouncements,
        resetAnnouncementState, resetAnnouncementOperationState, updateAnnouncement } = props;
    const announcementGroup = props?.match?.params?.group;
    const announcementId = props?.match?.params?.id;
    const history = useHistory();
    const announcementsLink = useRef(null);
    const errorAlert = useRef(null);
    const retryButton = useRef(null);
    const successAlert = useRef(null);
    const [announcement, setAnnouncement] = useState();
    const [isValidAnnouncement, setValidAnnouncement] = useState(false);

    const isUpdating = (announcementId ? true : false);
    const isOperationRunning = (announcementOperationState === ACTION_STATE_LOADING);
    const operationError = (announcementOperationState === ACTION_STATE_ERROR);
    const operationSuccess = (announcementOperationState === ACTION_STATE_SUCCESS);

    // when an announcement id is provided, get the announcement
    // when getting the announcement is successful, set the announcement in state
    // when getting the announcement fails, focus on the retry button
    // when the announcement is not found, focus on the announcements link
    useEffect(() => {
        if(isUpdating && announcementState === "" && !announcement) getAnnouncement(announcementGroup, announcementId);
        if(announcementState === ACTION_STATE_SUCCESS && !announcement) setAnnouncement(existingAnnouncement);
        if(announcementState === ACTION_STATE_ERROR && retryButton.current) retryButton.current.focus();
        if(announcementState === ACTION_STATE_NOT_FOUND && announcementsLink.current) announcementsLink.current.focus();
    }, [announcement, announcementGroup, announcementId, announcementState, existingAnnouncement, getAnnouncement, isUpdating, retryButton]);

    // when the announcement operation fails, set the focus on the error alert
    useEffect(() => {
        if(operationError) errorAlert.current.focus();
    }, [operationError]);

    // when the announcement operation is successful...
    // - set the focus on the success alert
    // - reset the announcements state so that it pulls fresh data
    // - if updating, invalidate the form to disable the save button until another change is made
    // - if not updating, clear the announcement state to reset the form and send the event to reset announcement
    useEffect(() => {
        if(operationSuccess) {
            successAlert.current.focus();
            resetAnnouncements();
            if(isUpdating) {
                setValidAnnouncement(false);
            } else {
                setState({}, false);
                document.dispatchEvent(new Event(EVENT_ANNOUNCEMENT_RESET_STATE));
            }
        }
    }, [isUpdating, operationSuccess, resetAnnouncements]);

    // on unmount, reset state
    useEffect(() => {
        return () => {
            resetAnnouncementState();
            resetAnnouncementOperationState();
        };
    }, [resetAnnouncementState, resetAnnouncementOperationState]);

    const setState = (state, isValid) => {
        setAnnouncement(state);
        setValidAnnouncement(isValid);
    };

    const save = () => {
        window.scrollTo(0, 0); // scroll to the top of the page so that the loading indicator is visible
        (isUpdating ? updateAnnouncement(announcementGroup, announcement) : createAnnouncement(announcement));
      };

    return (
        <div>
            {operationError && 
                <Alert id="errorAlert" severity="error" ref={errorAlert} tabIndex={-1} className="mb-1" onClose={() => resetAnnouncementOperationState()}>
                    There was an error {(isUpdating ? "updating" : "creating")} the announcement.
                </Alert>
            }
            {operationSuccess && 
                <Alert id="successAlert" severity="success" ref={successAlert} tabIndex={-1} className="mb-1" onClose={() => resetAnnouncementOperationState()}>
                    Announcement {(isUpdating ? "updated" : "created")} successfully.
                </Alert>
            }
            <div className="d-flex mb-3">
                <Link id="announcementsLink" aria-label="Navigate to Announcements" to="/announcements" className="d-flex p-0">
                    <Mui.Typography variant="caption" color="primary">Announcements</Mui.Typography>
                </Link>
                <Mui.Typography variant="caption" className="px-1">/</Mui.Typography>
                <Mui.Typography variant="caption" color="secondary">{(isUpdating ? "Update" : "New")} Announcement</Mui.Typography>
            </div>
            <h1 id="heading" className="mt-3">{(isUpdating ? "Update" : "New")} Announcement</h1>
            {announcementState === ACTION_STATE_LOADING &&
                <Mui.Box display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center" marginTop="2rem">
                    <Mui.CircularProgress size="20px" aria-describedby="announcementLoading" className="mb-2 mr-1" />
                    <Mui.Typography id="announcementLoading" variant="body1" aria-live="assertive">Loading announcement...</Mui.Typography>
                </Mui.Box>
            }
            {announcementState === ACTION_STATE_ERROR && 
                <Mui.Box display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center" color="error" marginTop="2rem">
                    <Mui.Typography id="announcementError" variant="body1" aria-live="assertive" color="error" className="mr-1">Failed to load announcement</Mui.Typography>
                    <Mui.IconButton ref={retryButton} size="small" aria-label="Retry to load announcement" className="mb-2" onClick={() => (getAnnouncement(announcementGroup, announcementId))}>
                        <MuiIcons.Replay fontSize="small" color="error" />
                    </Mui.IconButton>
                </Mui.Box>
            }
            {announcementState === ACTION_STATE_NOT_FOUND && 
                <Mui.Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" color="error" marginTop="2rem">
                    <Mui.Typography id="announcementNotFound" component="h2" variant="h6" aria-live="assertive" color="error" >Announcement not found.</Mui.Typography>
                    <Mui.Typography variant="body1" color="error">Return to the <Link ref={announcementsLink} to="/announcements">Announcements</Link> page.</Mui.Typography>
                </Mui.Box>
            }
            {(!isUpdating || (isUpdating && announcementState === ACTION_STATE_SUCCESS)) &&
                <Fragment>
                    {isOperationRunning &&
                        <Mui.Box display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center" marginTop="2rem">
                            <Mui.CircularProgress size="20px" aria-describedby="announcementOperationRunning" className="mb-2 mr-1" />
                            <Mui.Typography id="announcementOperationRunning" variant="body1" aria-live="assertive">{(isUpdating ? "Updating" : "Creating")} announcement...</Mui.Typography>
                        </Mui.Box>
                    }
                    <Mui.Box id="content" visibility={(isOperationRunning ? "hidden" : null)}>
                        <Announcement canDisable={true} requireAudience={true} state={announcement} setState={setState} />
                        <Mui.Box display="flex" justifyContent="space-between" maxWidth="16rem">
                            <Mui.Button variant="contained" color="secondary" aria-label={`Cancel ${(isUpdating ? "Update" : "Create")}`} onClick={() => history.push("/announcements")}>Cancel</Mui.Button>
                            <Mui.Button variant="contained" color="primary" aria-label={`${(isUpdating ? "Update" : "Create")} Announcement`} disabled={!isValidAnnouncement} onClick={save}>{(isUpdating ? "Update" : "Create")}</Mui.Button>
                        </Mui.Box>
                    </Mui.Box>
                </Fragment>
            }
        </div>
    );
}