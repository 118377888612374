import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BulkAnnouncement from "../components/BulkAnnouncement";
import { BULK_ANNOUNCEMENT_FILE_KEY, BULK_ANNOUNCEMENT_OPERATION_STATE, createBulkAnnouncement, resetBulkAnnouncements, resetState } from "../../actions-index";

const mapStateToProps = (state) => {
    return {
        bulkAnnouncementOperationState: (state?.bulkAnnouncementOperationState ?? "")
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createBulkAnnouncement: (announcement) => dispatch(createBulkAnnouncement(announcement)),
        resetBulkAnnouncementFileKey: () => dispatch(resetState(BULK_ANNOUNCEMENT_FILE_KEY, null)),
        resetBulkAnnouncementOperationState: () => dispatch(resetState(BULK_ANNOUNCEMENT_OPERATION_STATE, "")),
        resetBulkAnnouncements: () => dispatch(resetBulkAnnouncements())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkAnnouncement));