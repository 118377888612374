import { API } from "aws-amplify";
import axios from "axios";
import { transformAnnouncements } from "./transform";
import { ANNOUNCEMENT, ANNOUNCEMENT_OPERATION_STATE, ANNOUNCEMENT_STATE, ANNOUNCEMENTS, ANNOUNCEMENTS_STATE, BULK_ANNOUNCEMENT_FILE_KEY, BULK_ANNOUNCEMENTS, BULK_ANNOUNCEMENTS_STATE, BULK_ANNOUNCEMENT_OPERATION_STATE } from "../actions-index";
import { handleUnauthenticated } from "../Authentication/util";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_NOT_FOUND, ACTION_STATE_SUCCESS, ANNOUNCEMENT_GROUP } from "../util/constants";
import { buildAction } from "../util/util";

export function createAnnouncement(announcement) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(ANNOUNCEMENT_OPERATION_STATE, ACTION_STATE_LOADING));
            await API.post(process.env.REACT_APP_API_NAME, `/announcements/group/${ANNOUNCEMENT_GROUP}/announcement/create`, { body: announcement });
            dispatch(buildAction(ANNOUNCEMENT_OPERATION_STATE, ACTION_STATE_SUCCESS));
        } catch(error) {
            console.error("Create Announcement Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(ANNOUNCEMENT_OPERATION_STATE, ACTION_STATE_ERROR));
            });
        }
    };
}

export function updateAnnouncement(announcementGroup, announcement) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(ANNOUNCEMENT_OPERATION_STATE, ACTION_STATE_LOADING));
            const { id, ...rest } = announcement;
            await API.post(process.env.REACT_APP_API_NAME, `/announcements/group/${announcementGroup}/announcement/update/${id}`, { body: rest });
            dispatch(buildAction(ANNOUNCEMENT_OPERATION_STATE, ACTION_STATE_SUCCESS));
        } catch(error) {
            console.error("Update Announcement Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(ANNOUNCEMENT_OPERATION_STATE, ACTION_STATE_ERROR));
            });
        }
    };
}

export function createBulkAnnouncement(announcement) {
    return async (dispatch, getState) => {
        try {
            dispatch(buildAction(BULK_ANNOUNCEMENT_OPERATION_STATE, ACTION_STATE_LOADING));
            
            const { usersFile, ...rest } = announcement;
            let fileKey;
            const state = getState();
            if(state.bulkAnnouncementFileKey) {
                fileKey = state.bulkAnnouncementFileKey; // re-use previous file key
            } else {
                const usersFileNamePrefix = usersFile.name.split(".")[0];
                const response = await API.get(process.env.REACT_APP_API_NAME, `/announcements/group/${ANNOUNCEMENT_GROUP}/announcement/file/${usersFileNamePrefix}`);
                fileKey = response.fileKey;
                await axios.put(response.signedUrl, usersFile, { headers: { "Content-Type": usersFile.type } });
                dispatch(buildAction(BULK_ANNOUNCEMENT_FILE_KEY, fileKey)); // store file key in case there is an error
            }
            
            const body = { ...rest, usersFile: fileKey };
            await API.post(process.env.REACT_APP_API_NAME, `/announcements/group/${ANNOUNCEMENT_GROUP}/announcement/create/bulk`, { body });
            
            dispatch(buildAction(BULK_ANNOUNCEMENT_OPERATION_STATE, ACTION_STATE_SUCCESS));
            dispatch(buildAction(BULK_ANNOUNCEMENT_FILE_KEY, null)); // clear file key
        } catch(error) {
            console.error("Create Bulk Announcement Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(BULK_ANNOUNCEMENT_OPERATION_STATE, ACTION_STATE_ERROR));
            });
        }
    };
}

export function getGroupAnnouncements(expired = false) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(ANNOUNCEMENTS_STATE, ACTION_STATE_LOADING));
            const announcements = await API.get(process.env.REACT_APP_API_NAME, `/announcements/group?include=disabled${((expired === true) ? `&include=expired` : "")}`);
            dispatch(buildAction(ANNOUNCEMENTS, transformAnnouncements(announcements, true)));
            dispatch(buildAction(ANNOUNCEMENTS_STATE, ACTION_STATE_SUCCESS));
        } catch(error) {
            console.error("Get Group Announcements Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(ANNOUNCEMENTS_STATE, ACTION_STATE_ERROR));
            });
        }
    };
}

export function getGroupAnnouncement(announcementGroup, announcementId) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(ANNOUNCEMENT_STATE, ACTION_STATE_LOADING));
            const announcement = await API.get(process.env.REACT_APP_API_NAME, `/announcements/group/${announcementGroup}/announcement/${announcementId}`);
            dispatch(buildAction(ANNOUNCEMENT, transformAnnouncements([announcement])[0]));
            dispatch(buildAction(ANNOUNCEMENT_STATE, ACTION_STATE_SUCCESS));
        } catch(error) {
            console.error("Get Group Announcement Error: ", error);
            handleUnauthenticated(error, () => {
                const status = (error.response && error.response.status) ? error.response.status : null;
                if(status === 404) { // announcement not found
                    dispatch(buildAction(ANNOUNCEMENT_STATE, ACTION_STATE_NOT_FOUND));
                } else {
                    dispatch(buildAction(ANNOUNCEMENT_STATE, ACTION_STATE_ERROR));
                }
            });
        }
    };
}

export function searchAnnouncements(query, all = false) {
    return async (dispatch) => {
        try {
            dispatch(buildAction(ANNOUNCEMENTS_STATE, ACTION_STATE_LOADING));
            const announcements = await API.get(process.env.REACT_APP_API_NAME, `/announcements/search?query=${query}&all=${all}`);
            dispatch(buildAction(ANNOUNCEMENTS, transformAnnouncements(announcements, true)));
            dispatch(buildAction(ANNOUNCEMENTS_STATE, ACTION_STATE_SUCCESS));
        } catch(error) {
            console.error("Search Announcements Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(ANNOUNCEMENTS_STATE, ACTION_STATE_ERROR));
            });
        }
    };
}

export function resetAnnouncements() {
    return async (dispatch) => {
        dispatch(buildAction(ANNOUNCEMENTS, []));
        dispatch(buildAction(ANNOUNCEMENTS_STATE, ""));
    };
}

export function getBulkAnnouncements() {
    return async (dispatch) => {
        try {
            dispatch(buildAction(BULK_ANNOUNCEMENTS_STATE, ACTION_STATE_LOADING));
            const announcements = await API.get(process.env.REACT_APP_API_NAME, `/announcements/group?bulk=true`);
            dispatch(buildAction(BULK_ANNOUNCEMENTS, transformAnnouncements(announcements, true)));
            dispatch(buildAction(BULK_ANNOUNCEMENTS_STATE, ACTION_STATE_SUCCESS));
        } catch(error) {
            console.error("Get Bulk Announcements Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(BULK_ANNOUNCEMENTS_STATE, ACTION_STATE_ERROR));
            });
        }
    };
}

export function resetBulkAnnouncements() {
    return async (dispatch) => {
        dispatch(buildAction(BULK_ANNOUNCEMENTS, []));
        dispatch(buildAction(BULK_ANNOUNCEMENTS_STATE, ""));
    };
}