import React from "react";
import { Icon } from "osu-react-components";
import { forEach, isEmpty, some } from "lodash";
import { 
    REVIEW_CONTENT, REVIEW_CONTENT_FILTER, REVIEW_CONTENT_STATE, 
    UPDATE_CONTENT_ITEM_STATUS
} from "../actions-index";
import { 
    ACTION_STATE_SUCCESS, 
    CONTENT_ITEM_STATUS_PENDING, CONTENT_ITEM_STATUS_REJECTED } from "../util/constants";
import { formatDateString } from "../util/util";

export function reviewContent(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case REVIEW_CONTENT:
            const hasPendingItems = some(payload, ["status", CONTENT_ITEM_STATUS_PENDING]);
            return { 
                data: formatReviewContent(payload),
                filter: hasPendingItems ? { status: CONTENT_ITEM_STATUS_PENDING } : {},
                state: !isEmpty(payload) ? ACTION_STATE_SUCCESS : state.state
            };
        case REVIEW_CONTENT_STATE:
            return { ...state, state: payload };
        case REVIEW_CONTENT_FILTER:
            const { field, value } = payload;
            const filter = {...state.filter};
            if(isEmpty(value)) {
                delete filter[field];
            } else {
                filter[field] = value;
            }
            return { ...state, filter };
        case UPDATE_CONTENT_ITEM_STATUS:
            if(state.data) {
                const { status } = payload;
                const itemId = payload.item.main.identifier;
                let data;
                if([CONTENT_ITEM_STATUS_PENDING, CONTENT_ITEM_STATUS_REJECTED].includes(status)) { // update item status
                    data = state.data.map(item => {
                        return (item.identifier === itemId) ? { ...item, status } : item;
                    });
                } else { // remove item
                    data = state.data.filter(item => (item.identifier !== itemId));
                }
                return { ...state, data };
            }
            return state;
        default:
            return state;
    }
}

function formatReviewContent(content) {
    forEach(content, item => {
        item.channels = item.channel.join(", ");
        item.dateAddedDisplay = formatDateString(item.dateAdded);
        item.publishStartDateDisplay = formatDateString(item.publishStartDate);
        item.flagDisplay = item.flag ? 
            (
                <span>
                    <Icon color="black" type="flag" ariaLabel="Updated Flag" />
                    <span className="sr-only">{item.flag}</span>
                </span>
            ) : 
            null;
    });
    return content;
}