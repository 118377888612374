import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Notification from "../components";
import { submitNotification, SUBMIT_NOTIFICATION_STATE } from "../../actions-index";
import { buildAction } from "../../util/util";

const mapStateToProps = (state) => {
    return {
        submitNotificationState: state.submitNotificationState
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetSubmitNotificationState: () => dispatch(buildAction(SUBMIT_NOTIFICATION_STATE, "")),
        submitNotification: (file, notification) => dispatch(submitNotification(file, notification))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notification));