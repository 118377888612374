import React from "react";
import PropTypes from "prop-types";

function Heading(props) {
    return (<h4 {...props}>{props.children}</h4>);
}

Heading.propTypes = {
    children: PropTypes.string.isRequired
}

export default Heading;