import React from "react";
import { Image } from "react-bootstrap";
import { Body1, Caption } from "osu-react-components";
import { forEach, isEmpty } from "lodash";
import { 
    APPROVED_CONTENT, APPROVED_CONTENT_FILTER, APPROVED_CONTENT_STATE,
    CONTENT_ITEM, CONTENT_ITEM_STATE,
    UPDATE_CONTENT_ITEM_STATUS, UPDATE_CONTENT_ITEM_STATUS_STATE
} from "../actions-index";
import { ACTION_STATE_SUCCESS, CONTENT_ITEM_STATUS_APPROVED, PROVIDER_MOBILE_APPS } from "../util/constants";
import { arrayToString, formatDateString } from "../util/util";

export function approvedContent(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case APPROVED_CONTENT:
            return { 
                data: formatApprovedContent(payload),
                filter: {},
                state: !isEmpty(payload) ? ACTION_STATE_SUCCESS : state.state
            };
        case APPROVED_CONTENT_STATE:
            return { ...state, state: payload };
        case APPROVED_CONTENT_FILTER:
            const { field, value } = payload;
            const filter = {...state.filter};
            if(isEmpty(value)) {
                delete filter[field];
            } else {
                filter[field] = value;
            }
            return { ...state, filter };
        case UPDATE_CONTENT_ITEM_STATUS:
            if(state.data) {
                const { status } = payload;
                const itemId = payload.item.main.identifier;
                if(status === CONTENT_ITEM_STATUS_APPROVED) { // add item
                    return {
                        ...state, 
                        data: [
                            ...state.data, 
                            formatApprovedContent([payload.item.main])[0]
                        ]
                    };
                } else { // remove item
                    return { 
                        ...state, 
                        data: state.data.filter(item => (item.identifier !== itemId))
                    };
                }
            }
            return state;
        default:
            return state;
    }
}

function formatApprovedContent(content) {
    forEach(content, item => {
        item.channelDisplay = arrayToString(item.channel);
        item.image = (item.thumbnailURL ? (<Image src={item.thumbnailURL} alt="Content Image" fluid rounded />) : "");
        item.publishStartDateDisplay = formatDateString(item.publishStartDate);
        item.publishEndDateDisplay = formatDateString(item.publishEndDate);
        if(item.provider === PROVIDER_MOBILE_APPS) {
            const platform = (item?.platform?.length ? item.platform.join(", ") : "");
            const appBuild = (item.appBuildStart ? 
                (item.appBuildEnd ?
                    `${item.appBuildStart}-${item.appBuildEnd}`:
                    item.appBuildStart
                ) :
                ""
            );
            item.title = (
                <div>
                    <Body1 color="black">{item.title}</Body1>
                    <Caption>Platform: {platform}</Caption>
                    <Caption>App Build: {appBuild}</Caption>
                </div>
            );
        }
    });
    return content;
}

export function contentItem(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case CONTENT_ITEM:
            return {
                data: payload,
                state: !isEmpty(payload) ? ACTION_STATE_SUCCESS : state.state
            };
        case CONTENT_ITEM_STATE:
            return { ...state, state: payload };
        case UPDATE_CONTENT_ITEM_STATUS:
            if(state.data) {
                return { 
                    ...state, 
                    data: {
                        ...state.data,
                        main: {
                            ...state.data.main,
                            status: action.payload.status
                        }
                    }
                };
            }
            return state;
        default:
            return state;
    }
}

export function updateContentItemStatusState(state = "", action = {}) {
    return (action.type === UPDATE_CONTENT_ITEM_STATUS_STATE) ? action.payload : state;
}