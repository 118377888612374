import React from "react";
import PropTypes from "prop-types";
import Body from "./Body";
import Heading from "./Heading";
import Container from "./Container";

function Section(props) {
    const { components, ...otherProps } = props;
    let sectionContent = null;
    if(props.components.length > 0) {
        sectionContent = props.components.map(component => {
            const { components = [], identifier, role } = component;
            switch(role) {
                case "body":
                    return (<Body key={identifier} text={component.text} />);
                case "heading":
                    return (<Heading key={identifier} data-testid={`heading-${identifier}`}>{component.text}</Heading>);
                case "container":
                    const { contentDisplay = {} } = component;
                    const { type: displayType = null } = contentDisplay;
                    return (<Container key={identifier} data-testid={`container-${identifier}`} components={components} displayType={displayType} />);
                default:
                    return null;
            }
        });
    }
    return (<section {...otherProps}>{sectionContent}</section>);
}

Section.defaultProps = {
    components: []
}

Section.propTypes = {
    components: PropTypes.arrayOf(PropTypes.shape({
        components: PropTypes.arrayOf(PropTypes.object),
        identifier: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired
    }))
}

export default Section;