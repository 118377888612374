import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { OSUButton } from "osu-react-components";
import { find } from "lodash";
import App from "./App";
import Body from "./Body";
import Gallery from "./Gallery";
import Heading from "./Heading";
import Map from "./Map";
import Photo from "./Photo";
import Section from "./Section";
import Table from "./Table";
import Video from "./Video";

function Article(props) {
    const ArticleContent = props.content.map(contentItem => {
        const { identifier, role } = contentItem;
        switch(role) {
            case "app":
                return (<App key={identifier} caption={contentItem.caption} iconImgAlt={contentItem.accessibilityCaption} iconImgSrc={contentItem.iconImage} title={contentItem.title} />);
            case "body":
                return (<Body key={identifier} text={contentItem.text} />);
            case "button":
            case "internalLinkCell":
                const { additions = [], text } = contentItem;
                const link = find(additions, ["type", "link"]);
                const url = (link ? link.URL : null);
                return (<OSUButton key={identifier} ariaLabel={text} url={url}>{text}</OSUButton>);
            case "byline":
                return (<Typography component="div" variant="overline">{contentItem.text}</Typography>);
            case "caption":
                return (<Typography component="div" variant="caption">{contentItem.text}</Typography>);
            case "divider":
                return (<hr />);
            case "embedwebvideo":
                return (<Video key={identifier} alt={contentItem.accessibilityCaption} caption={contentItem.caption} src={contentItem.URL} />);
            case "gallery":
                return (<Gallery items={contentItem.items} text={contentItem.text} />);
            case "heading":
                return (<Heading key={identifier} data-testid={`heading-${identifier}`}>{contentItem.text}</Heading>);
            case "map":
                const baseLocation = { caption: contentItem.caption, accessibilityCaption: contentItem.accessibilityCaption, lat: parseFloat(contentItem.latitude), lng: parseFloat(contentItem.longitude) };
                const itemLocations = contentItem.items.map(item => ({ caption: item.caption, lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }));
                const locations = [baseLocation].concat(itemLocations);
                return (<Map key={identifier} locations={locations} />);
            case "photo":
                return (<Photo key={identifier} alt={contentItem.accessibilityCaption} caption={contentItem.caption} src={contentItem.URL} />);
            case "section":
                return (<Section key={identifier} components={contentItem.components} className="mt-2" />);
            case "table":
                return (<Table key={identifier} components={contentItem.components} />);
            case null: // unstyled text
                return (<Typography component="div" variant="body1">{contentItem.text}</Typography>);
            default:
                return null;
        }
    });
    return (
        <article>
            <img data-testid="image" className="my-1 w-100" src={props.imgSrc} alt={props.imgAlt} />
            <h3 data-testid="title">{props.title}</h3>
            <p data-testid="subtitle" className="mb-4">{props.subtitle}</p>
            {ArticleContent}
        </article>
    );
}

Article.defaultProps = {
    imgAlt: "Article Image"
}

Article.propTypes = {
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Article;