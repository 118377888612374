import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Announcements from "../components/Announcements";
import { getGroupAnnouncements, resetAnnouncements, searchAnnouncements } from "../../actions-index";

const mapStateToProps = (state) => {
    const { data: announcements = [], state: announcementsState = "" } = state.announcements;
    return {
        announcements,
        announcementsState
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAnnouncements: (expired) => dispatch(getGroupAnnouncements(expired)),
        resetAnnouncements: () => dispatch(resetAnnouncements()),
        searchAnnouncements: (query, all) => dispatch(searchAnnouncements(query, all))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Announcements));