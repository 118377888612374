import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Icon, OSUButton } from "osu-react-components";
import { PROVIDER_MOBILE_APPS } from "../../util/constants";
import { arrayToString, formatDateString } from "../../util/util";
import { isEmpty } from "lodash";

function ArticleSection(props) {
    const { article, draggable, onMoveToTop, onRemove } = props;

    const publishStartDate = formatDateString(article.publishStartDate);
    const publishEndDate = formatDateString(article.publishEndDate);
    let publishedDates = publishStartDate;
    if(!isEmpty(publishEndDate)) publishedDates += ` - ${publishEndDate}`;
    const platforms = (article?.platform?.length ? article.platform.join(", ") : "");
    const appBuild = (article.appBuildStart ? 
        (article.appBuildEnd ?
            `${article.appBuildStart}-${article.appBuildEnd}`:
            article.appBuildStart
        ) :
        ""
    );
    
    return (
        <div className="pl-3">
            <div className="d-flex flex-nowrap">
                {draggable &&
                    <div className="flex-column ml-1 pt-1"><Icon type="align-justify" ariaLabel="Drag Content Item" /></div>
                }
                <div className="col-4 flex-column mx-1">
                    <div data-testid="title"><b>{article.title}</b></div>
                    <div data-testid="published-dates">Published: {publishedDates}</div>
                    {article.provider === PROVIDER_MOBILE_APPS &&
                        <Fragment>
                            <div>Platform(s): {platforms}</div>
                            <div>App Build: {appBuild}</div>
                        </Fragment>
                    }
                </div>
                <div className="col-4 flex-column">
                    <div data-testid="audience">Audience: {arrayToString(article.affiliation)}</div>
                    <div data-testid="campus">Campus: {arrayToString(article.campus)}</div>
                    <div data-testid="login-state">Login State: {article.loginState}</div>
                </div>
                <div className="flex-column float-right">
                    {onRemove &&
                        <OSUButton ariaLabel="Remove Content Item" className="mr-1" color="white" title="Remove" onClick={() => onRemove(article.identifier)}>
                            <Icon type="minus-circle" color="red" ariaLabel="Remove Content Item" />
                        </OSUButton>
                    }
                    {onMoveToTop &&
                        <OSUButton ariaLabel="Move Content Item to Top" color="white" title="Move to Top" onClick={() => onMoveToTop(article.identifier)}>
                            <Icon type="angle-double-up" color="blue" size="lg" ariaLabel="Move Content Item to Top" />
                        </OSUButton>
                    }
                </div>
            </div>
            <hr />
        </div>
    );
}

ArticleSection.defaultProps = {
    draggable: false
}

ArticleSection.propTypes = {
    article: PropTypes.shape({
        affiliation: PropTypes.arrayOf(PropTypes.string).isRequired,
        campus: PropTypes.arrayOf(PropTypes.string).isRequired,
        identifier: PropTypes.string.isRequired,
        loginState: PropTypes.string.isRequired,
        publishEndDate: PropTypes.string,
        publishStartDate: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    }).isRequired,
    draggable: PropTypes.bool,
    onRemove: PropTypes.func
}

export default ArticleSection;