import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Alert, Form, FormFeedback, FormGroup, FormText, Label, Input } from "reactstrap";
import { forOwn } from "lodash";
import { Caption, OSUButton, Subtitle2 } from "osu-react-components";
import { OSULoading } from "osu-react-components";
import { ACTION_STATE_ALREADY_EXISTS, ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, CHANNEL_COMPASS, CHANNELS } from "../../../util/constants";

export default function CreateChannel(props) {
    const { createChannel, createChannelState, resetChannelsState, resetCreateChannelState } = props;
    const history = useHistory();
    const alertsEl = useRef(null);
    const [name, setName] = useState(null);
    const [key, setKey] = useState(null);
    const [channels, setChannels] = useState([]);
    const INPUT_STYLE = { maxWidth: "43rem" };
    const INPUT_MAX_LENGTH = 75;
    const channelOptions = [];
    forOwn(CHANNELS, (value, key) => {
        channelOptions.push({ label: value, value: key });
    });
    const isCreatingChannel = (createChannelState === ACTION_STATE_LOADING);
    const channelAlreadyExists = (createChannelState === ACTION_STATE_ALREADY_EXISTS);
    const createChannelError = (createChannelState === ACTION_STATE_ERROR);
    const channelCreated = (createChannelState === ACTION_STATE_SUCCESS);
    const isSubmitDisabled = !(key && name && channels.length > 0);

    const trimValueToMaxLength = (value, maxLength) => {
        let trimmedValue = value;
        if(value && maxLength && value.length > maxLength) {
            trimmedValue = value.substring(0, maxLength);
        }
        return trimmedValue;
    }
    const onChannelChange = (channel, checked) => {
        if(channel === CHANNEL_COMPASS) { // Compass stands alone
            setChannels((checked === true ? [channel] : []));
        } else {
            const index = channels.indexOf(channel);
            if(checked === true) {
                if(index === -1) setChannels([...channels, channel]);
            } else {
                if(index !== -1) {
                    const updatedChannels = [...channels];
                    updatedChannels.splice(index, 1);
                    setChannels(updatedChannels);
                }
            }
        }
    };

    // when the create channel state changes, set focus on alerts
    // when the create channel state is success, reset fields and channels state
    useEffect(() => {
        if([channelAlreadyExists, channelCreated, createChannelError].includes(true)) {
            alertsEl.current.focus();
            if(channelCreated) {
                resetChannelsState();
                setKey(null);
                setName(null);
                setChannels([]);
            }
        }
    }, [channelAlreadyExists, channelCreated, createChannelError, resetChannelsState]);

    // when leaving the page, reset the create channel state
    useEffect(() => {
        return () => {
            resetCreateChannelState();
        };
    }, [resetCreateChannelState]);

    return (
        <div>
            <div className="d-flex mb-3">
                <Link data-testid="organizeLink" aria-label="Navigate to Organize" to="/organize"><Caption>Organize</Caption></Link>
                <Caption className="pl-1 pr-1">/</Caption>
                <Caption>Create Channel</Caption>
            </div>
            <h1 data-testid="heading">Create Channel</h1>
            {isCreatingChannel && <OSULoading dataTestId="creating-channel" text="Creating Channel..." />}
            <div data-testid="content" className={(isCreatingChannel ? "d-none" : "")}>
                <div data-testid="alerts" ref={alertsEl} tabIndex="-1">
                    <Alert data-testid="create-channel-error-alert" color="danger" isOpen={createChannelError} toggle={resetCreateChannelState}>
                        <Subtitle2>Create Channel Failure:</Subtitle2>
                        <p className="mb-0">An error occurred while creating the channel. Please retry to see if that resolves the issue.</p>
                    </Alert>
                    <Alert data-testid="create-channel-already-exists-alert" color="danger" isOpen={channelAlreadyExists} toggle={resetCreateChannelState}>
                        <Subtitle2>Create Channel Failure:</Subtitle2>
                        <p className="mb-0">A channel with this Name or Key already exists.</p>
                    </Alert>
                    <Alert data-testid="create-channel-success-alert" color="success" isOpen={channelCreated} toggle={resetCreateChannelState}>
                        <Subtitle2>Create Channel Success:</Subtitle2>
                        <p className="mb-0">The channel was successfully created.</p>
                    </Alert>
                </div>
                <Form className="mb-2">
                    <fieldset>
                        <legend className="sr-only">Channel</legend>
                        <FormGroup>
                            <Label for="name" className="mb-0 mt-1 required">Name</Label>
                            <Input type="text" id="name" name="name" value={(name || "")} style={INPUT_STYLE} maxLength={INPUT_MAX_LENGTH} aria-required={true}
                                aria-describedby="nameFeedback" invalid={(name !== null && name.length === 0)} onChange={(e) => setName(trimValueToMaxLength(e.target.value, INPUT_MAX_LENGTH))} />
                            <FormFeedback id="nameFeedback">Name is a required field</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="key" className="mb-0 mt-1 required">Key</Label>
                            <Input type="text" id="key" name="key" value={(key || "")} style={INPUT_STYLE} maxLength={INPUT_MAX_LENGTH} aria-required={true} 
                                aria-describedby="keyFormText keyFeedback" invalid={(key !== null && key.length === 0)}
                                onChange={(e) => {
                                    const value = e.target.value.replace(/[^a-zA-Z\d:]/g, ""); // replace non-alphanumeric characters
                                    setKey(trimValueToMaxLength(value, INPUT_MAX_LENGTH));
                                }} />
                            <FormText id="keyFormText">Supports only alphanumeric characters</FormText>
                            <FormFeedback id="keyFeedback">Key is a required field</FormFeedback>
                        </FormGroup>
                        <fieldset>
                            <legend style={{ fontWeight: "bold", fontSize: "1.1rem" }}>Select what content is available for this channel.</legend>
                            {channelOptions.map(({ label, value }) => {
                                const key = `channel-${value}`;
                                const disabled = (
                                    (value === CHANNEL_COMPASS && (channels.length > 0 && !channels.includes(CHANNEL_COMPASS))) || // Compass checkbox is disabled when another checkbox is selected
                                    (value !== CHANNEL_COMPASS && (channels.length > 0 && channels.includes(CHANNEL_COMPASS))) // other checkboxes are disabled when the Compass checkbox is selected
                                );
                                return (
                                    <FormGroup check key={key}>
                                        <Input id={key} name={key} type="checkbox" value={value} checked={channels.includes(value)}
                                            disabled={disabled} onChange={(e) => onChannelChange(value, e.target.checked)} />
                                        <Label for={key}>{label}</Label>
                                    </FormGroup>
                                );
                            })}
                        </fieldset>
                    </fieldset>
                </Form>
                <div className="d-inline">
                    <OSUButton ariaLabel="Cancel" color="gray" className="mr-1" onClick={() => history.push("/organize")}>Cancel</OSUButton>
                    <OSUButton ariaLabel="Submit" color="blue" disabled={isSubmitDisabled} onClick={() => createChannel(key, name, channels)}>Submit</OSUButton>
                </div>
            </div>
        </div>
    );
}