import React, { useEffect, useRef, useState, Fragment } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Alert, Col, Row } from "reactstrap";
import { OSUButton, OSUError, OSULoading } from "osu-react-components";
import { 
    ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS,
    ARTICLE_CONTENT, ARTICLE_MAIN,
    CONTENT_ITEM_STATUS_APPROVED, CONTENT_ITEM_STATUS_PENDING, CONTENT_ITEM_STATUS_REJECTED
} from "../../util/constants";
import ContentItem from "../../Common/components/ContentItem";
import styled from "styled-components";
import { isEmpty, merge, pick } from "lodash";

const Status = styled.p`
    font-size: 1.2rem;
    font-weight: bold;
`;
export default function ReviewContentItem(props) {
    const { data, getContentItem, match, resetUpdateContentItemStatusState: resetUpdateStatusState, state: contentItemState, 
        updateContentItemStatus, updateContentItemStatusState: updateStatusState } = props;
    const content = buildContent(data);
    const { status = "" } = content;
    const params = (match && match.params) ? match.params : {};
    const itemId = params.itemId || null;
    const history = useHistory();
    const { pathname } = useLocation();
    const alertsEl = useRef(null);
    const updatingStatus = (updateStatusState === ACTION_STATE_LOADING);
    const [redirectToHome, setRedirectToHome] = useState(false);
    
    useEffect(() => {
        getContentItem(itemId);
    }, [getContentItem, itemId]);
    useEffect(() => {
        if(updateStatusState === ACTION_STATE_ERROR) {
            alertsEl.current.focus();
        }
    }, [updateStatusState]);
    useEffect(() => {
        if(updateStatusState === ACTION_STATE_SUCCESS && status === CONTENT_ITEM_STATUS_APPROVED && redirectToHome === true) {
            history.push("/");
        }
    }, [history, redirectToHome, status, updateStatusState]);
    useEffect(() => {
        return () => { 
            resetUpdateStatusState();
        }
    }, [pathname, resetUpdateStatusState]);

    let flag = (content.flag) ? ` - ${content.flag}` : null;
    let FlaggedArticle = null;
    if(content.flaggedId) {
        const contentItemId = content.flaggedId.replace(ARTICLE_MAIN, ARTICLE_CONTENT);
        const FlaggedArticleLink = (<Link to={`/content/${contentItemId}`} target="_blank" rel="noopener noreferrer" data-testid="flagged-article-link" >view current article</Link>);
        FlaggedArticle = (<Fragment>({FlaggedArticleLink})</Fragment>);
    }

    let statusButton = null;
    switch(status) {
        case CONTENT_ITEM_STATUS_PENDING:
            statusButton = { ariaLabel: "Update the status of the Content Item to Rejected", label: "Reject", status: CONTENT_ITEM_STATUS_REJECTED };
            break;
        case CONTENT_ITEM_STATUS_REJECTED:
            statusButton = { ariaLabel: "Update the status of the Content Item to Pending", label: "Pending Review", status: CONTENT_ITEM_STATUS_PENDING };
            break;
        default:
    }

    return (
        <div>
            <div data-testid="alerts" ref={alertsEl} tabIndex="-1">
                {updateStatusState === ACTION_STATE_ERROR &&
                    <Alert data-testid="status-update-alert" color="danger" toggle={resetUpdateStatusState}>
                        <h3>Status Update Failure</h3>
                        <p className="mb-0">
                            Failed to update the status of the Content Item. Please retry to see if it resolves the issue.<br/>
                            In some cases refreshing the page may resolve the issue if the displayed status is not the current status.
                        </p>
                    </Alert>
                }
            </div>
            <h1 data-testid="heading">Review Content Item</h1>
            {contentItemState === ACTION_STATE_LOADING &&
                <OSULoading dataTestId="loading" text="Loading Content Item..." />
            }
            {contentItemState === ACTION_STATE_ERROR &&
                <OSUError dataTestId="error" text="Failed to retrieve content item." small="true" actionText="Retry" ariaLabel="Retry to retrieve content item" onClick={() => getContentItem(itemId)} />
            }
            {contentItemState === ACTION_STATE_SUCCESS &&
                <div>
                    {updatingStatus &&
                        <div>
                            <OSULoading dataTestId="processing" text="Processing Status Update..." />
                        </div>
                    }
                    <div data-testid="content" className={updatingStatus ? "d-none" : ""}>
                        <Status data-testid="status">Status: {status}{flag} {FlaggedArticle}</Status>
                        <ContentItem content={content} />
                        <Row className="mt-2">
                            <Col sm="2">
                                <OSUButton className="mb-1 mr-1" color="gray" ariaLabel="Go back to the Review page" onClick={() => history.push("/review")}>Back</OSUButton>
                            </Col>
                            <Col sm="10">
                                <div className="float-right">
                                    {statusButton &&
                                        <OSUButton className="mb-1 mr-1" color="blue" ariaLabel={statusButton.ariaLabel}
                                            onClick={() => updateContentItemStatus(data, statusButton.status)}>
                                                {statusButton.label}
                                        </OSUButton>
                                    }
                                    {status !== CONTENT_ITEM_STATUS_APPROVED &&
                                        <Fragment>
                                            <OSUButton className="mb-1 mr-1" color="blue" ariaLabel="Update the status of the Content Item to Approved"
                                                onClick={() => updateContentItemStatus(data, CONTENT_ITEM_STATUS_APPROVED)}>
                                                Approve &amp; Review
                                            </OSUButton>
                                            <OSUButton className="mb-1" color="blue" ariaLabel="Update the status of the Content Item to Approved and go home"
                                                onClick={() => { setRedirectToHome(true); updateContentItemStatus(data, CONTENT_ITEM_STATUS_APPROVED); }}>
                                                    Approve &amp; Go Home
                                            </OSUButton>
                                        </Fragment>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            }
        </div>
    );
}

function buildContent(data) {
    let content = {};
    if(!isEmpty(data)) {
        const { article = {}, main = {} } = data;
        content = merge({...main}, pick(article, ["subtitle", "metadata", "components"]));
    }
    return content;
}