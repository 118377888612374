import { ANNOUNCEMENT_AFFILIATION, NO, YES } from "../util/constants";

export const ACADEMIC_CAREER = {
    "UGRD": "Undergraduate", 
    "GRAD": "Graduate", 
    "DENT": "Dentistry", 
    "LAW": "Law", 
    "MED": "Medicine",
    "OPT": "Optometry",
    "VMED": "Veterinary Medicine",
    "PHP": "Pharmacy, Professional"
};

export const ACADEMIC_PROGRAM = {
    "DENT": "Dentistry",
    "GADM": "Grad Administration Sciences",
    "GAGR": "Graduate Agriculture",
    "GAHR": "Graduate Architecture",
    "GAMP": "Graduate Allied Med Profession",
    "GART": "Graduate Art",
    "GASC": "Graduate Arts And Sciences",
    "GBIO": "Graduate Biological Sciences",
    "GBRDG": "Graduate Bridge",
    "GBUS": "Graduate Business",
    "GDEN": "Graduate Dentistry",
    "GEDU": "Graduate Education",
    "GEHE": "Grad Education & Human Ecology",
    "GENG": "Graduate Engineering",
    "GENR": "Grad Environment & Natural Res",
    "GHEC": "Graduate Human Ecology",
    "GHRS": "Graduate Hlth&Rehabltn Sci",
    "GHUM": "Graduate Humanities",
    "GINT": "Interdisciplinary",
    "GJGS": "John Glenn Col, Public Affairs",
    "GJUR": "Graduate Journalism",
    "GLAW": "Graduate Law",
    "GMED": "Graduate Medical",
    "GMPS": "Graduate Math & Physical Sci.",
    "GMUS": "Graduate Music",
    "GNDG": "Graduate Non-Degree Program",
    "GNRE": "Graduate Natural Resources",
    "GNUR": "Graduate Nursing",
    "GOPT": "Graduate Optometry",
    "GPBH": "Graduate Public Health",
    "GPHR": "Graduate Pharmacy",
    "GSBS": "Graduate Social & Behavior Sci",
    "GSWK": "Graduate Social Work",
    "GVME": "Graduate Veterinary Medicine",
    "GVSTR": "Grad Visitor Continuing Ed Prg",
    "LAW": "Law School",
    "LAWCS": "Law Casual",
    "LAWND": "Law Non Degree Program",
    "MED": "Medicine",
    "OPT": "Optometry",
    "PHP": "Pharmacy Professions",
    "UACD": "Academy",
    "UADM": "Administration Sciences",
    "UAGR": "Agriculture",
    "UAGT": "Agricultural Technical Inst",
    "UAHR": "Architecture",
    "UAMP": "Allied Med Professions",
    "UART": "Art",
    "UASC": "Arts and Sciences",
    "UASCI": "UASCI",
    "UATI": "Agricultural Tech. Institute",
    "UBIO": "Biological Sciences",
    "UBSC": "Biological Sciences",
    "UBUS": "Business",
    "UCOM": "Commerce",
    "UDHY": "Dental Hygiene",
    "UDHYP": "Dental Hygiene Pre Program",
    "UEDP": "Education Professional",
    "UEDU": "Education",
    "UEHE": "Education and Human Ecology",
    "UENG": "Engineering",
    "UENP": "Engineering Professional",
    "UENR": "Environment & Natural Resource",
    "UEXP": "Exploration Program",
    "UHE": "Home Economics",
    "UHEC": "Human Ecology",
    "UHRS": "Health & Rehabilitation Sci",
    "UHRSP": "Hlth&Rhblttn Sci Pre Program",
    "UHUM": "Humanities",
    "UJGS": "John Glenn Col, Public Affairs",
    "UJUR": "Journalism",
    "UMED": "Undergraduate Medicine",
    "UMPS": "Math & Physical Sciences",
    "UMUS": "Music",
    "UNCRD": "Undergraduate Not for Credit",
    "UNDG": "Undergraduate Non-Degree",
    "UNMS": "UNMS",
    "UNRE": "Natural Resources",
    "UNUR": "Nursing",
    "UNURP": "Nursing Pre Program",
    "UPBH": "Public Health",
    "UPBHP": "Public Health Pre Program",
    "UPHR": "Pharmacy",
    "UPPP": "UPPP",
    "USBS": "Social & Behavioral Sciences",
    "USWK": "Social Work",
    "UUSS": "Ugrd Student Academic Services",
    "UUVC": "University College",
    "UVSTR": "Ugrd Visitor Continuing Ed Prg",
    "VME": "Veterinary Medicine"
};

export const AFFILIATION = {
    [ANNOUNCEMENT_AFFILIATION.FACULTY_AND_STAFF]: "Faculty and Staff",
    [ANNOUNCEMENT_AFFILIATION.STUDENTS]: "Students"
};

export const APPLICATION = {
    "COMPASS": "Compass",
    "MOBILE": "Mobile",
    "PORTAL": "Student Portal"
};

export const AUTHENTICATED = {
    [NO]: "Unauthenticated (General Audience)",
    [YES]: "Authentication Required"
};

export const CAMPUS = [
    "Columbus",
    "Mansfield",
    "Marion",
    "Newark",
    "Lima",
    "Wooster"
];

export const COLLEGE = [
    "Academic_Affairs_CCH6",
    "Administration_and_Planning_CCH6",
    "Arts_and_Sciences_CCH6",
    "Athletics_and_Business_Advancement_CCH6",
    "Business_CCH6",
    "Dentistry_CCH6",
    "Education_and_Human_Ecology_CCH6",
    "Engineering_CCH6",
    "Enterprise_for_Research,_Innovation,_and_Knowledge_CCH6",
    "Food,_Agricultural,_and_Environmental_Sciences_CCH6",
    "Health_Sciences_CCH6",
    "Health_System_|_Ambulatory_Services_CCH6",
    "Health_System_|_James_Cancer_Hospital_CCH6",
    "Health_System_|_OSU_Harding_Hospital_CCH6",
    "Health_System_|_Ross_Heart_Hospital_CCH6",
    "Health_System_|_Shared_Services_CCH6",
    "Health_System_|_University_Hospital_CCH6",
    "Health_System_|_University_Hospital_East_CCH6",
    "John_Glenn_Public_Affairs_CCH6",
    "Law_CCH6",
    "Marketing_and_Communications_CCH6",
    "Medicine_CCH6",
    "Newark_CCH6",
    "OSU_Physicians_CCH6",
    "Public_Health_CCH6",
    "Social_Work_CCH6",
    "Student_Life_CCH6",
    "Veterinary_Medicine_CCH6"
];