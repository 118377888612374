import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import { Icon, OSUError, OSULoading, PaginationWrapper, Subtitle2, Table } from "osu-react-components";
import { formatDateString, formatText } from "../../util/sort-formatters.js";
import { chunk, compact, find } from "lodash";
import { AppContext } from "../../App/components";
import { 
  ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS,
  ARTICLE_MAIN, ARTICLE_CONTENT
} from "../../util/constants";
import "../styles/index.css";

export default function Content(props) {
  const { data: content, filter, filterOptions, filterApprovedContent, getApprovedContent, state: contentState } = props;
  const history = useHistory();

  if(contentState === "") getApprovedContent();

  const dataKeys = [
    { label: (<Subtitle2 className="sr-only">Content Image</Subtitle2>), key: "image" },
    { label: "Title", key: "title", sortFormatter: formatText, className: "white-space-pre-wrap" },
    { label: "Channel(s)", key: "channelDisplay" },
    { label: "Publish Start", key: "publishStartDateDisplay", sortFormatter: formatDateString },
    { label: "Publish End", key: "publishEndDateDisplay", sortFormatter: formatDateString }
  ];

  const sortKeys = compact(dataKeys.map(dataKey => {
    return !["channelDisplay", "image"].includes(dataKey.key) ? dataKey.key : null;
  }));

  const { screenSize } = useContext(AppContext);
  let defaultSortColumn;
  if(screenSize < 482) {
    dataKeys.shift();
    dataKeys.splice(1, 1);
    dataKeys.pop();
    dataKeys[0].width = 60;
    dataKeys[1].width = 40;
    defaultSortColumn = 0;
  } else if(screenSize < 768) {
    dataKeys.shift();
    dataKeys[0].width = 29;
    dataKeys[1].width = 25;
    dataKeys[2].width = 23;
    dataKeys[3].width = 23;
    defaultSortColumn = 0;
  } else {
    dataKeys[0].width = 12;
    dataKeys[1].width = 34;
    dataKeys[2].width = 22;
    dataKeys[3].width = 16;
    dataKeys[4].width = 16;
    defaultSortColumn = 1;
  }

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const updateRowsPerPage = (value) => {
    setRowsPerPage(value);
  };

  const [dataIndex, setDataIndex] = useState(0);
  const updateDataIndex = (value) => {
    setDataIndex(value);
  };

  const selectedChannel = filter.channel ? find(filterOptions.channels, ["value", filter.channel]) : null;
  const selectedProvider = filter.provider ? find(filterOptions.providers, ["value", filter.provider]) : null;
  const selectedLoginState = filter.loginState ? find(filterOptions.loginStates, ["value", filter.loginState]) : null;

  const data = content.map(item => {
    item.onRowClick = () => {
      const itemId = item.identifier.replace(ARTICLE_MAIN, ARTICLE_CONTENT);
      history.push(`/content/${itemId}`);
    };
    return item;
  });
  const chunkedData = data.length > 0 ? chunk(data, rowsPerPage) : [{}];

  return (
    <div>
      <h1 data-testid="heading">All Content</h1>
      {contentState === ACTION_STATE_LOADING &&
        <OSULoading dataTestId="loading" text="Loading Content..." />
      }
      {contentState === ACTION_STATE_ERROR &&
        <OSUError dataTestId="error" text="Failed to retrieve content." small="true" actionText="Retry" ariaLabel="Retry to retrieve content" onClick={() => getApprovedContent()} />
      }
      {contentState === ACTION_STATE_SUCCESS &&
        <div>
          <Row className="pt-2">
            <Col>
              <InputGroup data-testid="searchInput">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><Icon type="search" color="gray" /></InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" aria-label="Filter by Title" placeholder="Filter by Title" defaultValue={filter.title || ""}
                    onChange={(e) => filterApprovedContent("title", e.target.value)} />
              </InputGroup>
            </Col>
          </Row>
          <Row className="pt-1 pb-1 d-flex flex-wrap">
          <Col sm="4" className="flex-column pb-1">
              <form data-testid="channelFilterForm">
                <Select name="channelFilterSelect" aria-label="Filter by Channel" placeholder="Channel" isClearable={true} 
                  options={filterOptions.channels} value={selectedChannel}
                  onChange={(option) => filterApprovedContent("channel", option ? option.value : null)} />
              </form>
            </Col>
            <Col sm="4" className="flex-column pb-1">
              <form data-testid="providerFilterForm">
                <Select name="providerFilterSelect" aria-label="Filter by Provider" placeholder="Provider" isClearable={true} 
                  options={filterOptions.providers} value={selectedProvider}
                  onChange={(option) => filterApprovedContent("provider", option ? option.value : null)} />
              </form>
            </Col>
            <Col sm="4" className="flex-column pb-1">
              <form data-testid="loginStateFilterForm">
                <Select name="loginStateFilterSelect" aria-label="Filter by Login State" placeholder="Login State" isClearable={true} 
                  options={filterOptions.loginStates} value={selectedLoginState}
                  onChange={(option) => filterApprovedContent("loginState", option ? option.value : null)} />
              </form>
            </Col>
          </Row>
          <PaginationWrapper
            persist
            totalPageCount={chunkedData.length}
            updateDataIndex={updateDataIndex}
            updateRowsPerPage={updateRowsPerPage}
            dataIndex={dataIndex}
            rowsPerPageOptions={[10, 20, 30]}
            rowsPerPage={rowsPerPage}
            resultsData={{ shownResults: data.length, totalResults: data.length }}
            showOptionalCount={true}
          >
            <Table 
              sortable 
              headerVariant="subtitle2" 
              rowHeight="2" 
              dataKeys={dataKeys} 
              data={data} 
              noDataMessage="No Content" 
              paginationData={{ rowsPerPage, dataIndex }}
              sortKeys={sortKeys}
              defaultSortColumn={defaultSortColumn}
            />
          </PaginationWrapper>
        </div>
      }
    </div>
  );
}