import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Col, Modal, ModalBody, ModalHeader, ModalFooter, Row } from "reactstrap";
import { OSUButton, OSUError, OSULoading } from "osu-react-components";
import { AppContext } from "../../App/components";
import { 
    ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS,
    CONTENT_ITEM_STATUS_ARCHIVED
} from "../../util/constants";
import ContentItem from "../../Common/components/ContentItem";
import { isEmpty, merge, pick } from "lodash";

export default function ViewContentItem(props) {
    const { data, getContentItem, match, resetUpdateContentItemStatusState: resetUpdateStatusState, state: contentItemState, 
        updateContentItemStatus, updateContentItemStatusState: updateStatusState } = props;
    const { user } = useContext(AppContext);
    const { isAdmin, isApprover } = user;
    const content = buildContent(data);
    const { status = "" } = content;
    const params = (match && match.params) ? match.params : {};
    const itemId = params.itemId || null;
    const history = useHistory();
    const { pathname } = useLocation();
    const alertsEl = useRef(null);
    const updatingStatus = (updateStatusState === ACTION_STATE_LOADING);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    
    useEffect(() => {
        getContentItem(itemId);
    }, [getContentItem, itemId]);
    useEffect(() => {
        if(updateStatusState === ACTION_STATE_ERROR) {
            alertsEl.current.focus();
        }
    }, [updateStatusState]);
    useEffect(() => {
        return () => {
            resetUpdateStatusState();
        };
    }, [pathname, resetUpdateStatusState]);

    return (
        <div className="mb-2">
            <div data-testid="alerts" ref={alertsEl} tabIndex="-1">
                {updateStatusState === ACTION_STATE_ERROR &&
                    <Alert data-testid="status-update-alert" color="danger" toggle={resetUpdateStatusState}>
                        <h3>Status Update Failure</h3>
                        <p className="mb-0">
                            Failed to update the status of the Content Item. Please retry to see if it resolves the issue.<br/>
                            In some cases refreshing the page may resolve the issue if the displayed status is not the current status.
                        </p>
                    </Alert>
                }
            </div>
            <h1 data-testid="heading">View Your Content Item</h1>
            {contentItemState === ACTION_STATE_LOADING &&
                <OSULoading dataTestId="loading" text="Loading Content Item..." />
            }
            {contentItemState === ACTION_STATE_ERROR &&
                <OSUError dataTestId="error" text="Failed to retrieve content item." small="true" actionText="Retry" ariaLabel="Retry to retrieve content item" onClick={() => getContentItem(itemId)} />
            }
            {contentItemState === ACTION_STATE_SUCCESS &&
                <div>
                    {updatingStatus &&
                        <OSULoading dataTestId="processing" text="Processing Status Update..." />
                    }
                    <div data-testid="content" className={updatingStatus ? "d-none" : ""}>
                        <ContentItem content={content} />
                        <Row className="mt-2">
                            <Col>
                                <OSUButton color="gray" ariaLabel="Go back to the Content page" onClick={() => history.push("/content")}>Back</OSUButton>
                            </Col>
                            <Col>
                                {(isAdmin || isApprover) && status !== CONTENT_ITEM_STATUS_ARCHIVED &&
                                    <OSUButton className="float-right" color="blue" ariaLabel="Update the status of the Content Item to Archived"
                                        onClick={() => setIsArchiveModalOpen(true)}>Archive Article</OSUButton>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            }
            <Modal data-testid="archive-modal" isOpen={isArchiveModalOpen}>
                <ModalHeader>Archive Article Confirmation</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to archive the article?</p>
                    <p>The article will be removed from all channels and sections.</p>
                </ModalBody>
                <ModalFooter>
                    <OSUButton ariaLabel="Cancel, do not archive the article" color="gray" 
                        onClick={() => setIsArchiveModalOpen(false)}>Cancel</OSUButton>
                    <OSUButton ariaLabel="Yes, archive the article" color="blue" 
                        onClick={() => {
                            updateContentItemStatus(data, CONTENT_ITEM_STATUS_ARCHIVED);
                            setIsArchiveModalOpen(false);
                        }}>Yes - Archive</OSUButton>
                </ModalFooter>
            </Modal>
        </div>
    );
}

function buildContent(data) {
    let content = {};
    if(!isEmpty(data)) {
        const { article = {}, main = {} } = data;
        content = merge({...main}, pick(article, ["subtitle", "metadata", "components"]));
    }
    return content;
}