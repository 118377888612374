import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import { isEmpty } from "lodash";
import { Col, Row } from "reactstrap";
import "../styles/video.css";

function Video(props) {
    const { alt, caption, src } = props;
    const VideoPlayer = (<ReactPlayer url={src} aria-labelledby="video-label" className="video-player" data-testid="video-player" width="100%" height="100%" />);
    let Video = (
        <div data-testid="video-player-wrapper" className="video-player-wrapper">
            {VideoPlayer}
        </div>
    );
    if(!isEmpty(caption)) {
        Video = (
            <figure data-testid="video-fig">
                {Video}
                <figcaption data-testid="video-figcaption">{caption}</figcaption>
            </figure>
        );
    }
    return (
        <Row className="d-flex justify-content-center mt-2">
            <Col sm="12" md="10" lg="8" xl="6">
                <span id="video-label" data-testid="video-label" className="sr-only">{alt}</span>
                {Video}
            </Col>
        </Row>
    );
}

Video.propTypes = {
    alt: PropTypes.string.isRequired,
    caption: PropTypes.string,
    src: PropTypes.string.isRequired
}

export default Video;