import { API } from "aws-amplify";
import {
    ADD_CHANNEL_SECTION_ARTICLE_ID,
    ADD_CHANNEL_SECTION_ARTICLE_STATE,
    AVAILABLE_CONTENT, AVAILABLE_CONTENT_FILTER, AVAILABLE_CONTENT_STATE,
    CHANNELS, CHANNELS_STATE,
    CHANNEL_NOT_FOUND,
    CHANNEL_SECTION, CHANNEL_SECTION_STATE, CHANNEL_SECTION_TITLE,
    CHANNEL_SECTIONS, CHANNEL_SECTIONS_STATE,
    CREATE_CHANNEL_STATE,
    DELETE_CHANNEL_STATE,
    DELETE_CHANNEL_SECTION_STATE,
    REMOVE_CHANNEL_SECTION_ARTICLE_STATE,
    SELECTED_CHANNEL,
    UPDATE_ARTICLES_PRIORITY_STATE,
    UPDATE_CHANNEL_SECTION_STATE,
    UPDATE_CHANNEL_SECTIONS_PRIORITY_STATE
} from "../actions-index";
import { ACTION_STATE_ALREADY_EXISTS, ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS } from "../util/constants";
import { buildAction } from "../util/util";
import { handleUnauthenticated } from "../Authentication/util";

export function getChannels() {
    return dispatch => {
        dispatch(buildAction(CHANNELS_STATE, ACTION_STATE_LOADING));
        API.get(process.env.REACT_APP_API_NAME, "/channels")
        .then(response => {
            const channels = ((response.data && response.data.channels) ? response.data.channels : []);
            dispatch(buildAction(CHANNELS, channels));
            dispatch(buildAction(CHANNELS_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Get Channels Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(CHANNELS_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function getAvailableContent(channel) {
    return dispatch => {
        dispatch(buildAction(AVAILABLE_CONTENT_STATE, ACTION_STATE_LOADING));
        API.get(process.env.REACT_APP_API_NAME, `/content/channel/${channel}/available-articles`)
        .then(response => {
            const availableContent = (response.data && response.data.articles) ? response.data.articles : [];
            dispatch(buildAction(AVAILABLE_CONTENT, availableContent));
            dispatch(buildAction(AVAILABLE_CONTENT_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Get Available Content Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(AVAILABLE_CONTENT_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function filterAvailableContent(field, value) {
    return dispatch => dispatch(buildAction(AVAILABLE_CONTENT_FILTER, { field, value }));
}

export function resetGetAvailableContentState() {
    return dispatch => {
        dispatch(buildAction(AVAILABLE_CONTENT_STATE, ""));
    }
}

export function addChannelSectionArticle(channel, sectionId, articleId) {
    return dispatch => {
        dispatch(buildAction(ADD_CHANNEL_SECTION_ARTICLE_ID, articleId));
        dispatch(buildAction(ADD_CHANNEL_SECTION_ARTICLE_STATE, ACTION_STATE_LOADING));
        API.post(process.env.REACT_APP_API_NAME, `/content/channel/${channel}/section/${sectionId}/add-article`, 
            { body: { articleId }})
        .then(() => {
            dispatch(buildAction(ADD_CHANNEL_SECTION_ARTICLE_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Add Channel Section Article Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(ADD_CHANNEL_SECTION_ARTICLE_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function resetAddChannelSectionArticleState() {
    return dispatch => {
        dispatch(buildAction(ADD_CHANNEL_SECTION_ARTICLE_STATE, ""));
        dispatch(buildAction(ADD_CHANNEL_SECTION_ARTICLE_ID, null));
    }
}

export function removeChannelSectionArticle(channel, sectionId, articleId) {
    return dispatch => {
        dispatch(buildAction(REMOVE_CHANNEL_SECTION_ARTICLE_STATE, ACTION_STATE_LOADING));
        API.post(process.env.REACT_APP_API_NAME, `/content/channel/${channel}/section/${sectionId}/remove-article`, 
            { body: { articleId }})
        .then(() => {
            dispatch(buildAction(REMOVE_CHANNEL_SECTION_ARTICLE_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Remove Channel Section Article Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(REMOVE_CHANNEL_SECTION_ARTICLE_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function resetRemoveChannelSectionArticleState() {
    return dispatch => {
        dispatch(buildAction(REMOVE_CHANNEL_SECTION_ARTICLE_STATE, ""));
    }
}

export function setSelectedChannel(channel) {
    return dispatch => dispatch(buildAction(SELECTED_CHANNEL, channel));
}

export function createChannel(key, name, channels) {
    return dispatch => {
        dispatch(buildAction(CREATE_CHANNEL_STATE, ACTION_STATE_LOADING));
        API.post(process.env.REACT_APP_API_NAME, `/content/create-channel`, { body: { key, name, channels } })
        .then(() => {
            dispatch(buildAction(CREATE_CHANNEL_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Create Channel Error: ", error);
            handleUnauthenticated(error, () => {
                const status = ((error.response && error.response.status) ? error.response.status : null);
                if(status === 403) {
                    dispatch(buildAction(CREATE_CHANNEL_STATE, ACTION_STATE_ALREADY_EXISTS));
                } else {
                    dispatch(buildAction(CREATE_CHANNEL_STATE, ACTION_STATE_ERROR));
                }
            });
        });
    };
}

export function deleteChannel(key) {
    return dispatch => {
        dispatch(buildAction(DELETE_CHANNEL_STATE, ACTION_STATE_LOADING));
        API.post(process.env.REACT_APP_API_NAME, `/content/channel/${key}/delete`, {})
        .then(() => {
            dispatch(buildAction(DELETE_CHANNEL_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Delete Channel Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(DELETE_CHANNEL_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function createChannelSection(channel, title) {
    return dispatch => {
        dispatch(buildAction(CHANNEL_SECTION_STATE, ACTION_STATE_LOADING));
        dispatch(buildAction(CHANNEL_NOT_FOUND, false));
        API.post(process.env.REACT_APP_API_NAME, `/content/channel/${channel}/create-section`, 
            { body: { title }})
        .then(response => {
            const channelSection = (response.data && response.data.section) ? response.data.section : {};
            dispatch(buildAction(CHANNEL_SECTION, channelSection));
            dispatch(buildAction(CHANNEL_SECTION_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Create Channel Section Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(CHANNEL_SECTION_STATE, ACTION_STATE_ERROR));
                const status = (error.response && error.response.status) ? error.response.status : null;
                dispatch(buildAction(CHANNEL_NOT_FOUND, status === 404));
            });
        });
    };
}

export function getChannelSection(channel, sectionId) {
    return dispatch => {
        dispatch(buildAction(CHANNEL_SECTION_STATE, ACTION_STATE_LOADING));
        dispatch(buildAction(CHANNEL_NOT_FOUND, false));
        API.get(process.env.REACT_APP_API_NAME, `/content/channel/${channel}/section/${sectionId}`)
        .then(response => {
            const channelSection = (response.data && response.data.section) ? response.data.section : {};
            dispatch(buildAction(CHANNEL_SECTION, channelSection));
            dispatch(buildAction(CHANNEL_SECTION_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Get Channel Section Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(CHANNEL_SECTION_STATE, ACTION_STATE_ERROR));
                const status = (error.response && error.response.status) ? error.response.status : null;
                dispatch(buildAction(CHANNEL_NOT_FOUND, status === 404));
            });
        });
    };
}

export function resetChannelSectionState() {
    return dispatch => {
        dispatch(buildAction(CHANNEL_SECTION_STATE, ""));
        dispatch(buildAction(CHANNEL_NOT_FOUND, false));
    };
}

export function updateChannelSection(channel, sectionId, currentTitle, newTitle) {
    return dispatch => {
        dispatch(buildAction(UPDATE_CHANNEL_SECTION_STATE, ACTION_STATE_LOADING));
        API.post(process.env.REACT_APP_API_NAME, `/content/channel/${channel}/section/${sectionId}/update-title`, 
            { body: { title: newTitle, previousTitle: currentTitle }})
        .then(response => {
            dispatch(buildAction(UPDATE_CHANNEL_SECTION_STATE, ACTION_STATE_SUCCESS));
            const channelSection = (response.data && response.data.section) ? response.data.section : {};
            dispatch(buildAction(CHANNEL_SECTION_TITLE, channelSection.title));
        })
        .catch(error => {
            console.error("Update Channel Section Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(UPDATE_CHANNEL_SECTION_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function resetUpdateChannelSectionState() {
    return dispatch => dispatch(buildAction(UPDATE_CHANNEL_SECTION_STATE, ""));
}

export function deleteChannelSection(channel, sectionId, title) {
    return dispatch => {
        dispatch(buildAction(DELETE_CHANNEL_SECTION_STATE, ACTION_STATE_LOADING));
        API.post(process.env.REACT_APP_API_NAME, `/content/channel/${channel}/section/${sectionId}/delete`, 
            { body: { title }})
        .then(() => {
            dispatch(buildAction(DELETE_CHANNEL_SECTION_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Delete Channel Section Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(DELETE_CHANNEL_SECTION_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function resetDeleteChannelSectionState() {
    return dispatch => dispatch(buildAction(DELETE_CHANNEL_SECTION_STATE, ""));
}

export function getChannelSections(channel) {
    return dispatch => {
        dispatch(buildAction(CHANNEL_SECTIONS_STATE, ACTION_STATE_LOADING));
        API.get(process.env.REACT_APP_API_NAME, `/content/channel/${channel}`)
        .then(response => {
            const channelSections = (response.data && response.data.sections) ? response.data.sections : [];
            dispatch(buildAction(CHANNEL_SECTIONS, channelSections));
            dispatch(buildAction(CHANNEL_SECTIONS_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Get Channel Sections Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(CHANNEL_SECTIONS_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function updateChannelSectionsPriority(channel, sections) {
    return dispatch => {
        dispatch(buildAction(UPDATE_CHANNEL_SECTIONS_PRIORITY_STATE, ACTION_STATE_LOADING));
        API.post(process.env.REACT_APP_API_NAME, `/content/channel/${channel}/update-sections-priority`, 
            { body: { sections } })
        .then(() => {
            dispatch(buildAction(UPDATE_CHANNEL_SECTIONS_PRIORITY_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Update Channel Sections Priority Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(UPDATE_CHANNEL_SECTIONS_PRIORITY_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function resetUpdateChannelSectionsPriorityState() {
    return dispatch => dispatch(buildAction(UPDATE_CHANNEL_SECTIONS_PRIORITY_STATE, ""));
}

export function updateArticlesPriority(channel, sectionId, articles) {
    return dispatch => {
        dispatch(buildAction(UPDATE_ARTICLES_PRIORITY_STATE, ACTION_STATE_LOADING));
        API.post(process.env.REACT_APP_API_NAME, `/content/channel/${channel}/section/${sectionId}/update-articles-priority`, 
            { body: { articles } })
        .then(() => {
            dispatch(buildAction(UPDATE_ARTICLES_PRIORITY_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Update Articles Priority Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(UPDATE_ARTICLES_PRIORITY_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function resetUpdateArticlesPriorityState() {
    return dispatch => dispatch(buildAction(UPDATE_ARTICLES_PRIORITY_STATE, ""));
}