import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import { Icon, OSUError, OSULoading, PaginationWrapper, Subtitle2, Table } from "osu-react-components";
import { AppContext } from "../../App/components";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS, ARTICLE_MAIN, ARTICLE_CONTENT } from "../../util/constants";
import { formatDateString, formatText } from "../../util/sort-formatters.js";
import { chunk, find, map } from "lodash";
import "../styles/index.css";

export default function Review(props) {
  const { data: content, filter, filterOptions, filterReviewContent, getReviewContent, state: contentState } = props;
  const history = useHistory();

  if(contentState === "") getReviewContent();

  const dataKeys = [
    { label: "Title", key: "title", sortFormatter: formatText },
    { label: "Provider", key: "provider", sortFormatter: formatText },
    { label: "Date Added", key: "dateAddedDisplay", sortFormatter: formatDateString },
    { label: "Publish Start", key: "publishStartDateDisplay", sortFormatter: formatDateString },
    { label: "Status", key: "status", sortFormatter: formatText },
    { label: "Channel(s)", key: "channels" },
    { label: (<Subtitle2 className="sr-only">Updated Flag</Subtitle2>), key: "flagDisplay" }
  ];
  const sortKeys = map(dataKeys, dataKey => {
    if(!["channels", "flagDisplay"].includes(dataKey.key)) return dataKey.key;
  });

  const { screenSize } = useContext(AppContext);
  if(screenSize < 492) {
    dataKeys.splice(4, 2);
    dataKeys.splice(1, 2);
    dataKeys[0].width = 54;
    dataKeys[1].width = 42;
    dataKeys[2].width = 4;
  } else if(screenSize < 768) {
    dataKeys.splice(5, 1);
    dataKeys.splice(2, 1);
    dataKeys[0].width = 28;
    dataKeys[1].width = 24;
    dataKeys[2].width = 24;
    dataKeys[3].width = 20;
    dataKeys[4].width = 4;
  } else if(screenSize < 992) {
    dataKeys.splice(2, 1);
    dataKeys[0].width = 20;
    dataKeys[1].width = 20;
    dataKeys[2].width = 18;
    dataKeys[3].width = 19;
    dataKeys[4].width = 19;
    dataKeys[5].width = 4;
  } else {
    dataKeys[0].width = 18;
    dataKeys[1].width = 16;
    dataKeys[2].width = 16;
    dataKeys[3].width = 16;
    dataKeys[4].width = 16;
    dataKeys[5].width = 14;
    dataKeys[6].width = 4;
  }

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const updateRowsPerPage = (value) => {
    setRowsPerPage(value);
  };

  const [dataIndex, setDataIndex] = useState(0);
  const updateDataIndex = (value) => {
    setDataIndex(value);
  };

  const selectedProvider = filter.provider ? find(filterOptions.providers, ["value", filter.provider]) : null;
  const selectedStatus = filter.status ? find(filterOptions.statuses, ["value", filter.status]) : null;
  const selectedChannel = filter.channel ? find(filterOptions.channels, ["value", filter.channel]) : null;

  const data = content.map(item => {
    item.onRowClick = () => {
      const itemId = item.identifier.replace(ARTICLE_MAIN, ARTICLE_CONTENT);
      history.push(`/review/content/${itemId}`);
    };
    return item;
  });
  const chunkedData = data.length > 0 ? chunk(data, rowsPerPage) : [{}];

  return (
    <div>
      <Link data-testid="getProviderContentLink" to="/provider/get-content">Get Provider Content</Link>
      <h1 data-testid="heading" className="mt-3">Content to Review</h1>
      {contentState === ACTION_STATE_LOADING &&
        <OSULoading dataTestId="loading" text="Loading Content..." />
      }
      {contentState === ACTION_STATE_ERROR &&
        <OSUError dataTestId="error" text="Failed to retrieve content." small="true" actionText="Retry" ariaLabel="Retry to retrieve content" onClick={() => getReviewContent()} />
      }
      {contentState === ACTION_STATE_SUCCESS &&
        <div>
          <Row className="pt-2">
            <Col>
              <InputGroup data-testid="searchInput">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><Icon type="search" color="gray" /></InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" aria-label="Filter by Title" placeholder="Filter by Title" defaultValue={filter.title || ""}
                    onChange={(e) => filterReviewContent("title", e.target.value)} />
              </InputGroup>
            </Col>
          </Row>
          <Row className="pt-1 pb-1 d-flex flex-wrap">
            <Col sm="4" className="flex-column pb-1">
              <form data-testid="providerFilterForm">
                <Select name="providerFilterSelect" aria-label="Filter by Provider" placeholder="Provider" isClearable={true} 
                  options={filterOptions.providers} value={selectedProvider}
                  onChange={(option) => filterReviewContent("provider", option ? option.value : null)} />
              </form>
            </Col>
            <Col sm="4" className="flex-column pb-1">
              <form data-testid="statusFilterForm">
                <Select name="statusFilterSelect" aria-label="Filter by Status" placeholder="Status" isClearable={true} options={filterOptions.statuses} value={selectedStatus}
                  onChange={(option) => filterReviewContent("status", option ? option.value : null)} />
              </form>
            </Col>
            <Col sm="4" className="flex-column pb-1">
              <form data-testid="channelFilterForm">
                <Select name="channelFilterSelect" aria-label="Filter by Channel" placeholder="Channel" isClearable={true} options={filterOptions.channels} value={selectedChannel}
                  onChange={(option) => filterReviewContent("channel", option ? option.value : null)} />
              </form>
            </Col>
          </Row>
          <PaginationWrapper
            persist
            totalPageCount={chunkedData.length}
            updateDataIndex={updateDataIndex}
            updateRowsPerPage={updateRowsPerPage}
            dataIndex={dataIndex}
            rowsPerPageOptions={[10, 20, 30]}
            rowsPerPage={rowsPerPage}
            resultsData={{ shownResults: data.length, totalResults: data.length }}
            showOptionalCount={true}
          >
            <Table 
              sortable 
              headerVariant="subtitle2" 
              rowHeight="2" 
              dataKeys={dataKeys} 
              data={data} 
              noDataMessage="No Content to Review" 
              paginationData={{ rowsPerPage, dataIndex }}
              sortKeys={sortKeys}
              defaultSortColumn={0}
            />
          </PaginationWrapper>
        </div>
      }
    </div>
  );
}