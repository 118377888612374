import { Auth } from "aws-amplify";

const apiConfig = {
  "aws_user_pools": "enable",
  "aws_user_pools_mfa_type": "OFF",
  "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  "customAuthStorage": {
    "domain": process.env.REACT_APP_CUSTOM_AUTH_STORAGE_DOMAIN,
    "path": "/",
    "expires": 1,
    "secure": process.env.REACT_APP_CUSTOM_AUTH_STORAGE_SECURE === "true" ? true : false
  },
  "oauth": {
    "idp": process.env.REACT_APP_SAML_PROVIDER,
    "domain" : process.env.REACT_APP_OAUTH_DOMAIN,
    "scope" : ["aws.cognito.signin.user.admin", "openid", "profile"], 
    "redirectSignIn" : process.env.REACT_APP_SIGNIN, 
    "redirectSignOut": process.env.REACT_APP_SIGNOUT,
    "responseType": "code"
  },
  "API": {
    "endpoints": [
      {
        "name": process.env.REACT_APP_API_NAME,
        "endpoint": process.env.REACT_APP_API_URL,
        "region": "us-east-2",
        "custom_header": async () => { 
          return { Authorization: (await Auth.currentSession()).idToken.jwtToken } 
        }
      },
      {
        "name": process.env.REACT_APP_NOTIFICATION_API_NAME,
        "endpoint": process.env.REACT_APP_NOTIFICATION_API_URL,
        "region": process.env.REACT_APP_NOTIFICATION_API_REGION,
        "custom_header": async () => { 
          return { Authorization: (await Auth.currentSession()).idToken.jwtToken } 
        }
      }
    ]
  },
  Analytics: {
    disabled: true
  }
}
export default apiConfig;
