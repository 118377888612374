import { Auth } from "aws-amplify";
import { USER, USER_STATE } from "../actions-index";
import { transformUser } from "./transform";
import { ACTION_STATE_LOADING, ACTION_STATE_ERROR, ACTION_STATE_SUCCESS } from "../util/constants";
import { buildAction } from "../util/util";
import { isUnauthenticated, redirectToSignIn } from "./util";

export function signIn() {
    return dispatch => {
        return Auth.currentSession()
            .then(session => {
                dispatch(buildAction(USER, transformUser(session.idToken.payload)));
                dispatch(buildAction(USER_STATE, ACTION_STATE_SUCCESS));
            })
            .catch(error => {
                console.error("SignIn Error: ", error);
                if(isUnauthenticated) {
                    redirectToSignIn();
                } else {
                    dispatch(buildAction(USER_STATE, ACTION_STATE_ERROR));
                }
            });
    };
}

export function getUser() {
    return dispatch => {
        const handleSession = session => {
            dispatch(buildAction(USER, transformUser(session.idToken.payload)));
            dispatch(buildAction(USER_STATE, ACTION_STATE_SUCCESS));
        }

        dispatch(buildAction(USER_STATE, ACTION_STATE_LOADING));
        return Auth.currentSession() // first attempt
            .then(handleSession)
            .catch(() => {
                setTimeout(() => { // second attempt with wait
                    Auth.currentSession()
                    .then(handleSession)
                    .catch(() => {
                        setTimeout(() => { // third attempt with wait
                            Auth.currentSession()
                            .then(handleSession)
                            .catch(error => {
                                console.error("Get User Error: ", error);
                                dispatch(buildAction(USER_STATE, ACTION_STATE_ERROR));
                            })
                        }, 3000);
                    })
                }, 1000);
            })
    };
}

export function signOut() {
    return dispatch => {
        Auth.signOut();
        // clear user
        dispatch(buildAction(USER, {}));
        dispatch(buildAction(USER_STATE, ""));
    }
}