import React, { Fragment, useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";
import { Body2, OSUButton } from "osu-react-components";

function NavigationPrompt(props) {
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [navigationLocation, setNavigationLocation] = useState(null);
    const [isNavigationConfirmed, setIsNavigationConfirmed] = useState(false);

    useEffect(() => {
        if(isNavigationConfirmed) {
            history.push(navigationLocation);
            setIsModalOpen(false);
        }
    }, [isNavigationConfirmed, history, navigationLocation, setIsModalOpen])

    const onNavigation = (location) => {
        if(prompt && !isNavigationConfirmed) {
            setIsModalOpen(true);
            setNavigationLocation(location);
            return false;
        } else {
            return true;
        }
    };

    return (
        <Fragment>
            <Prompt when={props.prompt} message={onNavigation} />
            <Modal data-testid={props.dataTestId} isOpen={isModalOpen}>
                <ModalHeader>{props.header}</ModalHeader>
                <ModalBody><Body2>{props.message}</Body2></ModalBody>
                <ModalFooter>
                    <OSUButton ariaLabel={props.cancelButtonLabel} color="gray" className="mr-1" onClick={() => setIsModalOpen(false)}>{props.cancelButtonLabel}</OSUButton>
                    <OSUButton ariaLabel={props.confirmButtonLabel} color="blue" onClick={() => setIsNavigationConfirmed(true)}>{props.confirmButtonLabel}</OSUButton>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

NavigationPrompt.defaultProps = {
    cancelButtonLabel: "Cancel",
    confirmButtonLabel: "Confirm",
    dataTestId: "navigation-prompt",
    header: "Confirm Navigation",
    message: "Are you sure you want to leave the page?"
}

NavigationPrompt.propTypes = {
    cancelButtonLabel: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
    dataTestId: PropTypes.string,
    header: PropTypes.string,
    message: PropTypes.string,
    prompt: PropTypes.bool.isRequired
}

export default NavigationPrompt;
