import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import GroupAnnouncement from "../components/GroupAnnouncement";
import { ANNOUNCEMENT_OPERATION_STATE, ANNOUNCEMENT_STATE, createAnnouncement, getGroupAnnouncement, resetAnnouncements, resetState, updateAnnouncement } from "../../actions-index";

const mapStateToProps = (state, props) => {
    const { data: announcement = {}, state: announcementState = "" } = state.announcement;
    const { announcementOperationState = "" } = state;
    return {
        announcement,
        announcementOperationState,
        announcementState
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createAnnouncement: (announcement) => dispatch(createAnnouncement(announcement)),
        getAnnouncement: (announcementGroup, announcementId) => dispatch(getGroupAnnouncement(announcementGroup, announcementId)),
        resetAnnouncements: () => dispatch(resetAnnouncements()),
        resetAnnouncementState: () => dispatch(resetState(ANNOUNCEMENT_STATE, "")),
        resetAnnouncementOperationState: () => dispatch(resetState(ANNOUNCEMENT_OPERATION_STATE, "")),
        updateAnnouncement: (announcementGroup, announcement) => (dispatch(updateAnnouncement(announcementGroup, announcement)))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupAnnouncement));