import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Alert } from "reactstrap";
import { isEmpty, keys, map } from "lodash";
import { Caption, OSUButton, OSUError, OSULoading, Subtitle2 } from "osu-react-components";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS } from "../../util/constants";

export default function GetProviderContent(props) {
    const { getProviderContent, getProviderContentState, getProviders, providers, providersState, resetGetProviderContentState } = props;
    const alertsEl = useRef(null);
    const [providerOptions, setProviderOptions] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);

    // on mount, get providers (if necessary)
    useEffect(() => {
        if(providersState === "") getProviders();
    }, [providersState, getProviders]);

    // when providers are returned, build provider options
    useEffect(() => {
        if(providersState === ACTION_STATE_SUCCESS && !isEmpty(providers)) {
            setProviderOptions(map(keys(providers), key => {
                return { label: providers[key], value: key };
            }));
        }
    }, [providersState, providers]);

    // when Get Provider Content state changes, set focus on alerts
    // clear selected provider when the state is success
    useEffect(() => {
        if([ACTION_STATE_ERROR, ACTION_STATE_SUCCESS].includes(getProviderContentState)) {
            alertsEl.current.focus();
            if(getProviderContentState === ACTION_STATE_SUCCESS) setSelectedProvider(null); // reset
        }
    }, [getProviderContentState])

    // when leaving the page, reset the Get Provider Content state
    useEffect(() => {
        return () => {
            resetGetProviderContentState();
        };
    }, [resetGetProviderContentState]);

    const onStartProcessButtonClick = () => {
        if(selectedProvider !== null) {
            getProviderContent(selectedProvider.value);
        }
    };

    return (
        <div>
            {providersState === ACTION_STATE_LOADING &&
                <OSULoading dataTestId="providers-loading" text="Loading Providers..." />
            }
            {providersState === ACTION_STATE_ERROR && 
                <OSUError dataTestId="providers-error" text="Failed to load Providers."
                    small="true" actionText="Retry" ariaLabel="Retry loading Providers" 
                    onClick={() => getProviders()} />
            }
            {providersState === ACTION_STATE_SUCCESS &&
                <Fragment>
                    {getProviderContentState === ACTION_STATE_LOADING &&
                        <OSULoading dataTestId="startingProcess" text="Starting Process..." />
                    }
                    <div data-testid="content" className={getProviderContentState === ACTION_STATE_LOADING ? "d-none" : ""}>
                        <div data-testid="alerts" ref={alertsEl} tabIndex="-1">
                            <Alert data-testid="processFailureAlert" color="danger" isOpen={getProviderContentState === ACTION_STATE_ERROR} toggle={resetGetProviderContentState}>
                                <Subtitle2>Start Process Failure:</Subtitle2>
                                <p className="mb-0">
                                    The process failed to start. Please retry to see if that resolves the issue.
                                </p>
                            </Alert>
                            <Alert data-testid="processSuccessAlert" color="success" isOpen={getProviderContentState === ACTION_STATE_SUCCESS} toggle={resetGetProviderContentState}>
                                <Subtitle2>Start Process Success:</Subtitle2>
                                <p className="mb-0">
                                    The process was successfully started and may take several minutes to complete.<br />
                                    Refresh the <Link to="/review">Review</Link> page to see the updated content.
                                </p>
                            </Alert>
                        </div>
                        <div className="d-flex mb-3">
                            <Link data-testid="reviewLink" aria-label="Navigate to Review" to="/review" className="d-flex p-0">
                                <Caption>Review</Caption>
                            </Link>
                            <Caption className="px-1">/</Caption>
                            <Caption color="gray">Get Provider Content</Caption>
                        </div>
                        <h1 data-testid="heading">Get Provider Content</h1>
                        <form data-testid="providerSelectForm" className="mt-2" style={{maxWidth: "16.5rem"}}>
                            <Select name="providerSelect" aria-label="Select a Provider" placeholder="Select a Provider" isClearable={true} 
                                options={providerOptions} noOptionsMessage={() => { return "No Providers" }}
                                value={selectedProvider} onChange={(option) => setSelectedProvider(option)} />
                        </form>
                        <OSUButton ariaLabel="Start Process" color="blue" className="mt-3" disabled={selectedProvider === null} onClick={onStartProcessButtonClick}>
                            Start Process
                        </OSUButton>
                    </div>
                </Fragment>
            }
        </div>
    );
}