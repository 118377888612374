import React from "react";
import PropTypes from "prop-types";
import "../styles/table.css";

function Table(props) {
    if(props.components.length === 0) return null;
    return (<div className="mt-2">{renderCells(props.components)}</div>);
}

function renderCells(components = []) {
    const cells = [];
    components.forEach(component => {
        if(component.role === "cell" && component.layout === "checklist") {
            cells.push(
                <div key={component.identifier} data-testid={component.identifier}>
                    <div className="row mt-2">
                        <div className="col-1 align-middle">
                            <i className="far fa-check-circle fa-2x checklist-check" />
                        </div>
                        <div className={"col-11 align-self-center"}>
                            {renderChecklist(component.components)}
                        </div>
                    </div>
                    <hr className="m-0"/>
                </div>
            );
        }
    });
    return cells;
}

function renderChecklist(components = []) {
    const checklist = [];
    components.forEach(component => {
        switch(component.role) {
            case "heading":
                if(component.text) {
                    checklist.push(
                        <div key={component.identifier} data-testid={component.identifier} className="row checklist-heading">
                            <p className="col-12">{component.text}</p>
                        </div>
                    );
                }
                break;
            case "body":
                if(component.text) {
                    checklist.push(
                        <div key={component.identifier} data-testid={component.identifier} className="row checklist-body">
                            <p className="col-12">{component.text}</p>
                        </div>
                    );
                }
                break;
            default:
                checklist.push(
                    <div key={component.identifier} data-testid={component.identifier} className="row">
                        <div className={"col-12"} />
                    </div>
                );
        }
    });
    return checklist;
} 

Table.defaultProps = {
    components: []
}

Table.propTypes = {
    components: PropTypes.arrayOf(PropTypes.shape({
        components: PropTypes.arrayOf(PropTypes.shape({
            identifier: PropTypes.string,
            layout: PropTypes.string,
            role: PropTypes.string,
            text: PropTypes.string
        })),
        identifier: PropTypes.string,
        layout: PropTypes.string,
        role: PropTypes.string
    }))
}

export default Table;