import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { Alert, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import { Caption, Icon, OSUButton, OSUError, OSULoading, PaginationWrapper, Subtitle2, Table } from "osu-react-components";
import { chunk, compact, find, map, transform, uniq } from "lodash";
import { AppContext } from "../../../App/components";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS } from "../../../util/constants";
import { formatDateString, formatText } from "../../../util/sort-formatters.js";

export default function Content(props) {
    const { addChannelSectionArticle, addChannelSectionArticleId, addChannelSectionArticleState,
        availableContent, availableContentState, availableContentFilter, channelSection, channelSectionState,
        getAvailableContent, getChannelSection, filterAvailableContent, match = {}, resetAddChannelSectionArticleState,
        resetGetAvailableContentState } = props;
    const params = match.params || {};
    const channel = params.channel;
    const sectionId = params.sectionId;
    const history = useHistory();
    const { pathname } = useLocation();
    const alertsEl = useRef(null);

    const loading = (availableContentState === ACTION_STATE_LOADING || channelSectionState === ACTION_STATE_LOADING);
    const error = (availableContentState === ACTION_STATE_ERROR || channelSectionState === ACTION_STATE_ERROR);
    const success = (availableContentState === ACTION_STATE_SUCCESS && channelSectionState === ACTION_STATE_SUCCESS);
    
    const [providerOptions, setProviderOptions] = useState([]);
    const selectedProvider = availableContentFilter.provider ? find(providerOptions, ["value", availableContentFilter.provider]) : null;
    const [loginStateOptions, setLoginStateOptions] = useState([]);
    const selectedLoginState = availableContentFilter.loginState ? find(loginStateOptions, ["value", availableContentFilter.loginState]) : null;
    const [addedContent] = useState([]);

    const dataKeys = [
        { label: (<Subtitle2 className="sr-only">Content State Icon</Subtitle2>), key: "icon" },
        { label: (<Subtitle2 className="sr-only">Content Image</Subtitle2>), key: "image" },
        { label: "Title", key: "title", sortFormatter: formatText },
        { label: "Publish Start", key: "publishStartDateDisplay", sortFormatter: formatDateString },
        { label: "Publish End", key: "publishEndDateDisplay", sortFormatter: formatDateString }
    ];
    const { screenSize } = useContext(AppContext);
    let defaultSortColumn;
    if(screenSize < 576) {
        dataKeys.splice(1, 1);
        dataKeys.pop();
        dataKeys[0].width = 20;
        dataKeys[1].width = 40;
        dataKeys[2].width = 40;
        defaultSortColumn = 2;
    } else if(screenSize < 768) {
        dataKeys.splice(1, 1);
        dataKeys[0].width = 12;
        dataKeys[1].width = 30;
        dataKeys[2].width = 29;
        dataKeys[3].width = 29;
        defaultSortColumn = 2;
    } else {
        dataKeys[0].width = 10;
        dataKeys[1].width = 12;
        dataKeys[2].width = 38;
        dataKeys[3].width = 20;
        dataKeys[4].width = 20;
        defaultSortColumn = 3;
    }
    const sortKeys = compact(dataKeys.map(dataKey => {
        return !["icon", "image"].includes(dataKey.key) ? dataKey.key : null;
    }));
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const updateRowsPerPage = (value) => (setRowsPerPage(value));
    const [dataIndex, setDataIndex] = useState(0);
    const updateDataIndex = (value) => (setDataIndex(value));
    const data = availableContent.map(item => {
        const isContentItemAdded = addedContent.includes(item.identifier);
        const isContentItemBeingAdded = (addChannelSectionArticleState === ACTION_STATE_LOADING && addChannelSectionArticleId === item.identifier);
        const addContentItemFailed = (addChannelSectionArticleState === ACTION_STATE_ERROR && addChannelSectionArticleId === item.identifier);
        const isAddingContent = (addChannelSectionArticleState === ACTION_STATE_LOADING);
        if(isContentItemAdded) {
            item.icon = (<Icon type="check" color="green" ariaLabel="Content Item Added" className="ml-2" />);
        } else {
            const label = isContentItemBeingAdded ? "Adding Content Item (Please Wait)" : addContentItemFailed ? "Add Content Item (Failed)" : "Add Content Item";
            item.icon = (
                <OSUButton ariaLabel={label} color="white" disabled={isAddingContent}
                    onClick={() => addChannelSectionArticle(channel, sectionId, item.identifier)}>
                    <Icon type="plus" color={addContentItemFailed ? "red" : "blue"} ariaLabel={label} />
                </OSUButton>
            );
        }
        return item;
    });
    const chunkedData = data.length > 0 ? chunk(data, rowsPerPage) : [{}];
    
    if(availableContentState === "") getAvailableContent(channel);
    if(channelSectionState === "") getChannelSection(channel, sectionId);

    // when data is retrieved, build filter options
    useEffect(() => {
        if(success) {
            setProviderOptions(transform(uniq(map(availableContent, "provider")), (result, value) => (result.push({ label: value, value }))));
            setLoginStateOptions(transform(uniq(map(availableContent, "loginState")),(result, value) => (result.push({ label: value, value }))));
        }
    }, [success, availableContent, channel, channelSection]);

    // when adding content returns an error, focus on alerts
    // when adding content is successful, add the content ID to state and reset add content redux state
    useEffect(() => {
        if(addChannelSectionArticleState === ACTION_STATE_ERROR) {
            alertsEl.current.focus();
        }

        if(addChannelSectionArticleState === ACTION_STATE_SUCCESS) {
            addedContent.push(addChannelSectionArticleId);
            resetAddChannelSectionArticleState();
        }
    }, [addChannelSectionArticleId, addChannelSectionArticleState, addedContent, resetAddChannelSectionArticleState]);
    
    //  reset redux state when leaving the page
    useEffect(() => {
        return () => {
            resetAddChannelSectionArticleState();
            resetGetAvailableContentState();
        };
    }, [pathname, resetAddChannelSectionArticleState, resetGetAvailableContentState]);

    return (
        <div>
            {loading &&
                <OSULoading dataTestId="loading-content" text="Loading Content..." />
            }
            {error &&
                <OSUError dataTestId="error-content" text="Failed to load content."
                    small="true" actionText="Retry" ariaLabel="Retry loading content" 
                    onClick={() => {
                        if(availableContentState === ACTION_STATE_ERROR) getAvailableContent(channel);
                        if(channelSectionState === ACTION_STATE_ERROR) getChannelSection(channel, sectionId);
                    }} />
            }
            {success &&
                <div data-testid="content">
                    <div data-testid="alerts" ref={alertsEl} tabIndex="-1">
                        {addChannelSectionArticleState === ACTION_STATE_ERROR &&
                            <Alert data-testid="add-content-alert" color="danger" toggle={resetAddChannelSectionArticleState}>
                                <h3>Add Content Failure</h3>
                                <p className="mb-0">
                                    Failed to add the content item to the {channel} - {channelSection.title} section.  Please retry to see if it resolves the issue.
                                </p>
                            </Alert>
                        }
                    </div>
                    <div data-testid="breadcrumb" className="d-flex mb-3">
                        <Link to="/organize"><Caption>Organize</Caption></Link>
                        <Caption className="pl-1 pr-1">/</Caption>
                        <Link to={`/organize/channel/${channel}/section/${sectionId}`}><Caption>Edit and Organize Section</Caption></Link>
                        <Caption className="pl-1 pr-1">/</Caption>
                        <Caption>Add Content</Caption>
                    </div>
                    <h1 data-testid="page-header">{channel} - {channelSection.title}<br/>Add Content</h1>
                    <Row className="pt-2">
                        <Col>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText><Icon type="search" color="gray" /></InputGroupText>
                            </InputGroupAddon>
                            <Input data-testid="searchInput" type="text" aria-label="Filter by Title" placeholder="Filter by Title" defaultValue={availableContentFilter.title || ""}
                                onChange={(e) => filterAvailableContent("title", e.target.value)} />
                        </InputGroup>
                        </Col>
                    </Row>
                    <Row className="pt-1 pb-1 d-flex flex-wrap">
                        <Col sm="6" md="4" lg="3" className="flex-column pb-1">
                            <form data-testid="providerFilterForm">
                                <Select name="providerFilterSelect" aria-label="Filter by Provider" placeholder="Provider" isClearable={true} 
                                options={providerOptions} value={selectedProvider}
                                onChange={(option) => filterAvailableContent("provider", option ? option.value : null)} />
                            </form>
                        </Col>
                        <Col sm="6" md="4" lg="3" className="flex-column pb-1">
                            <form data-testid="loginStateFilterForm">
                                <Select name="loginStateFilterSelect" aria-label="Filter by Login State" placeholder="Login State" isClearable={true} 
                                options={loginStateOptions} value={selectedLoginState}
                                onChange={(option) => filterAvailableContent("loginState", option ? option.value : null)} />
                            </form>
                        </Col>
                    </Row>
                    <PaginationWrapper
                        persist
                        totalPageCount={chunkedData.length}
                        updateDataIndex={updateDataIndex}
                        updateRowsPerPage={updateRowsPerPage}
                        dataIndex={dataIndex}
                        rowsPerPageOptions={[10, 20, 30]}
                        rowsPerPage={rowsPerPage}
                        resultsData={{ shownResults: data.length, totalResults: data.length }}
                        showOptionalCount={true}
                    >
                        <Table 
                            sortable 
                            headerVariant="subtitle2" 
                            rowHeight="2" 
                            dataKeys={dataKeys} 
                            data={data}
                            noDataMessage="There is no content available to add at this time." 
                            paginationData={{ rowsPerPage, dataIndex }}
                            sortKeys={sortKeys}
                            defaultSortColumn={defaultSortColumn}
                            defaultSortDirection="desc"
                            hover={false}
                        />
                    </PaginationWrapper>
                    <OSUButton ariaLabel="Done adding content, go back to Edit and Organize Section" color="blue" className="mt-1"
                        onClick={() => history.push(`/organize/channel/${channel}/section/${sectionId}`)}>Done</OSUButton>
                </div>
            }
        </div>
    );
}