import React from "react";

export default function SignOut() {
    return (
        <div>
            <h1 className="sr-only">Log Out</h1>
            <h2>You have been logged out.</h2>
        </div>
    );
}
