import { API } from "aws-amplify";
import { 
    REVIEW_CONTENT, REVIEW_CONTENT_FILTER, REVIEW_CONTENT_STATE } from "../actions-index";
import { ACTION_STATE_ERROR, ACTION_STATE_LOADING, ACTION_STATE_SUCCESS } from "../util/constants";
import { buildAction } from "../util/util";
import { handleUnauthenticated } from "../Authentication/util";

export function getReviewContent() {
    return dispatch => {
        dispatch(buildAction(REVIEW_CONTENT_STATE, ACTION_STATE_LOADING));
        API.get(process.env.REACT_APP_API_NAME, "/content/review")
        .then(response => {
            const reviewContent = (response.data && response.data.content) ? response.data.content : [];
            dispatch(buildAction(REVIEW_CONTENT, reviewContent));
            dispatch(buildAction(REVIEW_CONTENT_STATE, ACTION_STATE_SUCCESS));
        })
        .catch(error => {
            console.error("Get Review Content Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(REVIEW_CONTENT_STATE, ACTION_STATE_ERROR));
            });
        });
    };
}

export function filterReviewContent(field, value) {
    return dispatch => dispatch(buildAction(REVIEW_CONTENT_FILTER, { field, value }));
}