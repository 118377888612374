export function transformUser(user = {}) {
    const roles = user[process.env.REACT_APP_USER_ROLES_ATTRIBUTE] || [];
    const isAdmin = roles.includes(process.env.REACT_APP_USER_ROLE_ADMIN);
    const isApprover = roles.includes(process.env.REACT_APP_USER_ROLE_APPROVER);
    const canSendNotification = roles.includes(process.env.REACT_APP_USER_ROLE_NOTIFICATION);
    const canManageCompositeChannels = roles.includes(process.env.REACT_APP_USER_ROLE_COMPOSITE_CHANNEL);
    const canManageAnnouncements = roles.includes(process.env.REACT_APP_USER_ROLE_ANNOUNCEMENT);
    return {
        email: user.email,
        isAdmin,
        isApprover,
        canManageAnnouncements,
        canManageCompositeChannels,
        canSendNotification,
        name: user.given_name,
        osuid: user.osuid,
        roles
    };
}